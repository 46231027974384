import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

//Base Selectors
const authSelector = (state) => state.authReducer;
const categorySelector = (state) => state.categoryReducer;
const productSelector = (state) => state.productReducer;
const chatSelector = (state) => state.chatReducer;
const profileSelector = (state) => state.profileReducer;
const locationSelector = (state) => state.profileReducer;


// https://verkaufdev.semseosmo.com/api/v1/locations/states/2
// https://verkaufdev.semseosmo.com/api/v1/locations/states
// https://verkaufdev.semseosmo.com/api/v1/locations?search=800



// Auth Slectors
export const isAuthenticatedSelector = createSelector(
  authSelector,
  (authData) => authData?.isAuthenticated
);

export const currentUserDataSelector = createSelector(
  authSelector,
  (authData) => authData?.currentUserData
);

// Product Selectors
export const newListingProductDataSelector = createSelector(
  productSelector,
  (product) => product?.newListingProductData
);
export const sponsoredProductDataSelector = createSelector(
  productSelector,
  (product) => product?.sponsoredProductData
);
export const trendyProductDataSelector = createSelector(
  productSelector,
  (product) => product?.trendyProductData
);
export const popularProductDataSelector = createSelector(
  productSelector,
  (product) => product?.popularProductData
);

// location Selectors
export const statesDataSelector = createSelector(
  locationSelector,
  (locationData) => locationData?.statesData
);

export const citiesDataSelector = createSelector(
  locationSelector,
  (locationData) => locationData?.citiesData
);

//chat
export const selectedChatRoomSelector = createSelector(
  chatSelector,
  (chatData) => chatData?.selectedChatRoom
);
export const allChatRoomDataSelector = createSelector(
  chatSelector,
  (chatData) => chatData?.allChatRoomData
);

const useAppSelectors = () => {
  // Profile selectors
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const currentUserData = useSelector(currentUserDataSelector);
 

  //chat 
  const selectedChatRoom = useSelector(selectedChatRoomSelector);
  const allChatRoomData = useSelector(allChatRoomDataSelector);

  // products selectors
  const newListingProductData = useSelector(newListingProductDataSelector);
  const sponsoredProductData = useSelector(sponsoredProductDataSelector);
  const trendyProductData = useSelector(trendyProductDataSelector);
  const popularProductData = useSelector(popularProductDataSelector);


  // location 
  const statesData = useSelector(statesDataSelector);
  const citiesData = useSelector(citiesDataSelector);

  return {
    isAuthenticated,
    currentUserData,
    // location
    statesData,
    citiesData,

    // products
    newListingProductData,
    sponsoredProductData,
    trendyProductData,
    popularProductData,

    //chat
    selectedChatRoom,
    allChatRoomData
  };
};

export default useAppSelectors;

import React, { useEffect, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import styled from "@emotion/styled";

// Icons
import DropdownSelect from "./DropdownSelect";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../../store/helper/categorySlice";
import { fetchTemplate } from "../../store/template/templateSlice";
import DynamicForm from "../DynamicForm/DynamicForm";
import { useLocation } from "react-router-dom";

// styles
const Text = styled(Typography)`
  color: #26252c;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const HRLine = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

function AdSubmissionForm({
  setCurrentSelectedCategoryId,
  currentSelectedCategoryId,
  selectedSubCategories,
  setSelectedSubCategories,
  selectedMainCategory,
  selectedAdsTypeButton,
  setShowTemplate,
  showTemplate,
  formHandleSubmitRef,
  handleFormSubmit,
  handleNextClick,
}) {
  const location = useLocation();
  const categoriesData = useSelector((state) => state.categoryReducer || []);
  const editAdsData = useSelector(
    (state) => state?.myadsReducer?.adsDetailsData
  );
  const template = useSelector((state) => state.templateReducer || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  // Track visible form sections
  const dispatch = useDispatch();

  const handleClick = (event) => {
    const target = event.currentTarget; // Capture the event target immediately
    setAnchorEl((prevAnchorEl) => {
      const newAnchorEl = prevAnchorEl ? null : target; // Use the captured target
      return newAnchorEl;
    });
  };

  const handleSelect = (option) => {
    dispatch(getCategoryById(option?.id));

    // Use functional update to ensure you're working with the most recent state
    setSelectedSubCategories((prevSelectedSubCategories) => {
      // Prevent adding duplicate subcategories
      const isAlreadySelected = prevSelectedSubCategories.some(
        (subcategory) => subcategory.id === option.id
      );

      if (isAlreadySelected) {
        return prevSelectedSubCategories;
      }

      return [...prevSelectedSubCategories, option];
    });

    setCurrentSelectedCategoryId(option?.id);
    setSearch("");

    const subcategories = categoriesData?.subCategoryMap[option?.id] || [];

    if (subcategories.length === 0) {
      const template_id = [
        selectedMainCategory,
        ...selectedSubCategories,
        option,
      ]
        ?.map((item) => item.id)
        .join("_");

      localStorage.setItem("template_id", template_id);
      localStorage.setItem("templateType", selectedAdsTypeButton);

      dispatch(
        fetchTemplate({ template_id, templateType: selectedAdsTypeButton })
      ).then(() => setShowTemplate(true));
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith("/edit-post/")) {
      // const templateId = editAdsData.template_id.split("_");

      // // Start from index 1 to skip the main category
      // templateId.slice(1).forEach((id) => {
      //   const categoryId = +id;
      //   const subcategories = categoriesData.subCategoryMap[categoryId] || [];

      //   // Find the specific subcategory that matches the ID
      //   const matchingSubcategory = subcategories.find(
      //     (sub) => sub.id === categoryId
      //   );

      //   if (matchingSubcategory) {
      //     handleSelect(matchingSubcategory);
      //   }
      // });

      dispatch(
        fetchTemplate({
          template_id: editAdsData?.template_id,
          templateType: editAdsData?.template_type,
        })
      ).then(() => setShowTemplate(true));
      setAnchorEl(null);
    }
  }, [location.pathname]);

  const subcategories =
    categoriesData?.subCategoryMap[currentSelectedCategoryId] || [];

  return (
    <Box sx={{ mt: "24px" }}>
      {!location.pathname.startsWith("/edit-post/") && (
        <>
          <Text>Select Sub Category</Text>
          <DropdownSelect
            currentSelectedCategoryId={currentSelectedCategoryId}
            setCurrentSelectedCategoryId={setCurrentSelectedCategoryId}
            setSelectedSubCategories={setSelectedSubCategories}
            selectedSubCategories={selectedSubCategories}
            setSearch={setSearch}
            search={search}
            anchorEl={anchorEl}
            subcategories={subcategories}
            handleSelect={handleSelect}
            handleClick={handleClick}
          />
          <HRLine />
        </>
      )}

      {/* Render visible sections dynamically */}
      {showTemplate  && (
        <DynamicForm
          formData={[...(template?.templateData?.details || [])]}
          handleFormSubmit={handleFormSubmit}
          formHandleSubmitRef={formHandleSubmitRef}
          handleNextClick={handleNextClick}
        />
      )}
    </Box>
  );
}

export default AdSubmissionForm;

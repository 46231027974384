import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "../Header/Header";

// Components

function AboutUs() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  return (
    <>
      <Box sx={{ dispay: "flex" }} mt={10.4}>
        <Header />
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "#fff",
            margin: "20px",
          }}
        >
          <Typography
            variant="h3"
            display="flex"
            justifyContent="center"
            textAlign="center"
            fontSize={40}
            fontWeight={600}
          >
            About Us
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default AboutUs;

import React from "react";
// CSS
import "./adsCard.css";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CommonCards from "../common/CommonCards";

function AdsCard({ products }) {
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        {Array.isArray(products) && products.length > 0 ? (
          products.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={item.id || index}>
              <CommonCards key={item.id || index} item={item} isMyAdsList={true}/>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
            No ads available
          </Typography>
        )}
      </Grid>
    </Box>
  );
}

export default AdsCard;

import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import CommonCards from "../common/CommonCards";

function FavouriteCard({ products }) {
  const [favAds, setFavAds] = React.useState(products || []);

    useEffect(() => {
      setFavAds(products || []); // Update favAds when products change
    }, [products]);

  const handleRemoveFavourite = (id) => {
    setFavAds((prevAds) => prevAds.filter((ad) => ad.id !== id));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {favAds.length > 0 ? (
        <Grid container spacing={2}>
          {favAds.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={item.id || index}>
              <CommonCards
                key={item.id || index}
                item={item}
                isFavList={true}
                onRemove={handleRemoveFavourite}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        // Ensure the "No Favourite Product added" message is always centered and visible
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Typography variant="h6">No Product added</Typography>
        </Box>
      )}
    </Box>
  );
}

export default FavouriteCard;

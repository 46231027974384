import React, { useState, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDynamicOption } from "../../../store/product/productSlice";

const CustomDynamicSelectField = ({
  name,
  label,
  control,
  disabled,
  multiple,
  errors,
  getValues,
  setValue,
  optionSourceFieldName,
  identifier,
  getOptionFromApi,
}) => {
  const [options, setOptions] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const sourceValue = getValues(optionSourceFieldName);
  const prevSourceValue = useRef(sourceValue);
  const fetchOptions = async () => {
    try {
      const resolvedOptions = await getOptionFromApi(
        sourceValue,
        optionSourceFieldName,
        identifier
      );
      if (resolvedOptions?.length > 0) setOptions(resolvedOptions);
    } catch (error) {
      console.error("Failed to fetch options:", error);
      setOptions([]);
    }
  };
  // Fetch options when sourceValue changes
  useEffect(() => {
    if (!sourceValue) {
      setOptions([]);
      return;
    }
    if (prevSourceValue.current !== sourceValue) {
      setValue(name, ""); 
      prevSourceValue.current = sourceValue; 
    }
    fetchOptions();
  }, [sourceValue, optionSourceFieldName, identifier, getOptionFromApi]);

  useEffect(() => {
    if (!sourceValue) {
      setOptions([]);
      return;
    }
    if(location.pathname.startsWith("/edit-post/")){
      dispatch(
        getDynamicOption(`${sourceValue}${identifier}`)
      ).then((res)=>setOptions(res));
    }
  }, []);

  return (
    <Box width="100%" mb="0.85rem">
      <FormControl variant="standard" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const value = multiple
              ? Array.isArray(field.value)
                ? field.value
                : []
              : field.value;

            return (
              <Select
                {...field}
                label={label}
                disabled={disabled}
                value={value||""}
                multiple={multiple}
                renderValue={(selected) =>
                  multiple ? selected.join(", ") : selected
                }
                error={!!errors[name]}
              >
                <MenuItem sx={{ display: "none" }} value="">
                  <em>None</em>
                </MenuItem>
                {options.map((option, i) => (
                  <MenuItem key={i + identifier} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
        {errors[name] && (
          <div style={{ color: "red" }}>{errors[name].message}</div>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomDynamicSelectField;

// return (
//   <Box width={"100%"} key={name} mb={"0.85rem"}>
//     <FormControl variant="standard" fullWidth>
//       <InputLabel>{label}</InputLabel>
//       <Controller
//         name={name}
//         control={control}
//         render={({ field }) => (
//           <Select
//             {...field}
//             label={label}
//             disabled={disabled}
//             value={getValues(name)}
//             renderValue={(selected) =>
//               multiple ? selected.join(", ") : selected
//             }
//             error={!!errors[name]}
//           >
//             <MenuItem value="">
//               <em>None</em>
//             </MenuItem>
//             {getValues(optionSourceFieldName) &&
//               getOptionFromApi(
//                 getValues(optionSourceFieldName),
//                 optionSourceFieldName
//               ).map((option) => (
//                 <MenuItem
//                   key={option.value}
//                   value={option.value}
//                 >
//                   {option.label}
//                 </MenuItem>
//               ))}
//           </Select>
//         )}
//       />
//       {errors[name] && (
//         <div style={{ color: "red" }}>
//           {errors[name].message}
//         </div>
//       )}
//     </FormControl>
//   </Box>
// );

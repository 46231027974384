// import React, { useEffect, useState } from "react";

// // css
// import "./ProductSlide.css";
// import ProductCard from "../ProductCard/ProductCard";

// // images
// import Car4 from "../../assets/images/ProductImages/car-04.png";
// import {
//   Box,
//   Card,
//   CardActionArea,
//   CardContent,
//   CardMedia,
//   Typography,
// } from "@mui/material";

// function ProductSlide({ title, showDiv, products }) {

//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, []);
  
//   return (
//     <Box sx={{ marginTop: "24px" }} className="productslide-container">
//       {/* product header text  */}
//       <p>{title}</p>
//       <Box sx={{ display: "flex" }}>
//         {showDiv && (
//           <Card
//             sx={{
//               minWidth: 400,
//               height: 314,
//               overflowX: "auto",
//               alignItems: "flex-start",
//               gap: "16px",
//               marginLeft: "16px",
//               // marginRight: "16px",
//               marginBottom: "16px",
//               backgroundColor: "#00A6E8",
//             }}
//           >
//             <CardMedia
//               component="img"
//               marginTop="10px"
//               height="258px"
//               width="62px"
//               image={Car4}
//               alt="green iguana"
//               sx={{
//                 objectFit: "contain",
//               }}
//             />
//             <CardContent sx={{ paddingBottom: "10px" }}>
//               <Typography
//                 gutterBottom
//                 variant="h5"
//                 component="div"
//                 textAlign="center"
//                 color="white"
//               >
//                 Trendy Deal
//               </Typography>
//             </CardContent>
//           </Card>
//         )}
//         {/*  product card*/}
//         <ProductCard products={products} />
//       </Box>
//     </Box>
//   );
// }

// export default ProductSlide;

import React, { useEffect } from "react";

// css
import "./ProductSlide.css";
import ProductCard from "../ProductCard/ProductCard";

// images
import Car4 from "../../assets/images/ProductImages/car-04.png";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

function ProductSlide({ title, showDiv, products }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ marginTop: "24px" }} className="productslide-container">
      {/* product header text */}
      <Typography variant="h6" m={1} fontSize={20} fontWeight={600}>{title}</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {showDiv && (
          <Card
            sx={{
              minWidth: 400,
              height: 314,
              alignItems: "flex-start",
              gap: "16px",
              marginLeft: "16px",
              marginBottom: "16px",
              backgroundColor: "#00A6E8",
            }}
          >
            <CardMedia
              component="img"
              height="258px"
              image={Car4}
              alt="trendy deal"
              sx={{ objectFit: "contain" }}
            />
            <CardContent sx={{ paddingBottom: "10px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
                color="white"
              >
                Trendy Deal
              </Typography>
            </CardContent>
          </Card>
        )}

        {/* Product Cards with Horizontal Scroller */}
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            overflowX: products?.length > 3 ? "auto" : "hidden",
            padding: "8px",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
          }}
        >
            <Box
            >
              <ProductCard products={products} />
            </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ProductSlide;

import {
  Box,
  Button,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";

function SuccessPage() {
  const navigate = useNavigate();

  const backHomeBtn = () => {
    navigate("/login");
  };

  return (
    <>
      <Header />
      <Button
        startIcon={<ArrowBackIcon />}
        sx={{
          color: " #707187",
          position: "relative",
          top: "50px",
          left: "220px",
        }}
        onClick={backHomeBtn}
      >
        Back To Login Page
      </Button>
      <Box
        container
        sx={{
          border: "0.5px solid #D8D8DF",
          position: "relative",
          height: "410px",
          width: "936px",
          left: "200px",
          top: "50px",
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "45px" }}>
          <div className="loginfom">
            <h1 className="headline">Password Successfully Changed.</h1>
            <p className="subtitle">
              Your password has been successfully updated. you’re all set to
              continue your journey!
            </p>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default SuccessPage;

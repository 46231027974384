import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Switch,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFireBaseChatHelper } from "../../../customHooks/useFireBaseChatHelper";
import { ArrowBack, Person } from "@mui/icons-material";
import { SocialLoginUser } from "../../../store/auth/authSlice";
import { fetchAndStoreToken } from "../../../config/firebase.config";
import { toast } from "react-toastify";

const schema = Yup.object({
  nickname: Yup.string().required("Required"),
});

const SocialAuth = () => {
  const [isSubscribed, setSubscribed] = useState(false);
  const [type, setType] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { setUserOnlineStatus } = useFireBaseChatHelper();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      nickname: "",
    },
  });

  const onSubmit = async (values) => {
    const reqBody = {
      email: location.state?.socialUser?.email,
      nickname: values.nickname,
      social_id: location.state?.socialUser?.social_id,
      type,
      is_subscribed: isSubscribed,
    };
    dispatch(SocialLoginUser(reqBody)).then((res) => {
      if (res?.data?.status) {
        setUserOnlineStatus(res?.data?.data?.id);
          fetchAndStoreToken(res?.data?.data?.id);
        toast.success("Login successful!");
        navigate("/", { replace: true });
      }
    });
    reset();
  };

  const handleNavLogin = () => {
navigate("/auth")
  }


  return (
    <>
      <Header />
      <Box
        mt={7.4}
        sx={{ width: "30%" }}
        width={300}
        position="relative"
        left={200}
        top={30}
      >
        <Box
          sx={{
            border: "0.5px solid #D8D8DF",
            width: 936,
            minHeight: 452,
            bgcolor: "#FFF",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              border: "0.5px solid #D8D8DF",
              width: 936,
              minHeight: 452,
              bgcolor: "#FFF",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              overflow: "hidden",
            }}
          >
            <Button
              startIcon={<ArrowBack sx={{ color: "#707187" }} />}
              onClick={handleNavLogin}
              disableRipple
              disableElevation
              sx={{
                marginTop: 2,
                textTransform:"none",
                color:"#707187",
                marginLeft: 3,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Back
            </Button>
            <Box sx={{ paddingLeft: "48px" }}>
              <h1 className="headline">Welcome to VerkaufAllen!</h1>
              <p className="subtitle">
                Please enter your nickname to access your account.
              </p>
              <Stack direction="row" spacing={2} mt={4}>
                <Button
                  onClick={() => setType(0)}
                  variant="outlined"
                  sx={{
                    bgcolor: type === 0 && "#1976d2",
                    color: type === 0 && "#fff",
                  }}
                >
                  Private
                </Button>
                <Button
                  onClick={() => setType(1)}
                  variant="outlined"
                  sx={{
                    bgcolor: type === 1 && "#1976d2",
                    color: type === 1 && "#fff",
                  }}
                >
                  Commercial
                </Button>
              </Stack>
              <form onSubmit={handleSubmit(onSubmit)} className="form">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      variant="standard"
                      error={Boolean(errors.nickname)}
                    >
                      <InputLabel htmlFor="nickname">Nickname</InputLabel>
                      <Input
                        id="nickname"
                        type="text"
                        {...register("nickname")}
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                      {errors.nickname && (
                        <FormHelperText>
                          {errors.nickname.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => setSubscribed(e.target.checked)}
                        />
                      }
                      label="Yes, I look forward to receiving regular updates by email"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} marginTop={-2}>
                  <Grid item xs={12} sm={3} marginTop={3}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "300px",
                        textTransform: "none",
                        backgroundColor:
                          isValid && isDirty ? "#00A6E8" : "gray",
                        color: "white",
                      }}
                      disabled={!(isValid && isDirty)}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SocialAuth;
// const TabComponent = () => {

//     return (
//       <>
//         <Header />
//         <Box
//           mt={7.4}
//           sx={{ width: "30%" }}
//           width={300}
//           position="relative"
//           left={200}
//           top={30}
//         >
//           <Box>
//             <Tabs
//               value={value}
//               onChange={handleChange}
//               aria-label="basic tabs example"
//             >
//               <Tab
//                 label="Login"
//                 {...a11yProps(0)}
//                 style={{
//                   fontSize: "15px",
//                   fontWeight: "bold",
//                   marginLeft: "70px",
//                   // color: "red",
//                 }}
//               />
//               <Tab
//                 label="Sign Up"
//                 {...a11yProps(1)}
//                 style={{
//                   fontSize: "15px",
//                   fontWeight: "bold",
//                 }}
//               />
//             </Tabs>
//           </Box>
//           <CustomTabPanel value={value} index={0}>
//             <Login />
//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={1}>
//             <Register />
//           </CustomTabPanel>
//         </Box>
//       </>
//     );
//   };

//   export default TabComponent;

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ProfileImg from "../../../assets/images/profile/3e5c02d1c4d27a7ec3537a08183734bc.jpeg";
import * as yup from "yup";
import { toast } from "react-toastify";
import CommercialPage from "./CommercialPage";
import ProfilePage from "./ProfilePage";
import { useDispatch } from "react-redux";
import {
  updateProfile,
  updateProfileImage,
} from "../../../store/profile/profileSlice";
import useAppSelectors from "../../../customHooks/useAppSelector";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


// const buttonStyle = {
//   color: "black",
//   textTransform: "none",
//   fontFamily: "Work Sans",
//   fontSize: "16px",
//   fontWeight: 500,
//   lineHeight: "22px",
//   width: "328px",
//   height: "40px",
// };

// const validateDateFormat = (value) => {
//   if (!value) return false;

//   const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
//   return datePattern.test(value);
// };

// const validationSchema = yup.object({
//   nickname: yup.string().required("Nickname is required"),
//   first_name: yup.string().required("First name is required"),
//   last_name: yup.string().required("Last name is required"),
//   dob: yup
//     .string()
//     .required("Date of birth is required")
//     .test(
//       "is-valid-date",
//       "Date of birth must be in the format dd/mm/yyyy",
//       validateDateFormat
//     ),
//   gender: yup.string().required("Gender is required"),
//   email: yup
//     .string()
//     .email("Invalid email format")
//     .required("Personal email is required"),
//   telephone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Personal telephone is required"),
//   phone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Phone Number is required"),
//   address: yup.string().required("Personal address is required"),
//   zipcode: yup.string().required("Personal zipcode is required"),
//   country: yup.string().required("Country is required"),
// });

// const commercialValidationSchema = yup.object({
//   company_name: yup.string().required("Company Name is required"),
//   company_address: yup.string().required("Address is required"),
//   company_zipcode: yup.string().required("Zip Code is required"),
//   company_country: yup.string().required("Country is required"),
//   company_email: yup
//     .string()
//     .email("Invalid email address")
//     .required("Email Id is required"),
//   company_telephone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Telephone is required"),
//   company_phone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Phone Number is required"),
//   company_website: yup.string().required("Website is required"),
//   company_gst: yup.string().required("GST Number is required"),
//   nickname: yup.string().required("Nickname is required"),
//   first_name: yup.string().required("First name is required"),
//   last_name: yup.string().required("Last name is required"),
//   dob: yup
//     .string()
//     .required("Date of birth is required")
//     .test(
//       "is-valid-date",
//       "Date of birth must be in the format dd/mm/yyyy",
//       validateDateFormat
//     ),
//   gender: yup.string().required("Gender is required"),
//   email: yup
//     .string()
//     .email("Invalid email format")
//     .required("Personal email is required"),
//   telephone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Personal telephone is required"),
//   phone: yup
//     .string()
//     .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
//     .required("Phone Number is required"),
//   address: yup.string().required("Personal address is required"),
//   zipcode: yup.string().required("Personal zipcode is required"),
//   country: yup.string().required("Country is required"),
// });

// const Profile = () => {
//   const [formType, setFormType] = useState("PRIVATE");
//   const [profileImg, setProfileImg] = useState(null);
//   const { currentUserData } = useAppSelectors();
//   const fileInputRef = useRef(null);
//   const dispatch = useDispatch();

//   const accountType = currentUserData?.accountType;

//   useEffect(() => {
//     if (currentUserData) {
//       formik.setValues({
//         ...formik.values,
//         first_name: currentUserData.firstName,
//         last_name: currentUserData.lastName,
//       });
//     }
//   }, [currentUserData]);

//   useEffect(() => {
//     if (currentUserData?.profileImage) {
//       setProfileImg(currentUserData?.profileImage); 
//     }
//   }, [currentUserData?.profileImage]);

//   useEffect(() => {
//     if (currentUserData) {
//       let parsedData;
//       try {
//         parsedData = typeof currentUserData === "string" ? JSON.parse(currentUserData) : currentUserData;
//       } catch (error) {
//         console.error("Failed to parse profile data:", error);
//         parsedData = {};
//       }

//       setFormType(parsedData.type === 1 ? "COMMERCIAL" : "PRIVATE");
//     }
//   }, [currentUserData]);

//   const isPrivateAccount = formType === "PRIVATE";
//   const isCommercialAccount = formType === "COMMERCIAL";

//   const initialPrivateValues = {
//     nickname: currentUserData.nickname || "",
//     first_name: currentUserData?.firstName || "",
//     last_name: currentUserData?.lastName || "",
//     dob: currentUserData.dob || "",
//     gender: currentUserData.gender ? currentUserData.gender.toLowerCase() : "",
//     email: currentUserData.email || "",
//     telephone:
//       typeof currentUserData?.telephone === "string"
//         ? [currentUserData.telephone]
//         : Array.isArray(currentUserData?.telephone)
//         ? currentUserData.telephone
//         : [],
//     phone:
//       typeof currentUserData?.phone === "string"
//         ? [currentUserData.phone]
//         : Array.isArray(currentUserData?.phone)
//         ? currentUserData.phone
//         : [],
//     address: currentUserData.address || "",
//     zipcode: currentUserData.zipcode || "",
//     country: currentUserData.country || "",
//     profile_image: null,
//   };

//   const initialCommercialValues = {
//     company_name: currentUserData?.companyName || "",
//     company_address: currentUserData.companyAddress || "",
//     company_zipcode: currentUserData.companyZipcode || "",
//     company_country: currentUserData.companyCountry || "",
//     company_email: currentUserData.companyEmail || "",
//     company_telephone:
//       typeof currentUserData?.companyTelephone === "string"
//         ? [currentUserData.companyTelephone]
//         : Array.isArray(currentUserData?.companyTelephone)
//         ? currentUserData.companyTelephone
//         : [],
//     company_phone:
//       typeof currentUserData?.companyPhone === "string"
//         ? [currentUserData.companyPhone]
//         : Array.isArray(currentUserData?.companyPhone)
//         ? currentUserData.companyPhone
//         : [],
//     company_website: currentUserData.companyWebsite || "",
//     company_gst: currentUserData.companyGst || "",
//     nickname: currentUserData.nickname || "",
//     first_name: currentUserData.firstName || "",
//     last_name: currentUserData.lastName || "",
//     dob: currentUserData.dob || "",
//     gender: currentUserData.gender ? currentUserData.gender.toLowerCase() : "",
//     email: currentUserData.email || "",
//     telephone:
//       typeof currentUserData?.telephone === "string"
//         ? [currentUserData.telephone]
//         : Array.isArray(currentUserData?.telephone)
//         ? currentUserData.telephone
//         : [],
//     phone:
//       typeof currentUserData?.phone === "string"
//         ? [currentUserData.phone]
//         : Array.isArray(currentUserData?.phone)
//         ? currentUserData.phone
//         : [],
//     address: currentUserData.address || "",
//     zipcode: currentUserData.zipcode || "",
//     country: currentUserData.country || "",
//     company_logo: null,
//     profile_image: null,
//   };

//   const formik = useFormik({
//     initialValues:
//       formType === "PRIVATE" ? initialPrivateValues : initialCommercialValues,
//     validationSchema:
//       formType === "PRIVATE" ? validationSchema : commercialValidationSchema,
//     enableReinitialize: true,
//     onSubmit: async (values) => {
//       let filteredValues = { ...values };

//       if (formType === "PRIVATE") {
//         filteredValues = {
//           nickname: values.nickname,
//           first_name: values.first_name,
//           last_name: values.last_name,
//           dob: values.dob,
//           gender: values.gender,
//           email: values.email,
//           // telephone: values.telephone,
//           telephone: Array.isArray(values.telephone)
//             ? values.telephone
//             : [values.telephone],
//           phone: Array.isArray(values.phone) ? values.phone : [values.phone],
//           address: values.address,
//           zipcode: values.zipcode,
//           country: values.country,
//         };
//       } else if (formType === "COMMERCIAL") {
//         filteredValues = {
//           company_name: values.company_name,
//           company_address: values.company_address,
//           company_country: values.company_country,
//           company_zipcode: values.company_zipcode,
//           company_email: values.company_email,
//           company_telephone: Array.isArray(values.company_telephone)
//             ? values.company_telephone
//             : [values.company_telephone],
//           company_phone: Array.isArray(values.company_phone)
//             ? values.company_phone
//             : [values.company_phone],
//           company_website: values.company_website,
//           company_gst: values.company_gst,
//           // company_logo: values.company_logo,
//           nickname: values.nickname,
//           first_name: values.first_name,
//           last_name: values.last_name,
//           dob: values.dob,
//           gender: values.gender,
//           email: values.email,
//           telephone: Array.isArray(values.telephone)
//             ? values.telephone
//             : [values.telephone],
//           phone: Array.isArray(values.phone) ? values.phone : [values.phone],
//           address: values.address,
//           zipcode: values.zipcode,
//           country: values.country,
//         };
//       }

//       const formData = new FormData();
//       formData.append("data", JSON.stringify(filteredValues));

//       // if (values.company_logo) {
//       //   formData.append("company_logo", values.company_logo);
//       // }
//       //  if (values.profile_image) {
//       //   formData.append("profile_image", values.profile_image);
//       // }

//       // Always append profile image (even if form data is there)
//       const dataImg = new FormData();
//       if (values.profile_image) {
//         dataImg.append("profile_image", values.profile_image);
//       }

//       // Append company logo if it exists
//       if (values.company_logo) {
//         formData.append("company_logo", values.company_logo);
//       }

//       // try {
//       //   await dispatch(updateProfileImage());
//       // } catch (error) {
//       //   toast.error(
//       //     "Failed to update profile: " + (error.message || "Unknown error")
//       //   );
//       // }
//       try {
//          dispatch(updateProfileImage(dataImg));
//          dispatch(updateProfile(formData));
//       } catch (error) {
//         toast.error(
//           console.error("Submission Error Details:", error.response || error)
//         );
//       }
//     },
//   });

//   const handleButtonClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (event, isProfileImage = true) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (isProfileImage) {
//         setProfileImg(URL.createObjectURL(file));
//         formik.setFieldValue("profile_image", file);
//       } else {
//         formik.setFieldValue("company_logo", file);
//       }
//     }
//   };

//   return (
//     <>
//       <Header/>
//       <Box sx={{ display: "flex" }}>
//         <Grid container spacing={2}>
//           <Grid item xs={3}>
//             <Sidebar />
//           </Grid>
//           <Grid item xs={8.9} overflow="hidden" marginTop={10.4}>
//             <Box bgcolor="white">
//               <Box padding={3}>
//                 <Typography
//                   variant="h5"
//                   fontFamily="Work Sans"
//                   fontSize="20px"
//                   fontWeight="600"
//                   fontStyle="normal"
//                   lineHeight="28px"
//                 >
//                   Profile
//                 </Typography>
//                 <Typography
//                   variant="subtitle2"
//                   color="#707187"
//                   fontFamily="Work Sans"
//                   fontSize="16px"
//                   fontStyle="normal"
//                   fontWeight="500"
//                   lineHeight="22px"
//                   marginTop={2}
//                 >
//                   Please fill in the following details to create your account.
//                 </Typography>
//                 <Box>
//                   <Stack
//                     direction={{ xs: "column", sm: "row" }}
//                     spacing={{ xs: 1, sm: 2, md: 4 }}
//                     paddingBottom={3}
//                     marginTop={4}
//                   >
//                     <Avatar
//                       alt="Profile Picture"
//                       src={profileImg}
//                       sx={{ width: 88, height: 88 }}
//                     />
//                     <input
//                       type="file"
//                       name="profile_image"
//                       ref={fileInputRef}
//                       accept="image/*"
//                       onChange={(event) => handleFileChange(event, true)}
//                       style={{ display: "none" }}
//                     />
//                     <Button
//                       variant="outlined"
//                       type="button"
//                       sx={{ width: 156, height: 32 }}
//                       style={{ marginTop: "30px", marginLeft: "15px" }}
//                       onClick={handleButtonClick}
//                     >
//                       Change Image
//                     </Button>
//                   </Stack>

//                   {/* Form type toggle buttons */}
//                   <Grid
//                     item
//                     xs={12}
//                     sm={3}
//                     display="flex"
//                     alignItems="center"
//                     gap={3}
//                   >
//                     <Button
//                       variant={
//                         formType === "PRIVATE" ? "contained" : "outlined"
//                       }
//                       onClick={() => setFormType("PRIVATE")}
//                       disabled={isCommercialAccount}
//                     >
//                       PRIVATE
//                     </Button>
//                     <Button
//                       variant={
//                         formType === "COMMERCIAL" ? "contained" : "outlined"
//                       }
//                       onClick={() => setFormType("COMMERCIAL")}
//                       disabled={isPrivateAccount}
//                     >
//                       COMMERCIAL
//                     </Button>
//                   </Grid>

//                   <form className="form" onSubmit={formik.handleSubmit}>
//                     {/* PRIVATE form */}
//                     {formType === "PRIVATE" && (
//                       <>
//                         <ProfilePage formik={formik} />
//                       </>
//                     )}

//                     {/* Commercial form */}
//                     {formType === "COMMERCIAL" && (
//                       <>
//                         <CommercialPage formik={formik} />
//                       </>
//                     )}

//                     {/* Submit Button */}
//                     <Stack
//                       direction="row"
//                       spacing={2}
//                       mt={15}
//                       justifyContent="flex-end"
//                     >
//                       <Button variant="outlined" disabled sx={buttonStyle}>
//                         Discard
//                       </Button>
//                       <Button
//                         variant="contained"
//                         type="submit"
//                         sx={{
//                           ...buttonStyle,
//                           backgroundColor: "#00A6E8",
//                           color: "white",
//                         }}
//                       >
//                         Update Profile
//                       </Button>
//                     </Stack>
//                   </form>
//                 </Box>
//               </Box>
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default Profile;

const validateDateFormat = (value) => {
  if (!value) return false;
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
  return datePattern.test(value);
};

const validationSchema = yup.object({
  nickname: yup.string().required("Nickname is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test("is-valid-date", "Date of birth must be in the format dd/mm/yyyy", validateDateFormat),
  gender: yup.string().required("Gender is required"),
  email: yup.string().email("Invalid email format").required("Personal email is required"),
  telephone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Personal telephone is required"),
  phone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Phone Number is required"),
  address: yup.string().required("Personal address is required"),
  zipcode: yup.string().required("Personal zipcode is required"),
  country: yup.string().required("Country is required"),
});

const commercialValidationSchema = yup.object({
  company_name: yup.string().required("Company Name is required"),
  company_address: yup.string().required("Address is required"),
  company_zipcode: yup.string().required("Zip Code is required"),
  company_country: yup.string().required("Country is required"),
  company_email: yup.string().email("Invalid email address").required("Email Id is required"),
  company_telephone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Telephone is required"),
  company_phone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Phone Number is required"),
  company_website: yup.string().required("Website is required"),
  company_gst: yup.string().required("GST Number is required"),
  nickname: yup.string().required("Nickname is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test("is-valid-date", "Date of birth must be in the format dd/mm/yyyy", validateDateFormat),
  gender: yup.string().required("Gender is required"),
  email: yup.string().email("Invalid email format").required("Personal email is required"),
  telephone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Personal telephone is required"),
  phone: yup
    .string()
    .transform((value) => (Array.isArray(value) ? value[0] : value.trim()))
    .required("Phone Number is required"),
  address: yup.string().required("Personal address is required"),
  zipcode: yup.string().required("Personal zipcode is required"),
  country: yup.string().required("Country is required"),
});

const Profile = () => {
  const [formType, setFormType] = useState("PRIVATE");
  const [profileImg, setProfileImg] = useState(null);

  const { currentUserData } = useAppSelectors();

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const accountType = Number(currentUserData?.type);  
  

  const initialPrivateValues = {
    nickname: currentUserData?.nickname || "",
    first_name: currentUserData?.firstName || "",
    last_name: currentUserData?.lastName || "",
    dob: currentUserData?.dob || "",
    gender: currentUserData?.gender ? currentUserData.gender.toLowerCase() : "",
    email: currentUserData?.email || "",
    telephone: Array.isArray(currentUserData?.telephone)
      ? currentUserData.telephone
      : [currentUserData?.telephone || ""],
    phone: Array.isArray(currentUserData?.phone)
      ? currentUserData.phone
      : [currentUserData?.phone || ""],
    address: currentUserData?.address || "",
    zipcode: currentUserData?.zipcode || "",
    country: currentUserData?.country || "",
    profile_image: null,
  };

  const initialCommercialValues = {
    company_name: currentUserData?.companyName || "",
    company_address: currentUserData?.companyAddress || "",
    company_zipcode: currentUserData?.companyZipcode || "",
    company_country: currentUserData?.companyCountry || "",
    company_email: currentUserData?.companyEmail || "",
    company_telephone: Array.isArray(currentUserData?.companyTelephone)
      ? currentUserData.companyTelephone
      : [currentUserData?.companyTelephone || ""],
    company_phone: Array.isArray(currentUserData?.companyPhone)
      ? currentUserData.companyPhone
      : [currentUserData?.companyPhone || ""],
    company_website: currentUserData?.companyWebsite || "",
    company_gst: currentUserData?.companyGst || "",
    nickname: currentUserData?.nickname || "",
    first_name: currentUserData?.firstName || "",
    last_name: currentUserData?.lastName || "",
    dob: currentUserData?.dob || "",
    gender: currentUserData?.gender ? currentUserData.gender.toLowerCase() : "",
    email: currentUserData?.email || "",
    telephone: Array.isArray(currentUserData?.telephone)
      ? currentUserData.telephone
      : [currentUserData?.telephone || ""],
    phone: Array.isArray(currentUserData?.phone)
      ? currentUserData.phone
      : [currentUserData?.phone || ""],
    address: currentUserData?.address || "",
    zipcode: currentUserData?.zipcode || "",
    country: currentUserData?.country || "",
    company_logo: null,
    profile_image: null,
  };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formType === "PRIVATE" ? validationSchema : commercialValidationSchema),
    defaultValues: formType === "PRIVATE" ? initialPrivateValues : initialCommercialValues,
  });

  useEffect(() => {
    if (currentUserData) {
      const initialValues = formType === "PRIVATE" ? initialPrivateValues : initialCommercialValues;
      reset(initialValues);
    }
  }, [currentUserData, formType, reset]);

  useEffect(() => {
    if (currentUserData?.profileImage) {
      setProfileImg(currentUserData.profileImage);
    }
  }, [currentUserData?.profileImage]);

  useEffect(() => {
    if (accountType === "COMMERCIAL") {
      setFormType("PRIVATE");
    }
  }, [accountType]);




  // const onSubmit = async (data) => {
  //   const filteredValues = { ...data };

  //   if (formType === "PRIVATE") {
  //     filteredValues.telephone = Array.isArray(data.telephone) ? data.telephone : [data.telephone];
  //     filteredValues.phone = Array.isArray(data.phone) ? data.phone : [data.phone];
  //   } else if (formType === "COMMERCIAL") {
  //     filteredValues.company_telephone = Array.isArray(data.company_telephone)
  //       ? data.company_telephone
  //       : [data.company_telephone];
  //     filteredValues.company_phone = Array.isArray(data.company_phone)
  //       ? data.company_phone
  //       : [data.company_phone];
  //   }

  //   const formData = new FormData();
  //   formData.append("data", JSON.stringify(filteredValues));

  //   if (data.profile_image) {
  //     formData.append("profile_image", data.profile_image);
  //   }
  //   if (data.company_logo) {
  //     formData.append("company_logo", data.company_logo);
  //   }

  //   try {
  //     await dispatch(updateProfileImage(formData));
  //     await dispatch(updateProfile(formData));
  //     toast.success("Profile updated successfully!");
  //   } catch (error) {
  //     toast.error("Failed to update profile: " + (error.message || "Unknown error"));
  //   }
  // };

  const onSubmit = async (data) => {
    const filteredValues = { ...data };

    if (formType === "PRIVATE") {
      filteredValues.telephone = Array.isArray(data.telephone)
        ? data.telephone
        : [data.telephone];
      filteredValues.phone = Array.isArray(data.phone)
        ? data.phone
        : [data.phone];
    } else if (formType === "COMMERCIAL") {
      filteredValues.company_telephone = Array.isArray(data.company_telephone)
        ? data.company_telephone
        : [data.company_telephone];
      filteredValues.company_phone = Array.isArray(data.company_phone)
        ? data.company_phone
        : [data.company_phone];
    }

    try {
      if (data.profile_image) {
        const profileImageData = new FormData();
        profileImageData.append("profile_image", data.profile_image);
        await dispatch(updateProfileImage(profileImageData));
      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(filteredValues));

      if (data.company_logo) {
        formData.append("company_logo", data.company_logo);
      }

      await dispatch(updateProfile(formData));

      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error(
        "Failed to update profile: " + (error.message || "Unknown error")
      );
    }
  };


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event, isProfileImage = true) => {
    const file = event.target.files[0];
    if (file) {
      if (isProfileImage) {
        setProfileImg(URL.createObjectURL(file));
        setValue("profile_image", file);
      } else {
        setValue("company_logo", file);
      }
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={10.4}>
            <Box bgcolor="white">
              <Box padding={3}>
                <Typography variant="h5" fontFamily="Work Sans" fontSize="20px" fontWeight="600">
                  Profile
                </Typography>
                <Typography variant="subtitle2" color="#707187" marginTop={2}>
                  Please fill in the following details to create your account.
                </Typography>
                <Box>
                  <Stack direction="row" spacing={2} paddingBottom={3} marginTop={4}>
                    <Avatar alt="Profile Picture" src={profileImg} sx={{ width: 88, height: 88 }} />
                    <input
                      type="file"
                      name="profile_image"
                      ref={fileInputRef}
                      accept="image/*"
                      onChange={(event) => handleFileChange(event, true)}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      type="button"
                      sx={{ width: 156, height: 32, marginTop: "30px", marginLeft: "15px" }}
                      onClick={handleButtonClick}
                    >
                      Change Image
                    </Button>
                  </Stack>

                  <Grid item xs={12} sm={3} display="flex" alignItems="center" gap={3}>
                    <Button
                      variant={formType === "PRIVATE" ? "contained" : "outlined"}
                      onClick={() => setFormType("PRIVATE")}
                      disabled={accountType === 1}
                    >
                      PRIVATE
                    </Button>
                    <Button
                      variant={formType === "COMMERCIAL" ? "contained" : "outlined"}
                      onClick={() => setFormType("COMMERCIAL")}
                      disabled={accountType === 0}
                    >
                      COMMERCIAL
                    </Button>
                  </Grid>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    {formType === "PRIVATE" && <ProfilePage control={control} errors={errors} />}
                    {formType === "COMMERCIAL" && (
                      <CommercialPage control={control} errors={errors} setValue={setValue}/>
                    )}

                    <Stack direction="row" spacing={2} mt={15} justifyContent="flex-end">
                      <Button variant="outlined" disabled>
                        Discard
                      </Button>
                      <Button variant="contained" type="submit" sx={{ backgroundColor: "#00A6E8" }}>
                        Update Profile
                      </Button>
                    </Stack>
                  </form>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
import React, { useEffect } from "react";
import { Box } from "@mui/material";

// Components
import Header from "../../../components/Header/Header";
import Category from "../../../components/Cateory/Category";
import Banner from "../../../components/Banner/Banner";

// COmponents
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";
import PopularCategory from "../../../components/PopularCategory/PopularCategory";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerList } from "../../../store/helper/banner";
import { fetchCategoryList } from "../../../store/helper/categorySlice";
import {
  fetchNewListingProductList,
  fetchPopularCategoryList,
  fetchSponsoredProductList,
  fetchTrendyProductList,
} from "../../../store/product/productSlice";
import { fetchAppAds } from "../../../store/myads/myadsSlice";
import AdvertisementDialogBox from "../../../components/AdvertisementDialogBox/AdvertisementDialogBox";
import useAppSelectors from "../../../customHooks/useAppSelector";

function Home() {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAd, setSelectedAd] = React.useState(null);

  const {
    newListingProductData,
    sponsoredProductData,
    trendyProductData,
    popularProductData,
  } = useAppSelectors();

  // Getting banner list from slice
  const bannerList = useSelector((state) => state?.bannerReducer?.data);

  // Category list
  const categoryList = useSelector(
    (state) => state?.categoryReducer?.mainCategorydata
  );

  const appAdsList = useSelector((state) => state?.myadsReducer?.appAdsData);

  const shouldShowDiv = true; // Or false, depending on your logic

  const handleDialogBox = (ad) => {
    setSelectedAd(ad);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAd(null);
  };

  const handleContinue = () => {
    const ad = appAdsList?.find((ad) => ad.position === "homepage_top_left");
    if (ad) {
      window.open(ad.url, "_blank", "noopener,noreferrer");
    }
    handleCloseDialog();
  };

  useEffect(() => {
    dispatch(fetchAppAds());
    dispatch(fetchNewListingProductList());
    dispatch(fetchSponsoredProductList());
    dispatch(fetchTrendyProductList());
    dispatch(fetchPopularCategoryList());
    dispatch(fetchBannerList());
    dispatch(fetchCategoryList());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ dispay: "flex" }}>
        <Header />
        <Box sx={{ marginLeft: "16px", marginRight: "16px" }} mt={10.4}>
          <Category categories={categoryList} />
          <Banner
            data={bannerList}
            onBannerClick={(banner) => handleDialogBox(banner)}
          />

          <Box sx={{ display: "flex", gap: "14px" }}>
            <Box minWidth={800}>
              <ProductSlide
                title="New Listing"
                products={newListingProductData}
              />
            </Box>
            <Box
              id="homepage_top_left"
              sx={{
                // width: "501px",
                // height: "371px",
                backgroundColor: "#fff",
                border: "1px solid #d8d8df",
                marginTop: "24px",
                borderRadius: "4px",
                textAlign: "-webkit-center",
                paddingTop: appAdsList?.find(
                  (ad) => ad.position === "homepage_top_left"
                )
                  ? "0"
                  : "180px",

                color: "grey",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={handleDialogBox}
            >
              {appAdsList?.find((ad) => ad.position === "homepage_top_left") ? (
                <img
                  src={
                    appAdsList?.find(
                      (ad) => ad.position === "homepage_top_left"
                    ).data
                  }
                  alt={
                    appAdsList?.find(
                      (ad) => ad.position === "homepage_top_left"
                    ).name
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fit",
                  }}
                />
              ) : (
                "Ads"
              )}
            </Box>
          </Box>

          <ProductSlide
            title="Trendy Deal"
            showDiv={shouldShowDiv}
            products={trendyProductData}
          />

          <PopularCategory categories={popularProductData} />

          <Box sx={{ display: "flex", gap: "14px" }}>
            <Box
              sx={{
                // width: "501px",
                // height: "371px",
                backgroundColor: "#fff",
                border: "1px solid #d8d8df",
                marginTop: "24px",
                borderRadius: "4px",
                textAlign: "-webkit-center",
                paddingTop: appAdsList?.find(
                  (ad) => ad.position === "homepage_top_left"
                )
                  ? "0"
                  : "180px",

                color: "grey",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={handleDialogBox}
            >
              {appAdsList?.find((ad) => ad.position === "homepage_top_left") ? (
                <img
                  src={
                    appAdsList?.find(
                      (ad) => ad.position === "homepage_top_left"
                    ).data
                  }
                  alt={
                    appAdsList?.find(
                      (ad) => ad.position === "homepage_top_left"
                    ).name
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fit",
                  }}
                />
              ) : (
                "Ads"
              )}
            </Box>
            <Box minWidth={800}>
              <ProductSlide
                title="Popular Products"
                // products={popularProductData}
              />
            </Box>
          </Box>

          <Box>
            <ProductSlide
              title="Sponsored Listing"
              products={sponsoredProductData}
            />
          </Box>
        </Box>

        <Footer />
      </Box>
      {dialogOpen && (
        <AdvertisementDialogBox
          adId={selectedAd?.id}
          onClose={handleCloseDialog}
          onContinue={handleContinue}
        />
      )}
    </>
  );
}

export default Home;

import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

const ColorBox = ({ hexValue }) => (
  <span
    style={{
      height: "1rem",
      width: "1rem",
      backgroundColor: `#${hexValue}`,
      display: "inline-block",
      marginRight: "0.5rem",
    }}
  />
);

const ColorOption = ({ label, hexValue }) => (
  <span style={{ display: "flex", alignItems: "center" }}>
    <ColorBox hexValue={hexValue} />
    {label}
  </span>
);

//  return (
//                       <Box width={"100%"} key={name} mb={"0.85rem"}>
//                         <FormControl variant="standard" fullWidth>
//                           <InputLabel>{label}</InputLabel>
//                           <Controller
//                             name={name}
//                             control={control}
//                             render={({ field }) => (
//                               <Select
//                                 {...field}
//                                 label={label}
//                                 disabled={disabled}
//                                 value={getValues(name)}
//                                 renderValue={(selected) => {
//                                   const selectedOption = options.find(
//                                     (option) => option.value === selected
//                                   );
//                                   return selectedOption ? (
//                                     <span
//                                       style={{
//                                         display: "flex",
//                                         alignItems: "center",
//                                       }}
//                                     >
//                                       <span
//                                         style={{
//                                           height: "1rem",
//                                           width: "1rem",
//                                           backgroundColor: `#${selectedOption?.hexValue}`,
//                                           display: "inline-block",
//                                           marginRight: "0.5rem",
//                                         }}
//                                       ></span>
//                                       {selectedOption.label}
//                                     </span>
//                                   ) : (
//                                     ""
//                                   );
//                                 }}
//                                 error={!!errors[name]}
//                               >
//                                 <MenuItem value="">
//                                   <em>None</em>
//                                 </MenuItem>
//                                 {options.map((option) => (
//                                   <MenuItem
//                                     key={option.value}
//                                     value={option.value}
//                                   >
//                                     <span
//                                       style={{
//                                         height: "1rem",
//                                         width: "1rem",
//                                         backgroundColor: `#${option?.hexValue}`,
//                                         display: "inline-block",
//                                         marginRight: "0.5rem",
//                                       }}
//                                     ></span>
//                                     {option.label}
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             )}
//                           />
//                           {errors[name] && (
//                             <div style={{ color: "red" }}>
//                               {errors[name].message}
//                             </div>
//                           )}
//                         </FormControl>
//                       </Box>
//                     );

const ColorSelectField = ({
  name,
  label,
  control,
  disabled,
  options,
  errors,
  getValues,
}) => {
  // Create lookup map for options
  const optionsMap = React.useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, []);

  return (
    <Box width="100%" mb="0.85rem">
      <FormControl variant="standard" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label={label}
              disabled={disabled}
              value={getValues(name)}
              renderValue={(selected) => {
                const selectedOption = optionsMap[selected];
                return selectedOption ? (
                  <ColorOption
                    label={selectedOption.label}
                    hexValue={selectedOption.hexValue}
                  />
                ) : (
                  ""
                );
              }}
              error={!!errors[name]}
            >
              <MenuItem sx={{display:"none"}} value="">
                <em>None</em>
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ColorOption
                    label={option.label}
                    hexValue={option.hexValue}
                  />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors[name] && (
          <div style={{ color: "red" }}>{errors[name].message}</div>
        )}
      </FormControl>
    </Box>
  );
};

export default ColorSelectField;

import {
  Box,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdsByCategory } from "../../store/helper/categorySlice";
import { useNavigate } from "react-router-dom";

const CategoryList = ({ categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoriesData = useSelector((state) => state.categoryReducer || []);
  const adsProduct = useSelector(
    (state) => state.categoryReducer?.products || []
  );

  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedMainCategorySlug, setSelectedMainCategorySlug] =
    useState(null);
  const [selectedSubCategorySlug, setSelectedSubCategorySlug] = useState(null);

  const handleMainCategoryClick = (category) => {
    setSelectedMainCategory(category.id);
    setSelectedMainCategorySlug(category.slug);
    setSelectedSubCategory(null);
    setSelectedSubCategorySlug(null);
  };

  const handleSubCategoryClick = (subcategory) => {
    setSelectedSubCategory(subcategory.id);
    setSelectedSubCategorySlug(subcategory.slug);
    dispatch(fetchAdsByCategory(subcategory.slug));
  };

  useEffect(() => {
    const filteredAds = filterAdsByCategory(adsProduct);
    if (filteredAds?.length > 0) {
      navigate("/ads-page", { state: { ads: filteredAds } });
    }
  }, [adsProduct, navigate]);

  useEffect(() => {
    if (selectedSubCategorySlug) {
      dispatch(fetchAdsByCategory(selectedSubCategorySlug));
    } else if (selectedMainCategorySlug) {
      dispatch(fetchAdsByCategory(selectedMainCategorySlug));
    }
  }, [dispatch, selectedMainCategorySlug, selectedSubCategorySlug]);

  // const renderSubcategoryButtons = (subCategories, onClickHandler) => {
  //   return subCategories?.map((subcategory) => (
  //     <Grid item xs={12} key={subcategory.id}>
  //       <Button
  //         fullWidth
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           textAlign: "center",
  //           padding: 2,
  //           width: "100%",
  //           minHeight: "80px",
  //           backgroundColor:
  //             selectedSubCategory === subcategory.id
  //               ? "#e0e0e0"
  //               : "transparent",
  //           boxShadow: "none",
  //           border: "none",
  //           "&:hover": {
  //             backgroundColor: "#f5f5f5",
  //           },
  //         }}
  //         onClick={() => onClickHandler(subcategory)}
  //         aria-label={subcategory.name}
  //       >
  //         <img
  //           src={subcategory.icon || "https://via.placeholder.com/40"}
  //           alt={subcategory.name}
  //           style={{
  //             marginBottom: "5px",
  //             borderRadius: "50%",
  //             width: "60px",
  //             height: "60px",
  //           }}
  //         />
  //         {subcategory.name}
  //       </Button>
  //     </Grid>
  //   ));
  // };
const renderSubcategoryButtons = (subCategories, onClickHandler) => {
  return subCategories?.map((subcategory) => (
    <Grid item xs={12} key={subcategory.id}>
      <Button
        fullWidth
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 2,
          border:"1px solid black",
          width: "100px",
          minHeight: "80px",
          backgroundColor:
            selectedSubCategory === subcategory.id ? "#e0e0e0" : "transparent",
          boxShadow: "none",
          border: "none",
          transition: "none",
          transform: "none",
          "&:hover": {
            backgroundColor: "#f5f5f5",
            minHeight: "80px",
          },
          "&:active": {
            backgroundColor: "#d9d9d9",
            transform: "none !important",
            boxShadow: "none !important",
            minHeight: "80px",
          },
          "&:focus": {
            outline: "none",
            boxShadow: "none",
            transform: "none !important",
            boxShadow: "none !important",
            minHeight: "80px",
          },
        }}
        onClick={() => onClickHandler(subcategory)}
        aria-label={subcategory.name}
      >
        <img
          src={subcategory.icon || "https://via.placeholder.com/40"}
          alt={subcategory.name}
          style={{
            marginBottom: "5px",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
          }}
        />
        {subcategory.name}
      </Button>
    </Grid>
  ));
};

  const renderCategoryButtons = () => {
    return categoriesData?.subCategoryMap[categoryId]?.map((category) => (
      <Grid item xs={12} key={category.id}>
        <Button
          // fullWidth
          // sx={{
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   textAlign: "center",
          //   padding: 2,
          //   maxWidth: "100%",
          //   backgroundColor:
          //     selectedMainCategory === category.id ? "#e0e0e0" : "transparent",
          //   boxShadow: "none",
          //   border: "none",
          //   "&:hover": {
          //     backgroundColor: "#f5f5f5",
          //   },
          fullWidth
          disableRipple
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            border: "1px solid black",
            padding: 2,
            width: "100%",
            minHeight: "80px",
            backgroundColor:
              selectedMainCategory === category.id ? "#e0e0e0" : "transparent",
            boxShadow: "none",
            border: "none",
            transition: "none",
            transform: "none",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              minHeight: "80px",
            },
            "&:active": {
              backgroundColor: "#d9d9d9",
              transform: "none !important",
              boxShadow: "none !important",
              minHeight: "80px",
            },
            "&:focus": {
              outline: "none",
              boxShadow: "none",
              transform: "none !important",
              boxShadow: "none !important",
              minHeight: "80px",
            },
          }}
          onClick={() => handleMainCategoryClick(category)}
          aria-label={category.name}
        >
          <img
            src={category.icon || "https://via.placeholder.com/40"}
            alt={category.name}
            style={{
              marginBottom: "5px",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
            }}
          />
          {category.name}
        </Button>
      </Grid>
    ));
  };

  const filterAdsByCategory = (adsProduct) => {
    return adsProduct?.length>0&&adsProduct?.filter((ad) => {
      const lastSelectedCategory =
        ad.selectedCategories?.[ad.selectedCategories.length - 1];
      return (
        lastSelectedCategory === selectedMainCategory ||
        lastSelectedCategory === selectedSubCategory
      );
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{ minHeight: "100%", width: "80px" }}
      >
        {renderCategoryButtons()}
      </Grid>

      {selectedMainCategory && (
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ minHeight: "100%", width: "80px", ml: 15 }}
        >
          {renderSubcategoryButtons(
            categoriesData.subCategoryMap[categoryId].find(
              (category) => category.id === selectedMainCategory
            )?.subCategory || [],
            handleSubCategoryClick
          )}
        </Grid>
      )}

      {selectedSubCategory && (
        <Grid container spacing={2} sx={{ width: "80px", ml: 15 }}>
          {categoriesData.subCategoryMap[categoryId]
            ?.find((category) => category.id === selectedMainCategory)
            ?.subCategory?.find((sub) => sub.id === selectedSubCategory)
            ?.subCategory?.map((sub) => (
              <Grid item xs={6} md={4} key={sub.id}>
                {renderSubcategoryButtons([sub], handleSubCategoryClick)}
              </Grid>
            ))}
        </Grid>
      )}
      {/* <Grid
        container
        spacing={2}
        direction="column"
        // mt={2}
        sx={{ minHeight: "100%", maxWidth: "100%" }}
      >
       {selectedSubCategory === subcategory.id &&
        subcategory.subCategory?.length > 0 && (
          <Grid container spacing={2} sx={{ paddingLeft: 3 }}>
            {renderSubcategoryButtons(subcategory.subCategory, onClickHandler)}
          </Grid>
        )}
      </Grid> */}
      {/* <Grid
        container
        spacing={2}
        direction="column"
        mt={2}
        sx={{ minHeight: "100%", maxWidth: "100%" }}
      >
        <Dialog
          open={openPopup}
          onClose={handleClosePopup}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Ads</DialogTitle>
          <DialogContent>
            {filterAdsByCategory(adsProduct)?.map((ad) => (
              <ProductSlide
                key={ad.id}
                products={filterAdsByCategory(adsProduct)}
              />
            ))}
          </DialogContent>
        </Dialog>
      </Grid> */}
    </Box>
  );
};

export default CategoryList;

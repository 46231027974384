import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useFireBaseChatHelper } from "../../../customHooks/useFireBaseChatHelper";
import useAppSelectors from "../../../customHooks/useAppSelector";
import { useLocation, useNavigate } from "react-router-dom";

const Styles = {
  menuItemStyle: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  avatorList: {
    width: "70px",
    height: "70px",
    borderRadius: "10%",
    marginLeft: "12px",
  },
  listItems: {
    marginLeft: "10px",
  },
};

const ChatSideBar = ({
  setMessages,
  setLoading,
  selectedChatRoom,
  setSelectedChatRoom,
}) => {
  const location = useLocation();
  const chatRoomData = location.state;
  const [allChatRoomData, setAllChatRoomData] = useState([]);
  const navigate = useNavigate();
  const { currentUserData } = useAppSelectors();
  const { fetchChatRooms, fetchMessages, listenToChatRooms } =
    useFireBaseChatHelper();

  useEffect(() => {
    fetchChatRooms(
      currentUserData?.id,
      setAllChatRoomData,
      setLoading,
      setSelectedChatRoom,
      chatRoomData,
      setMessages
    );
    const unsubscribe = listenToChatRooms(
      currentUserData?.id,
      setAllChatRoomData
    );

    return () => unsubscribe();
  }, []);

  const handleEnterChatRoom = (chatRoom) => {
    setSelectedChatRoom(chatRoom);
    fetchMessages(chatRoom?.id, setMessages);
  };

  useEffect(() => {
    if (chatRoomData) {
      setSelectedChatRoom(chatRoomData);
      fetchMessages(chatRoomData?.id, setMessages);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, []);

  return (
    <Paper
      sx={{
        direction: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          maxHeight: "calc(100vh - 150px)",
        }}
      >
        <MenuList>
          {allChatRoomData?.map((item) => (
            <MenuItem
              key={item.id}
              sx={Styles.menuItemStyle}
              onClick={() => handleEnterChatRoom(item)}
            >
              <Box
                sx={{
                  bgcolor:
                    selectedChatRoom?.id === item.id ? "#00A6E8" : "#fff",
                  color: selectedChatRoom?.id === item.id ? "#fff" : "#000",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                  padding: "10px",
                }}
              >
                <Avatar variant="square" sx={Styles.avatorList}>
                  <img
                    src={item.productImage}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Avatar>

                <Grid container spacing={2} sx={{ ml: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {item.providerName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{item.productName}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {/* {item.lastMessage} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </Paper>
  );
};

export default ChatSideBar;

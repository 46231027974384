import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setCurrentUserData } from "../auth/authSlice";
import { getCookie } from "../../utils/cookie";

const GET_PROFILE_BY_ID = `${process.env.REACT_APP_BASE_URL}/profile`;
const UPDATE_PROFILE = `${process.env.REACT_APP_BASE_URL}/profile`;
const UPDATE_PROFILE_IMAGE = `${process.env.REACT_APP_BASE_URL}/profile`;

const getToken = () => getCookie(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  // data: null,
  status: null,
  message: null,
  faqData: [],
  profileImage: null,
  data: {
    profile: null,
    ads: [],
  },
  statesData: [],
  citiesData: [],
};

export const ProfileSlice = createSlice({
  name: "ProfileList",
  initialState,
  reducers: {
    profileDetailSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.ads = action.payload.ads;
    },
    profileDetailFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    updateProfileSuccess: (state, action) => {
      // state.data = action.payload.data;
      state.message = action.payload.data;
    },
    updateProfileFailure: (state, action) => {
      state.data = action.payload.data;
      state.message = action.payload.data;
    },
    updateProfileImageSuccess: (state, action) => {
      state.message = action.payload.message;
    },
    updateProfileImageFailure: (state, action) => {
      state.message = action.payload.message;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    //location
    getStatesSuccess: (state, action) => {
      state.statesData = action.payload;
    },
    getCitiesSuccess: (state, action) => {
      state.citiesData = action.payload;
    },
  },
});

export const {
  profileDetailSuccess,
  profileDetailFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfileImageSuccess,
  updateProfileImageFailure,
  setProfileImage,
  getStatesSuccess,
  getCitiesSuccess
} = ProfileSlice.actions;

export const getProfileById = (id) => async (dispatch) => {
  const token = getToken();

  if (isNaN(id)) {
    console.error("Invalid ID provided");
    return;
  }
  try {
    const response = await axios.get(`${GET_PROFILE_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const profileData = response?.data?.data;
    const adsData = response?.data?.data?.ads || []; // Access ads from profile data structure

    // const accountType =
    // sellerData.type !== undefined
    //     ? sellerData.type === 1
    //       ? "COMMERCIAL"
    //       : "PRIVATE"
    //     : "PRIVATE";

    dispatch(
      profileDetailSuccess({
        status: response.data.status,
        message: response.data.message,
        data: profileData,
        ads: adsData,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      profileDetailFailure({
        status: false,
        message: error.message,
        data: null,
      })
    );
  }
};

export const updateProfile = (formData) => async (dispatch) => {
  const token = getToken();

  try {
    const response = await axios.put(`${UPDATE_PROFILE}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(
      setCurrentUserData({
        currentUserData: response?.data?.data,
      })
    );

    return response?.data;
  } catch (error) {
    dispatch(
      updateProfileFailure({
        status: false,
        message: error.message,
        data: null,
      })
    );
  }
};

export const updateProfileImage = (values) => async (dispatch) => {
  const token = getToken();

  try {
    const response = await axios.patch(`${UPDATE_PROFILE_IMAGE}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(
      setCurrentUserData({
        currentUserData: response?.data?.data,
      })
    );

    return response?.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    dispatch(
      updateProfileImageFailure({
        status: false,
        message: error.message,
      })
    );
  }
};


export const addSuggestion = (reqBody) => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/suggestion/add`,
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

// location

export const getStates = () => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/locations/states`);
    dispatch(getStatesSuccess(response.data.data));
  } catch (error) {
    
  }

};

export const getCityByStateId = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/locations/states/${id}`);
    dispatch(getCitiesSuccess(response.data.data));
  } catch (error) {}
};

export const getLocationBySearchQuery = (query) => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/locations?search=${query}`);
    dispatch(getCitiesSuccess(response.data.data));
    return response.data.data;
  } catch (error) {}
};

export default ProfileSlice.reducer;

import React, { useState, useMemo, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  InputAdornment,
  FormLabel,
} from "@mui/material";
import UploadImages from "./UploadImages";
import ComplexSelect from "./ComplexSelect";
import { CloudUpload } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import { getDynamicOption } from "../../store/product/productSlice";
import CustomSelectField from "./CustomFormFields/CustomSelectField";
import ColorSelectField from "./CustomFormFields/ColorSelectField";
import CustomDynamicSelectField from "./CustomFormFields/CustomDynamicSelectField";
import CustomLocationDynamicSelectField from "./CustomFormFields/CustomLocationDynamicSelectField";
import { getLocationBySearchQuery } from "../../store/profile/profileSlice";

const createValidationSchema = (formData, values, location) => {
  const schemaShape = {};

  formData.forEach((field) => {
    const { name, required, label, multiple } = field.props;

    const shouldRender = shouldRenderField(field, values);

    if (shouldRender) {
      switch (field.type) {
        case "text":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "number":
        case "price":
          schemaShape[name] = required
            ? Yup.number()
                .typeError(`${label} must be a number`)
                .required(`${label} is required`)
                .positive(`${label} must be a positive number`)
                .integer(`${label} must be an integer`)
            : Yup.number()
                .typeError(`${label} must be a number`)
                .positive(`${label} must be positive`)
                .integer(`${label} must be an integer`);
          break;
        case "select":
          if (multiple) {
            schemaShape[name] = required
              ? Yup.array()
                  .transform((value, originalValue) => {
                    // Convert empty strings to empty arrays
                    if (originalValue === "") return [];
                    return value;
                  })
                  .min(1, `${label} is required`)
                  .of(Yup.string().required())
              : Yup.array()
                  .transform((value, originalValue) => {
                    if (originalValue === "") return [];
                    return value;
                  })
                  .nullable();
          } else {
            schemaShape[name] = required
              ? Yup.string().required(`${label} is required`)
              : Yup.string();
          }
          break;
        case "dynamicSelect":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "textarea":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "file":
          schemaShape[name] = location.pathname.startsWith("/edit-post/")
            ? false
            : required
            ? Yup.array()
                .transform((value, originalValue) => {
                  if (originalValue === "") return [];
                  return value;
                })
                .required("At least one image is required")
                .min(1, "At least one image is required")
            : Yup.array()
                .transform((value, originalValue) => {
                  if (originalValue === "") return [];
                  return value;
                })
                .nullable();
          break;
        case "date":
          let dateSchema = Yup.date();
          if (required) {
            dateSchema = dateSchema.required(`${label} is required`);
          }
          if (field.props.minLength) {
            const maxPastDate = new Date();
            maxPastDate.setDate(maxPastDate.getDate() - field.props.minLength);
            dateSchema = dateSchema.max(
              maxPastDate,
              `${label} must be at least ${field.props.minLength} days from today`
            );
          }
          if (field.props.maxLength) {
            const maxFutureDate = new Date();
            maxFutureDate.setDate(
              maxFutureDate.getDate() + field.props.maxLength
            );
            dateSchema = dateSchema.max(
              maxFutureDate,
              `${label} must be within ${field.props.maxLength} days from today`
            );
          }
          schemaShape[name] = dateSchema;
          break;
        case "radiogroup":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "checkbox":
          schemaShape[name] = required
            ? Yup.boolean().oneOf([true], `${label} is required`)
            : Yup.boolean();
          break;
        case "checkboxgroup":
          schemaShape[name] = required
            ? Yup.array()
                .min(1, `At least one ${label} must be selected`)
                .of(Yup.string().required())
            : Yup.array().nullable();
          break;
        default:
          break;
      }
    }
  });
  return Yup.object().shape(schemaShape);
};

const shouldRenderField = (field, values) => {
  const { renderConditionally, condition } = field.props;

  if (!renderConditionally) {
    return true;
  }

  const areConditionsMet =
    condition?.length > 0 &&
    condition?.every((condition) => {
      const { fieldName, fieldValue } = condition;
      const currentValue = values[fieldName];
      if (currentValue === undefined || currentValue === null) {
        return false; // or handle as per your logic
      }
      if (Array.isArray(fieldValue)) {
        return fieldValue.includes(currentValue.toString());
      } else {
        return currentValue === fieldValue;
      }
    });

  if (renderConditionally) {
    return areConditionsMet;
  }

  return true;
};

const DynamicForm = ({ formData, handleFormSubmit, formHandleSubmitRef }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [removedUploadedImageUrls, setRemovedUploadedImageUrls] = useState([]);
  const editAdsData = useSelector(
    (state) => state?.myadsReducer?.adsDetailsData
  );
  const initialValues = useMemo(() => {
    if (!formData?.length) return {};

    let initialValues = formData.flat().reduce((acc, field) => {
      const { name, defaultValue, type } = field.props;
      if (type === "ageyearmonth") {
        acc[name] = {
          year: defaultValue?.year || "",
          month: defaultValue?.month || "",
        };
      } else {
        acc[name] = type === "file" ? [] : defaultValue || "";
      }
      return acc;
    }, {});

    if (location.pathname.startsWith("/edit-post/")) {
      for (const [key, value] of Object.entries(editAdsData?.details)) {
        initialValues[key] = value.value;
      }
      setUploadedImageUrls(editAdsData?.images);
    }

    return initialValues;
  }, [formData, location.pathname]);

  const [validationSchema, setValidationSchema] = useState(
    createValidationSchema(formData.flat(), initialValues, location)
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const formValues = watch();
  const prevFormValuesRef = useRef();

  const calculatedFields = useMemo(
    () => formData.flat().filter((field) => field.props.calculateValue),
    [formData]
  );

  const converterFields = useMemo(
    () => formData.flat().filter((field) => field.type === "converter"),
    [formData]
  );
 
  const dependencyConverterFields = useMemo(() => {
    const fields = new Set();
    converterFields.forEach((field) => {
      fields.add(field.props.converterData?.converterFieldName);
    });
    return Array.from(fields);
  }, [formData]);

  const dependencyFields = useMemo(() => {
    const fields = new Set();
    calculatedFields.forEach((field) => {
      fields.add(field.props.calculationData.field1);
      fields.add(field.props.calculationData.field2);
    });
    return Array.from(fields);
  }, [formData]);

  const customHandleChange = async (e, field,customValue,fieldData) => {
    const { name, value } = e.target;
    // console.log(customValue,fieldData)
    if(fieldData?.type==="locationSelect"){
      setValue(fieldData?.props?.setValueOnChange,customValue)
    }

    setValue(name, value);
    await trigger(name);
  };

  const processFormValues = (formData, values) => {
    return formData.flat().reduce((acc, field) => {
      const { name, label, prefix, suffix, showLabelWithValue } = field.props;

      if (!shouldRenderField(field, values)) {
        return acc;
      }
      let displayText = "";

      if (field?.type !== "file") {
        const formatWithAffixes = (value) => {
          const prefixStr = prefix ? `${prefix} ` : "";
          const suffixStr = suffix ? ` ${suffix}` : "";
          return `${prefixStr}${value || ""}${suffixStr}`.trim();
        };

        if (showLabelWithValue) {
          if (Array.isArray(values[name])) {
            if (typeof values[name][0] === "object") {
              displayText = values[name]
                .filter((item) => item.displayData && item.value)
                .map((item) => item.displayData || item.label);
            } else {
              displayText = formatWithAffixes(values[name] || "");
            }
          } else {
            displayText = `${label} ${formatWithAffixes(
              values[name] || ""
            )}`.trim();
          }
        } else {
          if (Array.isArray(values[name])) {
            if (typeof values[name][0] === "object") {
              displayText = values[name]
                .filter((item) => item.displayData && item.value)
                .map((item) => item.displayData || item.value);
            } else {
              displayText = formatWithAffixes(values[name] || "");
            }
          } else {
            if (field?.type === "ageyearmonth") {
              const year = values[name]?.year;
              const month = values[name]?.month;

              displayText = `${
                year ? `${year} year${year > 1 ? "s" : ""}` : ""
              }${year && month ? " and " : ""}${
                month ? `${month} month${month > 1 ? "s" : ""}` : ""
              }`;
            } else {
              displayText = formatWithAffixes(values[name] || "");
            }
          }
        }
      }

      acc[name] = {
        value: values[name],
        label: label,
        displayData:
          typeof values[name] === "boolean"
            ? values[name]
              ? "Yes"
              : "No"
            : displayText,
      };

      return acc;
    }, {});
  };

  const onSubmit = (data) => {
    const finalData = processFormValues(formData, data);

    handleFormSubmit(finalData, removedUploadedImageUrls);
  };
  const performCalculation = (value1, value2, operator) => {
    switch (operator) {
      case "*":
        return value1 * value2;
      case "+":
        return value1 + value2;
      case "-":
        return value1 - value2;
      case "/":
        return value2 !== 0 ? value1 / value2 : 0; // Handle division by zero
      default:
        return 0;
    }
  };

  const calculateFieldValue = (field, values, type = "calculate") => {
    const data =
      type === "calculate"
        ? field.props.calculationData
        : field.props.converterData;

    const value1Key = data.field1 || data.converterFieldName;
    const value2Key = data.field2 || data.converterValue;
    const operator = data.operator || data.converterOperator;
   
    const value1 = parseFloat(values[value1Key]) || 0;
    const value2 =
      type === "calculate"
        ? parseFloat(values[value2Key])
        : parseFloat(value2Key);

    return type === "calculate"
      ? performCalculation(value1, value2, operator)
      : Math.round(performCalculation(value1, value2, operator));
  };

  const getOptionFromApi = async (
    optionSourceFieldValue,
    optionSourceFieldName,
    identifier
  ) => {
    let response = [];
    if (
      prevFormValuesRef.current[optionSourceFieldName] !==
      optionSourceFieldValue
    ) {
      response = await dispatch(
        getDynamicOption(`${optionSourceFieldValue}${identifier}`)
      );
      return response;
    }
    return response;
  };

  const getLocationOptionFromApi = async (
    optionSourceFieldValue,
    locationSourceFieldName
  ) => {
    let response = [];
    if (
      prevFormValuesRef.current[locationSourceFieldName] !==
      optionSourceFieldValue
    ) {
      response = await dispatch(
        getLocationBySearchQuery(optionSourceFieldValue)
      );
      return response;
    }
    return response;
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (dependencyFields.includes(name)) {
        formData.flat().forEach((field) => {
          if (field.props.calculateValue) {
            const { field1, field2 } = field.props.calculationData;
            if (name === field1 || name === field2) {
              const calculatedValue = calculateFieldValue(field, getValues());
              setValue(field.props.name, calculatedValue);
            }
          }
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch()]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (dependencyConverterFields.includes(name)) {
        formData.flat().forEach((field) => {
          if (field.type === "converter") {
            const { converterFieldName } = field.props.converterData;
            if (name === converterFieldName) {
              const currentValue = getValues(field.props.name);
              const convertedValue = calculateFieldValue(
                field,
                getValues(),
                "converter"
              );
              // Only update if value changes
              if (convertedValue !== currentValue) {
                setValue(field.props.name, convertedValue);
              }
            }
          }
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch()]); 

  useEffect(() => {
    if (isEqual(prevFormValuesRef.current, formValues)) {
      return;
    }
    const newValidationSchema = createValidationSchema(
      formData.flat(),
      formValues,
      location
    );
    setValidationSchema((prevSchema) => {
      if (isEqual(prevSchema, newValidationSchema)) {
        return prevSchema;
      }
      return newValidationSchema;
    });

    prevFormValuesRef.current = formValues;
  }, [formValues, formData, location]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {formData.map((fieldGroup, groupIndex) => (
        <Box display="flex" gap="1rem" key={groupIndex + "formRender"}>
          {fieldGroup.map((field) => {
            if (!shouldRenderField(field, watch())) {
              return null;
            }
            const {
              name,
              label,
              placeholder,
              suffix,
              prefix,
              values: options,
              locationSourceFieldName,
              optionSourceFieldName,
              identifier,
              multiple,
              disabled,
              setValueOnChange,
            } = field.props;
            const { type } = field;

            return (
              <Box key={groupIndex + name} mb="1rem" width={"100%"}>
                {(() => {
                  switch (type) {
                    case "text":
                    case "state":
                    case "number":
                    case "converter":
                    case "price":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={label}
                                type={type}
                                disabled={disabled}
                                placeholder={placeholder}
                                value={getValues(name)}
                                error={!!errors[name]}
                                helperText={errors[name]?.message}
                                variant="standard"
                                InputProps={{
                                  startAdornment: prefix ? (
                                    <InputAdornment position="start">
                                      {prefix}
                                    </InputAdornment>
                                  ) : null,
                                  endAdornment: suffix ? (
                                    <InputAdornment position="end">
                                      {suffix}
                                    </InputAdornment>
                                  ) : null,
                                }}
                                onChange={(e) => customHandleChange(e, field)}
                              />
                            )}
                          />
                        </Box>
                      );
                    case "select":
                      return (
                        <CustomSelectField
                          name={name}
                          label={label}
                          control={control}
                          disabled={disabled}
                          multiple={multiple}
                          options={options}
                          errors={errors}
                          customHandleChange={customHandleChange}
                        />
                      );

                    case "color":
                      return (
                        <ColorSelectField
                          name={name}
                          label={label}
                          control={control}
                          disabled={disabled}
                          options={options}
                          errors={errors}
                          getValues={getValues}
                        />
                      );

                    case "dynamicSelect":
                      return (
                        <CustomDynamicSelectField
                          name={name}
                          label={label}
                          control={control}
                          disabled={disabled}
                          multiple={multiple}
                          errors={errors}
                          getValues={getValues}
                          setValue={setValue}
                          optionSourceFieldName={optionSourceFieldName}
                          identifier={identifier}
                          getOptionFromApi={getOptionFromApi}
                        />
                      );

                    case "locationSelect":
                      return (
                        <CustomLocationDynamicSelectField
                          name={name}
                          label={label}
                          control={control}
                          multiple={multiple}
                          errors={errors}
                          getValues={getValues}
                          setValue={setValue}
                          setValueOnChange={setValueOnChange}
                          fieldprops={field}
                          locationSourceFieldName={locationSourceFieldName}
                          getLocationOptionFromApi={getLocationOptionFromApi}
                          customHandleChange={customHandleChange}
                        />
                      );

                    case "textarea":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                disabled={disabled}
                                multiline
                                rows={4}
                                label={label}
                                placeholder={placeholder}
                                error={!!errors[name]}
                                helperText={errors[name]?.message}
                              />
                            )}
                          />
                        </Box>
                      );
                    case "file":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <UploadImages
                                onImagesSelected={(files) => {
                                  field.onChange(files);
                                }}
                                selectedImages={field.value || []}
                                uploadedImageUrls={uploadedImageUrls}
                                setUploadedImageUrls={setUploadedImageUrls}
                                setRemovedUploadedImageUrls={
                                  setRemovedUploadedImageUrls
                                }
                                removedUploadedImageUrls={
                                  removedUploadedImageUrls
                                }
                              />
                            )}
                          />
                          {errors[name] && (
                            <div style={{ color: "red" }}>
                              {errors[name].message}
                            </div>
                          )}
                        </Box>
                      );
                    case "date":
                      const minDate = field.props.pastDate
                        ? "1900-01-01"
                        : new Date().toISOString().split("T")[0];
                      const maxDate = field.props.futureDate
                        ? "2100-12-31"
                        : new Date().toISOString().split("T")[0];
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={label}
                                disabled={disabled}
                                type="date"
                                error={!!errors[name]}
                                helperText={errors[name]?.message}
                                variant="standard"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: minDate,
                                  max: maxDate,
                                }}
                              />
                            )}
                          />
                        </Box>
                      );
                    case "radiogroup":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <FormLabel>{label}</FormLabel>
                          {field.props.viewType === "button" ? (
                            <Box display="flex" gap={1} mt={"0.4rem"}>
                              {options.map((option) => (
                                <Button
                                  sx={{ textTransform: "none" }}
                                  key={option.value}
                                  variant={
                                    watch(name) === option.value
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() => setValue(name, option.value)}
                                >
                                  {option.label}
                                </Button>
                              ))}
                            </Box>
                          ) : (
                            <Controller
                              name={name}
                              control={control}
                              render={({ field }) => (
                                <RadioGroup disabled={disabled} {...field} row>
                                  {options.map((option) => (
                                    <FormControlLabel
                                      key={option.value}
                                      control={<Radio />}
                                      label={option.label}
                                      value={option.value}
                                    />
                                  ))}
                                </RadioGroup>
                              )}
                            />
                          )}
                          {errors[name] && (
                            <div style={{ color: "red" }}>
                              {errors[name].message}
                            </div>
                          )}
                        </Box>
                      );
                    case "signature":
                      return (
                        <>
                          <Box key={name} mb={"0.85rem"}>
                            <FormLabel>{label}</FormLabel>
                            <Box
                              border={1}
                              borderColor="grey.300"
                              borderRadius={2}
                              p={1}
                            >
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id={`signature-upload-${name}`}
                                key={getValues(name)}
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  if (file) {
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      // Save base64 encoded image
                                      setValue(name, reader.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                              <Box>
                                {getValues(name) ? (
                                  // Display uploaded signature
                                  <Box>
                                    <Box>
                                      <img
                                        src={getValues(name)}
                                        alt="Signature"
                                        style={{
                                          maxWidth: "300px",
                                          maxHeight: "200px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                ) : (
                                  // Upload button when no signature is present
                                  <label htmlFor={`signature-upload-${name}`}>
                                    <Button
                                      variant="outlined"
                                      component="span"
                                      startIcon={<CloudUpload />}
                                    >
                                      Upload Signature
                                    </Button>
                                  </label>
                                )}
                              </Box>
                            </Box>
                            {errors[name] && (
                              <div style={{ color: "red" }}>
                                {errors[name].message}
                              </div>
                            )}
                          </Box>
                          {getValues(name) &&
                            !location.pathname.startsWith("/edit-post/") && (
                              <Box>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => setValue(name, "")}
                                  sx={{ mt: 1 }}
                                >
                                  Clear
                                </Button>
                              </Box>
                            )}
                        </>
                      );

                    case "checkbox":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    disabled={disabled}
                                    checked={field.value || false}
                                  />
                                }
                                label={label}
                              />
                            )}
                          />
                          {errors[name] && (
                            <div style={{ color: "red" }}>
                              {errors[name].message}
                            </div>
                          )}
                        </Box>
                      );
                    case "checkboxgroup":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <FormLabel>{label}</FormLabel>
                          <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                              <>
                                {options.map((option) => (
                                  <FormControlLabel
                                    key={option.value}
                                    control={
                                      <Checkbox
                                        {...field}
                                        disabled={disabled}
                                        value={option.value}
                                        checked={field.value?.includes(
                                          option.value
                                        )}
                                      />
                                    }
                                    label={option.label}
                                  />
                                ))}
                              </>
                            )}
                          />
                          {errors[name] && (
                            <div style={{ color: "red" }}>
                              {errors[name].message}
                            </div>
                          )}
                        </Box>
                      );
                    case "ageyearmonth":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <FormLabel>{label || "Age"}</FormLabel>
                          <Box display="flex" alignItems="center" gap="1rem">
                            <Controller
                              name={`${name}.year`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled={disabled}
                                  label="Year"
                                  variant="standard"
                                  type="number"
                                  fullWidth
                                  error={!!errors[name]?.year}
                                  helperText={errors[name]?.year?.message}
                                />
                              )}
                            />
                            <Controller
                              name={`${name}.month`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled={disabled}
                                  label="Month"
                                  variant="standard"
                                  type="number"
                                  fullWidth
                                  error={!!errors[name]?.month}
                                  helperText={errors[name]?.month?.message}
                                />
                              )}
                            />
                          </Box>
                          {(watch(`${name}.year`) === 0 ||
                            watch(`${name}.year`) === "" ||
                            !watch(`${name}.year`)) &&
                            watch(`${name}.month`) < 6 && (
                              <div style={{ color: "red" }}>
                                Age should be 6 months old
                              </div>
                            )}
                          {errors[name] && (
                            <div style={{ color: "red" }}>
                              {errors[name].message}
                            </div>
                          )}
                        </Box>
                      );
                    case "complexSelect":
                      return (
                        <Box width={"100%"} key={name} mb={"0.85rem"}>
                          <ComplexSelect
                            field={field} // Pass the entire field to ComplexSelect
                            values={getValues(name)}
                            setFieldValue={setValue}
                          />
                        </Box>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            );
          })}
        </Box>
      ))}
      <Button
        ref={formHandleSubmitRef}
        sx={{ display: "none" }}
        type="submit"
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
    </Box>
  );
};

export default DynamicForm;

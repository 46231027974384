import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
  } from "firebase/auth";
  import { auth, db } from "../config/firebase.config";
import { addDoc, collection, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, where } from "firebase/firestore";
  
// FireBase funs()
// Sign up a new user
export const FireBaseSignUp = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
      } else {
      }
    }
  };
  
  // Log in an existing user
  export const FireBaseLogIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {

    }
   
  };

  


    // Chat room functions
 export const getOrCreateChatRoom = async (productId, ownerId, customerId, chatData) => {
      const chatRoomsRef = collection(db, 'chatRooms');
      
      // Query for existing chat room
      const q = query(
        chatRoomsRef,
        where('productId', '==', productId),
        where('customerId', '==', customerId),
        limit(1)
      );
  
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id;
      }
  
      // Create new chat room
      const newChatRoom = await addDoc(chatRoomsRef, {
        ...chatData,
        timestamp: serverTimestamp()
      });
      return newChatRoom.id;
    };
  
    // Message handling
    const fetchMessages = (chatRoomId,setMessages) => {
      const messagesRef = collection(db, `chatRooms/${chatRoomId}/messages`);
      const q = query(messagesRef, orderBy('timestamp'));
  
      return onSnapshot(q, (snapshot) => {
        const messageList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(messageList);
        // scrollToBottom();
      });
    };
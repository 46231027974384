import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  EmailOutlined,
  HttpsOutlined,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
// import { FacebookOutlined, Apple, Google } from "@mui/icons-material";
import Google from "../../../assets/images/LoginImages/google.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/auth/authSlice";
import "./register.css";
import Email from "../../../assets/images/LoginImages/mail.svg";
import Password from "../../../assets/images/LoginImages/password.svg";
import { FireBaseSignUp } from "../../../utils/firebasehelper";
import { signInWithGoogle } from "../../../config/firebase.config";
import useSocialLogin from "../../../customHooks/useSocialLogin";

const schema = Yup.object({
  nickname: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubscribed, setSubscribed] = useState(false);
  const [type, setType] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const {handleSocialAuth} = useSocialLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      nickname: "",
      email: "",
      password: "",
      confirmPassword: "",
    }
  });

  const handleEncrypt = (text) => encryptPassword.encrypt(text);

  const onSubmit = async (values) => {
    setLoading(true);
    const reqBody = {
      nickname: values.nickname,
      email: values.email,
      password: handleEncrypt(values.password),
      type,
      is_subscribed: isSubscribed,
    };

    try {
      const res = await dispatch(registerUser(reqBody));
      sessionStorage.setItem("userEmail", values.email);
      toast.success(res?.message || "Registration successful!");
      FireBaseSignUp(values.email, values.password);
      navigate("/email-verification");
      reset();
    } catch (error) {
      toast.error(error.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !termsAccepted || loading;

  const inputField = (label, field, type, icon, show, setShow) => (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        {...register(field)}
        type={show ? "text" : type}
        error={touchedFields[field] && Boolean(errors[field])}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        endAdornment={
          (field === "password" || field === "confirmPassword") && (
            <InputAdornment position="end">
              <IconButton onClick={() => setShow(!show)}>
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {touchedFields[field] && errors[field] && (
        <Typography color="error">{errors[field].message}</Typography>
      )}
    </FormControl>
  );

  return (
    <Box
      sx={{
        border: "0.5px solid #D8D8DF",
        width: "936px",
        minHeight: "462px",
        bgcolor: "#FFF",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "48px" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Welcome to VerkaufAllen!
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: "#707187", fontFamily: "Work Sans", fontSize: "16px" }}
        >
          Please enter your credentials to access your account.
        </Typography>
        <Stack direction="row" spacing={2} mt={4}>
          <Button
            onClick={() => setType(0)}
            variant="outlined"
            sx={{
              bgcolor: type === 0 && "#1976d2",
              color: type === 0 && "#fff",
            }}
          >
            Private
          </Button>
          <Button
            onClick={() => setType(1)}
            variant="outlined"
            sx={{
              bgcolor: type === 1 && "#1976d2",
              color: type === 1 && "#fff",
            }}
          >
            Commercial
          </Button>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Nick Name",
                "nickname",
                "text",
                <Person />,
                false,
                () => {}
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Email Id",
                "email",
                "email",
                <img
                  src={Email}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                false,
                () => {}
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Password",
                "password",
                "password",
                <img
                  src={Password}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                showPassword,
                setShowPassword
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {inputField(
                "Confirm Password",
                "confirmPassword",
                "password",
                <img
                  src={Password}
                  alt="icon"
                  style={{ width: 20, height: 20 }}
                />,
                showConfirmPassword,
                setShowConfirmPassword
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch onChange={(e) => setSubscribed(e.target.checked)} />
                }
                label="Yes, I look forward to receiving regular updates by email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                }
                label="By signing up, you agree to our terms and conditions and Privacy Policy."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, width: "300px" }}
            disabled={isButtonDisabled}
          >
            {loading ? "Registering..." : "Register"}
          </Button>
        </form>
        <div className="otherOption">
          <p>Or continue with</p>
        </div>
        <Divider className="horizontalLine" />
        <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
          <Button
            onClick={() => handleSocialAuth("google")}
            startIcon={<img src={Google} alt="Google" width="27" />}
            sx={{
              backgroundColor: "#eeeeee",
              textTransform: "none",
              color: "black",
              fontWeight: 600,
              font: "Work Sans",
              fontSize:"16px",
            }}
          >
            Continue With Google
          </Button>
          {/* <Button
            onClick={()=>handleSocialAuth('apple')}
            startIcon={<FacebookOutlined />}
            sx={{ backgroundColor: "#eeeeee", textTransform: "none" }}
          >
            Continue With Facebook
          </Button>
          <Button
            onClick={()=>handleSocialAuth('facebook')}
            startIcon={<Apple />}
            sx={{ backgroundColor: "#eeeeee", textTransform: "none" }}
          >
            Continue With Apple
          </Button> */}
        </Stack>
      </Box>
    </Box>
  );
}

export default Register;
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import CategoryList from "../CategoryList/CategoryList";
import Header from "../Header/Header";
import { getCategoryById } from "../../store/helper/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import { fetchAdsByCategory } from "../../store/helper/categorySlice";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const demoImg =
  "https://s3-alpha-sig.figma.com/img/6eb6/4ba2/ba2906440a85e4d8dcaa3d081dd0ffcf?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SoU3-RVHvchgLx5yiTxzX2WB14X7ExsroEc-HApapxUp7kFexu~W6fw0AMW3DPqmoOqpA0PEdTj-ZSJGOGBQnFfX034VNIxKCs9BwGr5vQqIcLbSuRpdDJXZolBXKB8sxkiKNRTaVNlCxhdh1kKHgKR2BrYPr3Wugmeot01EHV4QQF0m~ySYjOfyj1RNVKoR6ZrnMZv7dDXRHGr8wooPjtr74YjwzsHqmnWrmiONBUo4jdosdjygZ2NZ1DAXl8uPtj0yTsytiDqkJmlGTjcbh9A3yDM8q8YHJy5GBQdQ0e2L1IgN0Wt8DEs1WTYRLxTXDGNfRuJIw-hN1TQN~V5-Sw__";

const CategoryCard = () => {
  const [value, setValue] = useState(0);
  const [valueTab, setValueTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const adsProduct = useSelector(
    (state) => state.categoryReducer || []
  );
  const categoriesData = useSelector((state) => state.categoryReducer || []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update the search query state
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const selectedCategorySlug =
      categoriesData?.subCategoryMap[slug]?.[newValue]?.slug;
    dispatch(getCategoryById(selectedCategorySlug));
  };

  useEffect(() => {
    if (slug) {
      dispatch(getCategoryById(slug)).then((res) => {
        if (res?.data?.data?.length > 0) {
          dispatch(getCategoryById(res?.data?.data[0].slug));
        }
      });
    }
  }, [dispatch, slug]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Filter categories based on the search query
  const filteredCategories = categoriesData?.subCategoryMap[slug]?.filter(
    (category) =>
      category?.slug?.toLowerCase().includes(searchQuery.toLowerCase()) // Match slug with the search query
  );
  //  useEffect(() => {
  //    if (filteredCategories?.length === 0) {
  //      navigate("/ads"); // <-- Navigate to ads page
  //    }
  //  }, [filteredCategories, navigate]);

  useEffect(() => {
    if (adsProduct.length > 0) {
      dispatch(fetchAdsByCategory(adsProduct));
    }
  }, [adsProduct, dispatch]);

  useEffect(() => {
    if (!filteredCategories || filteredCategories.length === 0) {
      dispatch(fetchAdsByCategory(slug)).then((res) => {
        if (res?.length > 0) {
          navigate("/ads-page", { state: { ads: res } });
        }
      });
    }
  }, [dispatch, slug, filteredCategories, navigate]);

  return (
    <>
      <Header />
      <Box
        sx={{
          margin: "85px 10px",
          height: "100%",
          border: "1px solid #D8D8DF",
          borderRadius: "4px",
        }}
      >
        <Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.paper",
              borderBottom: 1,
              borderColor: "divider",
              paddingTop: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: "19px",
                paddingLeft: "10px",
                textTransform: "uppercase",
              }}
            >
              {slug}
            </Typography>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mr: 2,
                paddingBottom: 1,
              }}
            >
              <SearchOutlined sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="Search"
                label="Search"
                variant="standard"
                value={searchQuery} // Controlled value for search
                onChange={handleSearchChange} // Trigger search on input change
              />
            </Box> */}
          </Box>
        </Stack>

        <Stack>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                width: "138px",
                backgroundColor: "#F7F7F8",
              }}
            >
              {filteredCategories?.map((category) => (
                <Tab
                  key={category.id}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={category.icon || demoImg}
                        alt={category.name}
                        sx={{
                          width: 40,
                          height: 40,
                          marginBottom: 1,
                          borderRadius: 30,
                        }}
                      />
                      {category.name}
                    </Box>
                  }
                />
              ))}
            </Tabs>

            {filteredCategories?.map((category, i) => (
              <TabPanel key={category.id} value={value} index={i}>
                {valueTab === 0 ? (
                  <CategoryList categoryId={category.slug} />
                ) : (
                  <Typography>
                    {category.description || `Item ${i + 1}`}
                  </Typography>
                )}
              </TabPanel>
            ))}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default CategoryCard;

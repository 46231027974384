import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { Controller } from "react-hook-form";

const sectionHeaderStyle = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontWeight: 500,
  marginTop: "32px",
};

const inputLabelStyle = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "13px",
  fontWeight: 500,
};

const buttonStyle = {
  border: "0.5px dashed grey",
  color: "grey",
  width: "200px",
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 5px",
  textTransform: "none",
  marginTop: "4px",
};

// const FormField = ({ id, label, placeholder, note, formik, disabled }) => {
//   return (
//     <FormControl variant="standard" fullWidth>
//       <InputLabel htmlFor={id} sx={inputLabelStyle}>
//         {label} {note && <small>{note}</small>}
//       </InputLabel>
//       <Input
//         id={id}
//         placeholder={placeholder}
//         name={id}
//         value={formik.values[id] || ""}
//         onChange={formik.handleChange}
//         onBlur={formik.handleBlur}
//         disabled={disabled}
//       />
//       {formik.touched[id] && formik.errors[id] && (
//         <FormHelperText sx={{ color: "red" }}>
//           {formik.errors[id]}
//         </FormHelperText>
//       )}
//     </FormControl>
//   );
// };


// const CommercialPage = ({ formik }) => {
//   const [logoFileName, setLogoFileName] = useState("");

//   return (
//     <>
//       {/* Company Information */}
//       <div style={sectionHeaderStyle}>
//         <p>Company Information</p>
//         <Divider
//           className="horizontalLine"
//           sx={{ marginLeft: "60px", marginTop: "-2px" }}
//         />
//       </div>
//       <Grid container spacing={3}>
//         {[
//           {
//             id: "company_name",
//             label: "Company Name",
//             placeholder: "Enter Here",
//           },
//           {
//             id: "company_address",
//             label: "Address",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_zipcode",
//             label: "Zip Code",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_country",
//             label: "Country",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_email",
//             label: "Email Id",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_telephone",
//             label: "Telephone",
//             type: "number",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_phone",
//             label: "Phone Number",
//             type: "number",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "company_website",
//             label: "Website",
//             placeholder: "Enter Here",
//           },
//           {
//             id: "company_gst",
//             label: "GST Number",
//             placeholder: "Enter Here",
//           },
//           {
//             id: "company_logo",
//             label: "Company Logo",
//             placeholder: "Enter Here",
//           },
//         ].map(({ id, label, placeholder, note }) => (
//           <Grid item xs={12} sm={3} key={id}>
//             {id === "company_logo" ? (
//               <FormControl fullWidth>
//                 <div>
//                   <label
//                     style={{
//                       color: "black",
//                       fontSize: "13px",
//                       fontWeight: 500,
//                     }}
//                   >
//                     {label}
//                   </label>
//                   <input
//                     accept="image/*"
//                     id={id}
//                     name={id}
//                     type="file"
//                     style={{ display: "none" }}
//                     onChange={(event) => {
//                       const file = event.currentTarget.files[0];
//                       if (file) {
//                         setLogoFileName(file.name);
//                         formik.setFieldValue(id, file);
//                       }
//                     }}
//                   />
//                   <label htmlFor={id}>
//                     <Button
//                       variant="outlined"
//                       component="span"
//                       endIcon={<FileUploadOutlined sx={{ color: "black" }} />}
//                       sx={buttonStyle}
//                     >
//                       Upload Logo
//                     </Button>
//                   </label>
//                   {logoFileName && ( // Display the selected file name
//                     <span style={{ marginLeft: "10px", color: "black" }}>
//                       {logoFileName}
//                     </span>
//                   )}
//                 </div>
//               </FormControl>
//             ) : (
//               <FormField
//                 id={id}
//                 label={label}
//                 placeholder={placeholder}
//                 note={note}
//                 formik={formik}
//               />
//             )}
//           </Grid>
//         ))}
//       </Grid>

//       {/* Personal Information */}
//       <div style={sectionHeaderStyle}>
//         <p>Personal Information</p>
//         <Divider
//           className="horizontalLine"
//           sx={{ marginLeft: "60px", marginTop: "-2px" }}
//         />
//       </div>
//       <Grid container spacing={3}>
//         {[
//           {
//             id: "nickname",
//             label: "Nickname",
//             placeholder: "Enter Here",
//           },
//           {
//             id: "first_name",
//             label: "First Name",
//             placeholder: "Enter first name",
//           },
//           {
//             id: "last_name",
//             label: "Last Name",
//             placeholder: "Enter Here",
//           },
//           {
//             id: "dob",
//             label: "Date of Birth",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "gender",
//             label: "Gender",
//             placeholder: "Select",
//             options: [
//               { value: "", label: "Select Gender" },
//               { value: "male", label: "Male" },
//               { value: "female", label: "Female" },
//               { value: "other", label: "Other" },
//             ],
//           },
//         ].map(({ id, label, placeholder, note, options }) => (
//           <Grid item xs={12} sm={3} key={id}>
//             {id === "gender" ? (
//               <FormControl fullWidth>
//                 <InputLabel
//                   sx={{
//                     color: "black",
//                     fontSize: "13px",
//                     fontWeight: 500,
//                     marginBottom: "2px",
//                     transform: "translateY(-3px)",
//                   }}
//                 >
//                   {label}
//                 </InputLabel>
//                 <Select
//                   id={id}
//                   name={id}
//                   value={formik.values[id]}
//                   onChange={formik.handleChange}
//                   sx={{
//                     borderBottom: "1px solid grey",
//                     borderRadius: 0,
//                     padding: "10px 0 4px 0",
//                     "& .MuiSelect-select": {
//                       border: "none",
//                       padding: "4px 0",
//                       "&:focus": {
//                         border: "none",
//                         paddingBottom: "2px",
//                       },
//                     },
//                     "& fieldset": {
//                       border: "none",
//                     },
//                   }}
//                 >
//                   {options.map((option) => (
//                     <MenuItem key={option.value} value={option.value}>
//                       {option.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             ) : (
//               <FormField
//                 id={id}
//                 label={label}
//                 placeholder={placeholder}
//                 note={note}
//                 formik={formik}
//               />
//             )}
//           </Grid>
//         ))}
//       </Grid>

//       {/* Contact Information */}
//       <div
//         style={{
//           color: "#26252C",
//           fontFamily: "Work Sans",
//           fontSize: "16px",
//           fontStyle: "normal",
//           fontWeight: 500,
//           lineHeight: "22px",
//           marginTop: "50px",
//         }}
//       >
//         <p> Contact Information </p>
//         <Divider
//           className="horizontalLine"
//           style={{ marginLeft: "58px", marginTop: "-2px" }}
//         />
//       </div>
//       <Grid container spacing={3}>
//         {[
//           {
//             id: "email",
//             label: "Email Id",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "telephone",
//             label: "Telephone",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "phone",
//             label: "Phone",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//         ].map(({ id, label, placeholder, note }) => (
//           <Grid
//             item
//             xs={12}
//             sm={3}
//             key={id}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               mt: 2,
//             }}
//           >
//             <FormField
//               id={id}
//               label={label}
//               placeholder={placeholder}
//               note={note}
//               formik={formik}
//               disabled={id === "email"}
//             />
//           </Grid>
//         ))}
//       </Grid>

//       {/* Address Information */}
//       <div
//         style={{
//           color: "#26252C",
//           fontFamily: "Work Sans",
//           fontSize: "16px",
//           fontStyle: "normal",
//           fontWeight: 500,
//           lineHeight: "22px",
//           marginTop: "50px",
//         }}
//       >
//         <p> Address Information </p>
//         <Divider
//           className="horizontalLine"
//           style={{ marginLeft: "58px", marginTop: "-2px" }}
//         />
//       </div>
//       <Grid container spacing={3}>
//         {[
//           {
//             id: "address",
//             label: "Address",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "zipcode",
//             label: "Zip Code",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//           {
//             id: "country",
//             label: "Country",
//             placeholder: "Enter Here",
//             note: "(Only visible for you)",
//           },
//         ].map(({ id, label, placeholder, note }) => (
//           <Grid
//             item
//             xs={12}
//             sm={3}
//             key={id}
//             sx={{ display: "flex", alignItems: "center" }}
//           >
//             <FormField
//               id={id}
//               label={label}
//               placeholder={placeholder}
//               note={note}
//               formik={formik}
//             />
//           </Grid>
//         ))}
//       </Grid>
//     </>
//   );
// };

// export default CommercialPage;


const FormField = ({ id, label, placeholder, note, control, errors, disabled }) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel htmlFor={id} sx={inputLabelStyle}>
        {label} {note && <small>{note}</small>}
      </InputLabel>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            {...field}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      />
      {errors[id] && (
        <FormHelperText sx={{ color: "red" }}>{errors[id].message}</FormHelperText>
      )}
    </FormControl>
  );
};


const CommercialPage = ({ control, errors, setValue }) => {
  const [logoFileName, setLogoFileName] = useState("");

  return (
    <>
      {/* Company Information */}
      <div style={sectionHeaderStyle}>
        <p>Company Information</p>
        <Divider className="horizontalLine" sx={{ marginLeft: "60px", marginTop: "-2px" }} />
      </div>
      <Grid container spacing={3}>
        {[
          {
            id: "company_name",
            label: "Company Name",
            placeholder: "Enter Here",
          },
          {
            id: "company_address",
            label: "Address",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_zipcode",
            label: "Zip Code",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_country",
            label: "Country",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_email",
            label: "Email Id",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_telephone",
            label: "Telephone",
            type: "number",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_phone",
            label: "Phone Number",
            type: "number",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "company_website",
            label: "Website",
            placeholder: "Enter Here",
          },
          {
            id: "company_gst",
            label: "GST Number",
            placeholder: "Enter Here",
          },
          {
            id: "company_logo",
            label: "Company Logo",
            placeholder: "Enter Here",
          },
        ].map(({ id, label, placeholder, note }) => (
          <Grid item xs={12} sm={3} key={id}>
            {id === "company_logo" ? (
              <FormControl fullWidth>
                <div>
                  <label
                    style={{
                      color: "black",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {label}
                  </label>
                  <input
                    accept="image/*"
                    id={id}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (file) {
                        setLogoFileName(file.name);
                        setValue(id, file); // Use setValue from RHF
                      }
                    }}
                  />
                  <label htmlFor={id}>
                    <Button
                      variant="outlined"
                      component="span"
                      endIcon={<FileUploadOutlined sx={{ color: "black" }} />}
                      sx={buttonStyle}
                    >
                      Upload Logo
                    </Button>
                  </label>
                  {logoFileName && (
                    <span style={{ marginLeft: "10px", color: "black" }}>
                      {logoFileName}
                    </span>
                  )}
                </div>
              </FormControl>
            ) : (
              <FormField
                id={id}
                label={label}
                placeholder={placeholder}
                note={note}
                control={control}
                errors={errors}
              />
            )}
          </Grid>
        ))}
      </Grid>

      {/* Personal Information */}
      <div style={sectionHeaderStyle}>
        <p>Personal Information</p>
        <Divider className="horizontalLine" sx={{ marginLeft: "60px", marginTop: "-2px" }} />
      </div>
      <Grid container spacing={3}>
        {[
          {
            id: "nickname",
            label: "Nickname",
            placeholder: "Enter Here",
          },
          {
            id: "first_name",
            label: "First Name",
            placeholder: "Enter first name",
          },
          {
            id: "last_name",
            label: "Last Name",
            placeholder: "Enter Here",
          },
          {
            id: "dob",
            label: "Date of Birth",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "gender",
            label: "Gender",
            placeholder: "Select",
            options: [
              { value: "", label: "Select Gender" },
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "other", label: "Other" },
            ],
          },
        ].map(({ id, label, placeholder, note, options }) => (
          <Grid item xs={12} sm={3} key={id}>
            {id === "gender" ? (
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: 500,
                    marginBottom: "2px",
                    transform: "translateY(-3px)",
                  }}
                >
                  {label}
                </InputLabel>
                <Controller
                  name={id}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{
                        borderBottom: "1px solid grey",
                        borderRadius: 0,
                        padding: "10px 0 4px 0",
                        "& .MuiSelect-select": {
                          border: "none",
                          padding: "4px 0",
                          "&:focus": {
                            border: "none",
                            paddingBottom: "2px",
                          },
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <FormField
                id={id}
                label={label}
                placeholder={placeholder}
                note={note}
                control={control}
                errors={errors}
              />
            )}
          </Grid>
        ))}
      </Grid>

      {/* Contact Information */}
      <div
        style={{
          color: "#26252C",
          fontFamily: "Work Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "22px",
          marginTop: "50px",
        }}
      >
        <p>Contact Information</p>
        <Divider className="horizontalLine" style={{ marginLeft: "58px", marginTop: "-2px" }} />
      </div>
      <Grid container spacing={3}>
        {[
          {
            id: "email",
            label: "Email Id",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "telephone",
            label: "Telephone",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "phone",
            label: "Phone",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
        ].map(({ id, label, placeholder, note }) => (
          <Grid item xs={12} sm={3} key={id} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <FormField
              id={id}
              label={label}
              placeholder={placeholder}
              note={note}
              control={control}
              errors={errors}
              disabled={id === "email"}
            />
          </Grid>
        ))}
      </Grid>

      {/* Address Information */}
      <div
        style={{
          color: "#26252C",
          fontFamily: "Work Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "22px",
          marginTop: "50px",
        }}
      >
        <p>Address Information</p>
        <Divider className="horizontalLine" style={{ marginLeft: "58px", marginTop: "-2px" }} />
      </div>
      <Grid container spacing={3}>
        {[
          {
            id: "address",
            label: "Address",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "zipcode",
            label: "Zip Code",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
          {
            id: "country",
            label: "Country",
            placeholder: "Enter Here",
            note: "(Only visible for you)",
          },
        ].map(({ id, label, placeholder, note }) => (
          <Grid item xs={12} sm={3} key={id} sx={{ display: "flex", alignItems: "center" }}>
            <FormField
              id={id}
              label={label}
              placeholder={placeholder}
              note={note}
              control={control}
              errors={errors}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CommercialPage;
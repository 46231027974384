import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// css
import "./ProductCard.css";

// Images
import location from "../../assets/images/ProductImages/location.png";
import favourite from "../../assets/images/ProductDetails/favourite-add.svg";
import unfavourite from "../../assets/images/ProductDetails/favorite.svg";

// COmponents
import { Box, Button } from "@mui/material";
import { addFavourite } from "../../store/favourite/favouriteSlice";
import { useNavigateAction } from "../../customHooks/useNavigateAction";

const iconBtn = {
  width: "32px",
  height: "32px",
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
};

function ProductCard({ products }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleProtectedAction = useNavigateAction();
  const [productsData, setProductsData] = useState([]);
  const [isFavourite, setIsFavourite] = useState([]);
 
  // Navigate handler
  const navigateToProductDetailsHandler = (id, slug) => {
    navigate(`/product-details/${slug}`);
  };

  // const handleFavourite = (item, event) => {
  //   event.stopPropagation();
  //   handleProtectedAction(() => {
  //     let updated = [...isFavourite];
  //     if (updated?.includes(item?.id)) {
  //       updated = updated.filter((prodIds) => prodIds !== item?.id);
  //     } else {
  //       if(item?.favourite) {
  //       //  update in productsData
  //       }else{

  //         updated.push(item?.id);
  //       }
  //     }
  //     setIsFavourite(updated);
  //     dispatch(addFavourite(item?.id));
  //   });
  // };

  const handleFavourite = (item, event) => {
    event.stopPropagation();
    handleProtectedAction(() => {
      // Store the previous state for rollback
      const previousFavouriteState = [...isFavourite];

      // Update local state
      let updated = [...isFavourite];
      if (updated?.includes(item?.id)) {
        updated = updated.filter((prodIds) => prodIds !== item?.id);
      } else {
        if (item?.favourite) {
          // Update in productsData if the item was already marked as favourite
          const updatedProductsData = productsData.map((product) => {
            if (product.id === item.id) {
              return { ...product, favourite: false };
            }
            return product;
          });
          setProductsData(updatedProductsData);
        } else {
          updated.push(item?.id);
        }
      }

      // Update UI state immediately for responsive feel
      setIsFavourite(updated);

      // Dispatch action to update backend
      const resultAction = dispatch(addFavourite(item?.id));

      // Check if the action results in an error
      resultAction.then((action) => {
        // In Redux Toolkit, failed actions have an 'error' property
        if (!action.status) {
          // Rollback to previous state if API fails
          setIsFavourite(previousFavouriteState);

          // If we updated productsData, revert that too
          if (item?.favourite) {
            const revertedProductsData = productsData.map((product) => {
              if (product.id === item.id) {
                return { ...product, favourite: true };
              }
              return product;
            });
            setProductsData(revertedProductsData);
          }
        }
      });
    });
  };
  useEffect(() => {
    setProductsData(products);
  }, [products]);
  return (
    <>
      <div className="product-card-container">
        {productsData?.length > 0 &&
          productsData?.map((item, index) => (
            <Box
              key={item.id}
              className="product-card"
              onClick={() =>
                navigateToProductDetailsHandler(item.id, item.slug)
              }
            >
              <img
                style={{
                  objectFit: item?.templateType === 0 ? "fill" : "contain",
                }}
                // src={item.images[0]}
                src={
                  item.images && item.images.length > 0
                    ? item.images[0]
                    : "fallback-image-url"
                }
                alt={item.title}
              />
              {/* price and wishlist */}
              <div
                className="price-wishlist"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* price */}
                {item.templateType === 0 ? (
                  <div className="product-price">
                    <p className="productPrice-2">{item.price}</p>
                  </div>
                ) : (
                  <div className="product-price"></div> // Empty div to maintain space
                )}

                {/* favourite */}
                <Button
                  sx={iconBtn}
                  onClick={(event) => handleFavourite(item, event)}
                >
                  <div className="wishlist">
                    <img
                      src={
                        isFavourite.includes(item.id) || item.favourite
                          ? unfavourite
                          : favourite
                      }
                      alt={isFavourite ? "unfavourite icon" : "favourite icon"}
                    />
                  </div>
                </Button>
              </div>

              {/* Name */}
              <div className="product-name">
                <p>
                  {item.title}
                  {index == 0 &&
                    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos ducimus dolores molestias ipsam maiores sapiente veniam cum, nihil soluta excepturi non ipsum id nesciunt quia saepe dicta accusamus sit expedita?"}
                </p>
              </div>

              {/* state and pin */}
              <div className="state-pin">
                <img src={location} alt="location icon" />
                <p>{item?.location?.state}</p>
                <div className="big-dot" />
                <p>{item?.location?.pincode}</p>
                <div className="big-dot" />
                <div className="village-district">
                  <p style={{ marginBottom: "4px" }}>{item?.location?.city}</p>
                </div>
              </div>

              {/* Sponsored and days*/}
              <div className="sponsored">
                <div>
                  <p className="sponsored-text">Sponsored</p>
                </div>
                {item.sponsored && (
                  <p className="sponsored-days">{item.days}</p>
                )}
              </div>
            </Box>
          ))}
      </div>
    </>
  );
}

export default ProductCard;

import React, { useState } from 'react';
import { Box } from '@mui/material';

const ImageViewer = ({
  images,
  initialSelectedImage,
  altText = "Image"
}) => {
  const [selectedImage, setSelectedImage] = useState(
    initialSelectedImage || images[0]
  );

  return (
    <Box>
      {/* Selected Image */}
      <Box 
        sx={{
          width: '100%',
          height: '618px',
          overflow: 'hidden'
        }}
      >
        <img
          src={selectedImage}
          alt={altText}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      {/* Thumbnails */}
      <Box 
        sx={{ 
          display: 'flex', 
          marginTop: '12px', 
          gap: '8px',
          overflowX: 'auto'
        }}
      >
        {images?.length > 0 &&
          images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${altText} thumbnail ${index + 1}`}
              onClick={() => setSelectedImage(image)}
              style={{
                width: '144px',
                height: '94px',
                objectFit: 'cover',
                borderRadius: '4px',
                border: selectedImage === image 
                  ? '4px solid #00A6E8'
                  : '2px solid transparent',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out'
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ImageViewer;
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box,
  DialogContent,
} from "@mui/material";
import { BlockRounded, DeleteForever } from "@mui/icons-material";

const DeleteBlockDialogBox = ({ adId, dialogType, onClose, onContinue }) => {
  
  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "400px",
          maxWidth: "600px",
          backgroundColor: "#F7F7F8",
          borderRadius: 10,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        {(dialogType === "block" || dialogType === "unblock") ? (
          <BlockRounded sx={{ color: "#d10000", fontSize: 70, padding: 2 }} />
        ) : (
          <DeleteForever sx={{ color: "#d10000", fontSize: 70, padding: 2 }} />
        )}
      </Box>

      <DialogTitle>
        <Typography
          variant="h6"
          fontWeight={600}
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          {dialogType === "block"
            ? "Are You Sure You Want to Block?"
            : "Are You Sure You Want to Delete?"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="subtitle1"
          fontSize={13}
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          {dialogType === "block"
            ? "You can unblock anytime from the chat menu option."
            : "This action cannot be undone, and all your data will be lost."}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ backgroundColor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: 2,
          }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              textTransform: "none",
              padding: "6px 24px",
              marginRight: "20px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onContinue}
            variant="outlined"
            color="info"
            sx={{ textTransform: "none", padding: "6px 24px" }}
          >
            {dialogType === "block" ? "Yes, Block" : "Delete Chat"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBlockDialogBox;

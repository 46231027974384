import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box,
  DialogContent,
} from "@mui/material";
import { WarningRounded } from "@mui/icons-material";


const AdvertisementDialogBox = ({ adId, onClose, onContinue }) => {

  return (
    <>
      <Box mt={2}>
        <Dialog
          open
          onClose={onClose}
          PaperProps={{
            sx: {
              width: "400px",
              maxWidth: "600px",
              backgroundColor: "#F7F7F8",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <WarningRounded
              sx={{
                color: "#d10000",
                fontSize: 70,
                padding: 2,
              }}
            />
          </Box>
          <DialogTitle>
            <Typography
              variant="h6"
              fontWeight={600}
              display="flex"
              justifyContent="center"
              textAlign="center"
            >
              Exiting our Website
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              You are Leaving our site and we cannot be held responsible for the
              content of external website
            </Typography>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#fff" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: 2,
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  padding: "6px 24px",
                  marginRight: "20px",
                  color:"black"
                }}
              >
                Stay
              </Button>
              <Button
                onClick={onContinue}
                variant="contained"
                color="info"
                sx={{ textTransform: "none", padding: "6px 24px" }}
              >
                Continue to link
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AdvertisementDialogBox;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { searchProducts } from '../../store/product/productSlice';
import { useDebounce } from '../../customHooks/useDebounce';

const ProductSerachBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchResultRef = useRef(null);
  
  // Local state
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState({});

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  // Create debounced search function
  const performSearch = useCallback(
    (query) => {
      if (query && query.length > 0) {
        dispatch(searchProducts(query)).then((data)=>{
          setSearchedProducts(data);
        });
      }
    },
    [dispatch]
  );

  // Initialize debounced search with 500ms delay
  const debouncedSearch = useDebounce(performSearch, 500);

  const handleSearchFocus = () => {
    setIsSearchActive(true);
  };

  const handleProductDetail = (slug, id) => {
    navigate(`/product-details/${slug}`);
    setIsSearchActive(false);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultRef.current && !searchResultRef.current.contains(event.target)) {
        setIsSearchActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

 

  return (
    <>
      <TextField
        variant="outlined"
        placeholder="Search Products"
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded sx={{ color: 'action.active' }} />
            </InputAdornment>
          ),
        }}
      />

      {isSearchActive && searchQuery &&(
        <Box
          ref={searchResultRef}
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            borderRadius: '10px',
            zIndex: 1,
          }}
        >
          <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            {searchedProducts?.result?.length > 0 ? (
              searchedProducts?.result?.map((product) => (
                <Box
                  key={product.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: '1px solid #ddd',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleProductDetail(product.slug, product.id)}
                >
                  <Box sx={{ marginRight: 2 }}>
                    <img
                      src={product?.images[0]}
                      alt={product?.title}
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        marginLeft: '10px',
                      }}
                    />
                  </Box>
                  <Typography>{product?.title}</Typography>
                </Box>
              ))
            ) : (
              <Typography sx={{padding:2, textAlign:"center", fontSize:17}}>No products found</Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductSerachBar;
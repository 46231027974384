import React from "react";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

const menuItems = [
  { label: "Price", path: "/account", icon: <ChevronRight /> },
  { label: "Category", path: "/profile", icon: <ChevronRight /> },
  { label: "State", path: "/my-ads", icon: <ChevronRight /> },
  { label: "Listing", path: "/following", icon: <ChevronRight /> },
  { label: "Color", path: "/favourite", icon: <ChevronRight /> },
  { label: "Brand", path: "/help", icon: <ChevronRight /> },
  { label: "Price Range", path: "/help", icon: <ChevronRight /> },
  { label: "Milage", path: "/help", icon: <ChevronRight /> },
  { label: "Vehicle Type", path: "/help", icon: <ChevronRight /> },
  { label: "Fuel Type", path: "/help", icon: <ChevronRight /> },
  { label: "Condition", path: "/help", icon: <ChevronRight /> },
  { label: "Milage", path: "/help", icon: <ChevronRight /> },
  { label: "Vehicle Type", path: "/help", icon: <ChevronRight /> },
  { label: "Fuel Type", path: "/help", icon: <ChevronRight /> },
  { label: "Gear Type", path: "/help", icon: <ChevronRight /> },
];

const SidebarFilter = () => {
  const endpoint = window.location.pathname;
  const handleCloseProfile = () => {};

  return (
    <Paper
      sx={{
        direction: "column",
        marginLeft: "10px",
        marginTop: 10.4,
        // height: "auto",
        position: "relative",
        width: "98.5%",
      }}
    >
      {/* Filter as Heading */}
      <Box sx={{ padding: "10px 16px" }}>
        <Typography
          variant="h6"
          fontWeight={700}
          fontSize="24px"
        >
          Filter
        </Typography>
      </Box>
      <MenuList>
        {menuItems.map((item) => (
          <MenuItem key={item.path} sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <ListItem
              sx={{
                bgcolor: endpoint === item.path ? "#00A6E8" : "#fff",
                color: endpoint === item.path ? "#fff" : "#000",
                borderTop: "1px solid #D8D8DF",
              }}
              component={Link}
              to={item.path}
              onClick={handleCloseProfile}
            >
              <ListItemText primary={item.label} />
              <Box
                sx={{
                  color: "##26252C",
                }}
              >
                {item.icon}
              </Box>
            </ListItem>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default SidebarFilter;

import React from "react";
import { Box, List, Typography } from "@mui/material";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

// Components

function PrivacyPolicy() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
        <Header />
      <Box sx={{ dispay: "flex" }} mt={10.4}>
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "#fff",
            margin: "20px",
          }}
        >
          <Typography
            variant="h3"
            display="flex"
            justifyContent="center"
            textAlign="center"
            fontSize={40}
            fontWeight={600}
          >
            Datenschutzerklärung März 2024
          </Typography>
          <Typography
            variant="h5"
            fontSize={35}
            fontWeight={800}
            mt={2.5}
            display="flex"
            textAlign="center"
            justifyContent="center"
          >
            Introductory provisions
          </Typography>
          <ol>
            <Typography variant="h5" fontSize={25} fontWeight={600}>
              <li>Rechtsgrundlagen</li>
            </Typography>
            <Typography variant="body1" mt={1.7}>
              Die Datenverarbeitung von personenbezogenen Daten durch
              VerkaufAlles erfolgt unter Maßgabe der Bestimmungen der
              Datenschutzgrundverordnung (DSGVO), des Datenschutzgesetzes (DSG),
              des Telekommunikationsgesetzes (TKG), des E-Commerce-Gesetzes
              (ECG) und der sonst relevanten Bestimmungen. Gemäß Art 6 Abs 1
              DSGVO werden personenbezogene Daten zur Vertragserfüllung,
              vorvertragliche Maßnahmen gemäß lit b, auf Grund einer
              Einwilligung iSd lit a oder zur Erfüllung gesetzlicher Pflichten
              iSd lit c oder zur Erfüllung eines berechtigten Interesses iSd lit
              f verarbeitet.
            </Typography>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Verantwortlicher/ Auftragsverarbeiter</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  VerkaufAlles ist Verantwortlicher für die Verarbeitung der
                  personenbezogenen Daten.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Datenschutzbeauftragter ist: VerkaufAlles Internet Services
                  GmbH E-Mail office@verkaufalles.at
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Werden Daten im Auftrag von VerkaufAlles verarbeitet, erfolgt
                  dies unter Einhaltung eines Datenverarbeitervertrages.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Verarbeitete Daten</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Im Falle einer Registrierung werden jene personenbezogenen
                  Daten verarbeitet, die uns von Ihnen bei der Nutzung der App
                  und der Homepage, sei es durch die Registrierung, die
                  Veröffentlichung von Inseraten oder Suchanfragen oder denn
                  Besuch der Homepage und der App VerkaufAlles bekannt gegeben
                  werden. Personenbezogene Daten sind alle Informationen,
                  aufgrund deren eine Person direkt oder indirekt identifiziert
                  werden kann, z.B. Name, Firma, Wohn- und Geschäftsanschrift,
                  E-Mail-Adresse, Geburtsdatum, Firmenbuchnummer, Beruf,
                  Kontoverbindung etc.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Zwecke der Verarbeitung</li>
            </Typography>

            <Typography variant="body1" mt={1.7}>
              Die Datenverarbeitung erfolgt für Zwecke der Vertragserfüllung
              gemäß den AGBs, für die Schaltung gezielter Werbung, der
              Marktforschung sowie zur bedarfsgerechten Gestaltung unseres
              Angebots, wie zur Nutzerverwaltung , Bereitstellung,
              Fehlerbehebung und Verbesserung unserer Services und Sicherheit,
              Empfehlungen und Personalisierung von Funktionen, Produkten und
              Dienstleistungen, die Kommunikation mit den Kunden, die Einhaltung
              von verschiedenen gesetzlichen Bestimmungen (wie bspw. im
              Zusammenhang mit Rechnungslegung), zur Verifizierung bei
              behaupteten Rechten oder Pflichten, die Verwaltung,
              Aufrechterhaltung der Sicherheit, die Betrugsprävention sowie die
              laufende Verbesserung der Services unter{" "}
              <Link
                href="https://verkaufweb.semseosmo.com/"
                target="_blank"
                rel="noopener"
              >
                https://verkaufweb.semseosmo.com/
              </Link>{" "}
              in den mobilen Anwendungen.
            </Typography>

            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Grenzüberschreitende Datentransfers</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Sollten unsere Vertragspartner außerhalb des
                  Anwendungsbereichs der DSGVO Daten verarbeiten, wird
                  VerkaufAlles für entsprechende Garantien durch den Abschluss
                  von Datenschutzverträgen, die von der Europäischen Kommission
                  erlassen wurden, zB Standarddatenschutzklauseln (2021/914/EU)
                  mit den Empfängern.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Speicherdauer und Löschung</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Personenbezogene Daten werden nur so lange gespeichert wie es
                  für die genannten Verarbeitungszwecke erforderlich ist.
                  Folglich werden die Daten gelöscht oder anonymisiert
                  entsprechend den VerkaufAlles- Richtlinien für die
                  Aufbewahrung von Daten, soferne es keine gesetzlichen Vorgaben
                  gibt, die eine Abweichung notwendig machen, wie
                  steuerrechtliche Aufbewahrungspflichten, welche 7 Jahre
                  umfassen. Ist für eine Rechtsverfolgung oder
                  Rechtsverteidigung eine längere Aufbewahrung notwendig, können
                  personenbezogene Daten iSd der Verjährungs- und
                  Aufbewahrungsfristen auch länger aufbewahrt werden.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Rechte der Betroffenen</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Eine betroffene Person hat das Recht auf Auskunft über die
                  über sie gespeicherten Daten.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Es kann die Richtigstellung von Daten verlangt werden.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Eine betroffene Person hat das Recht auf unverzügliche
                  Löschung der personenbezogenen Daten, sofern keine
                  gesetzlichen Gründe dagegen sprechen.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Weiters kann die Einschränkung der Verarbeitung verlangt
                  werden, wenn kein gesetzlicher Grund dagegen spricht.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Betroffene Personen haben das Recht, die Bereitstellung ihrer
                  personenbezogenen Daten in einem strukturierten, gängigen,
                  maschinenlesbaren Format zu erhalten und dass diese Daten an
                  einen anderen Verantwortlichen übermittelt werden.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Der Betroffene kann die Einwilligung zur Datenverarbeitung
                  jederzeit widerrufen. Sofern keine andere gesetzliche
                  Verpflichtung besteht zur Verarbeitung der personenbezogenen
                  Daten, dürfen Daten von VerkaufAlles in diesem Fall nicht mehr
                  verarbeitet werden.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Diese Rechte können ausgeübt werden durch Übermittlung eines
                  E-Mails an: office@verkaufalles.at. Zur Geltendmachung seiner
                  Rechte ist eine Identifikation erforderlich.
                </Typography>
              </li>
              <Typography variant="body1" mt={1.7}>
                Erfolgt die Verarbeitung der personenbezogenen Daten nicht
                rechtmäßig, kann Beschwerde an die Datenschutzbehörde unter{" "}
                <Link
                  href="https://www.dsb.gv.at"
                  target="_blank"
                  rel="noopener"
                >
                  https://www.dsb.gv.at
                </Link>{" "}
                eingebracht werden.
              </Typography>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Altersbegrenzung</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Die Nutzung der App und Homepage ist auf Personen, die das 18
                  Lebensjahr vollendet haben beschränkt. Es werden keine Daten
                  verarbeitet von Personen, die unter 18 sind. Daten von
                  Personen, die unter 18 sind werden unverzüglich gelöscht.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Datensicherheit</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  VerkaufAlles ergreift technische und organisatorische
                  Maßnahmen zum Schutz Ihrer personenbezogenen Daten, um Risiken
                  wie Datenverlust, Missbrauch, unberechtigten Zugriff,
                  Weitergabe und Modifikation zu minimieren. Dazu gehören
                  Netzwerksicherheitsdienste, Datenverschlüsselung, physische
                  Zugangsbeschränkungen für Rechenzentren und logische
                  Zugriffskontrollen für den Daten- und Systemzugriff.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              <li>Cookies</li>
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Cookies dienen dem Zweck, die korrekte Funktionsweise einer
                  Website zu ermöglichen, den Funktionsumfang einer Website zu
                  erweitern und eine Optimierung der Website-Funktionen zu
                  realisieren. Darüber hinaus können Cookies auch zur Erhebung
                  von statistischen Daten eingesetzt werden. Für den Betrieb
                  unserer Website verwenden wir betriebsnotwendige Cookies, ohne
                  welche die Bereitstellung unserer Website nicht möglich wäre.
                  Funktionale Cookies und Webanalyse-Cookies, welche uns
                  erlauben, unsere Website zu verbessern, werden von uns nur
                  dann eingesetzt, wenn Sie uns Ihre Einwilligung zu deren
                  Verwendung erteilen. Im Fall Ihrer Einwilligung zur Verwendung
                  von Webanalyse-Cookies werden Daten darüber, wie die Website
                  von Ihnen verwendet wird, an Dritte weitergegeben.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Nachfolgend finden Sie eine detaillierte Beschreibung der von
                  uns verwendeten Arten von Cookies und wie Sie uns Ihre
                  Einwilligung zur Verwendung von funktionalen Cookies und/oder
                  Webanalyse-Cookies geben können, aber auch darüber, wie Sie
                  diese gegebenenfalls widerrufen können.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              Welche Daten werden erhoben, gespeichert und verarbeitet?
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Wenn Sie auf unsere Website zugreifen, werden auf Ihrem
                  Endgerät technische Informationen in Form eines sogenannten
                  „Cookies“ abgelegt. Cookies sind kleine Textdateien, die eine
                  Website auf dem Rechner Ihres Endgerätes hinterlässt, wenn Sie
                  sie besuchen. Bei Ihrem erstmaligen Besuch werden
                  ausschließlich betriebsnotwendige Cookies auf Ihrem Endgerät
                  gespeichert. Sie haben in weiterer Folge über unser Cookies
                  Tool, welches automatisch beim erstmaligen Aufruf unserer
                  Website erscheint, die Möglichkeit, in die Verwendung von
                  funktionalen Cookies und/oder Webanalyse-Cookies einzuwilligen
                  und Ihre Auswahl zu speichern. Ebenso besteht die Option
                  unsere Website ohne Einwilligung in die Verwendung dieser
                  Cookies zu nutzen, beachten Sie hierbei aber, dass der
                  Funktionsumfang in diesem Fall nur eingeschränkt gegeben ist.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              Betriebsnotwendige Cookies:
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Auf unserer Webseite setzen wir Cookies ein, die für den
                  Betrieb der Website, ihre Funktionen und Tools
                  betriebsnotwendig sind. Hierzu gehören Technologien, die Ihnen
                  den Zugriff auf die Website erlauben, die erforderlich sind,
                  um die Funktionalität der Seite zu prüfen, Betrug zu
                  verhindern und die Sicherheit zu verbessern. Diese Cookies
                  sind unerlässlich, damit Sie die Dienste der Website nutzen
                  können. Ohne diese Cookies würden die Dienste nicht korrekt
                  funktionieren. Diese Cookies werden allerdings auch nur
                  solange gespeichert, als für diesen Dienst unbedingt
                  erforderlich, also in der Regel bis zum Ende der
                  Internetsitzung und bis Sie Ihren Browser vollständig
                  schließen (Session-Cookies).
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              Funktionale Cookies:
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Funktionale Cookies ermöglichen es einer Website, bereits
                  getätigte Angaben (wie z.B. Sprachauswahl oder der Ort, an dem
                  Sie sich befinden) zu speichern und dem Nutzer verbesserte,
                  persönlichere Funktionen anzubieten. Zum Beispiel könnte eine
                  Website eine lokale preisrelevante Information anbieten, wenn
                  die Website ein Cookie benutzt, um die Region zu speichern, in
                  der sich der Nutzer gerade befindet. Funktionale Cookies
                  werden außerdem dazu benutzt, angeforderte Funktionen zu
                  ermöglichen. Diese Cookies sammeln anonymisierte
                  Informationen, sie können nicht Ihre Bewegungen auf anderen
                  Websites verfolgen.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Das von uns eingesetzte Cookie ermöglicht die Erinnerung an
                  ihre Einstellungen, wenn Sie innerhalb derselben Sitzung zu
                  einer Seite zurückkehren oder unsere Website zu einem späteren
                  Zeitpunkt erneut aufrufen. Die durch das Cookie gesammelten
                  Informationen beziehen sich nur auf unsere Website, es werden
                  jedoch keine Informationen über das Surfen auf anderen
                  Websites gesammelt. Grundsätzlich wird dieser Cookie für einen
                  Zeitraum von einem Jahr auf Ihrem Endgerät gespeichert.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Außerdem verwenden wir ein permanentes Cookie, das Ihre
                  Einwilligung zu der Verwendung und Platzierung von Cookies
                  erfasst und speichert. Stimmen Sie der Verwendung von
                  zumindest den funktionalen Cookies zu, so wird das permanente
                  Cookie auf ihrem Endgerät für die Dauer von einem Jahr
                  gespeichert. Dieser „First-Party-Cookie“ erfasst keine
                  Informationen über das Surfen auf anderen Websites und leitet
                  keine Daten an Dritte weiter. Es dient dem Zweck, dass die
                  Einwilligung zur Nutzung von Cookies nur ein einziges Mal je
                  Browserverwendung bei Aufrufen unserer Website erteilt werden
                  muss und das Cookies Tool somit nicht bei jedem Aufruf unserer
                  Website erscheint.
                </Typography>
              </li>
            </ol>
            <Typography variant="h5" fontSize={25} fontWeight={600} mt={2.5}>
              Deaktivieren von Cookies
            </Typography>
            <ol
              style={{
                listStyle: "disc",
                marginTop: "5px",
              }}
            >
              <li>
                <Typography variant="body1" mt={1.7}>
                  Sie können Cookies nach Belieben steuern und/oder löschen.
                  Dazu können Sie unser Cookies Tool verwenden, welches Ihnen
                  erlaubt, Ihre Einwilligung zur Verwendung von funktionalen
                  und/oder Webanalyse-Cookies jederzeit zu widerrufen. Dazu
                  müssen Sie lediglich die auf unserer Website im linken unteren
                  Bereich mit einem Zahnrad versehenden Schaltfläche betätigen,
                  wodurch sich das Cookies Tool erneut öffnet und Änderungen
                  vorgenommen werden können.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Darüber hinaus besteht die Möglichkeit über Ihren Browser
                  Cookies zu löschen. Detaillierte Informationen dazu finden Sie
                  jeweils auf der Website des von Ihnen verwendeten Browsers (zB
                  Mozilla Firefox, Internet Explorer, Chrome, Safari).
                </Typography>
              </li>
              <li>
                <Typography variant="body1" mt={1.7}>
                  Sie können alle auf Ihrem Rechner abgelegten Cookies löschen
                  und die meisten Browser so einstellen, dass die Ablage von
                  Cookies verhindert wird. Dann müssen Sie aber möglicherweise
                  einige Einstellungen bei jedem Besuch der Website manuell
                  vornehmen und die Beeinträchtigung mancher Funktionen in Kauf
                  nehmen.
                </Typography>
              </li>
            </ol>
          </ol>
        </Box>
      </Box>
    </>
  );
}

export default PrivacyPolicy;

import React, { useCallback, useEffect, useState } from "react";
import { ArrowBack, SearchRounded } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import gpsicon from "../../assets/images/HeaderIcon/gps_icon.svg";
import greylocationicon from "../../assets/images/HeaderIcon/greylocation_icon.svg";
import { useDispatch } from "react-redux";
import {
  getCityByStateId,
  getLocationBySearchQuery,
  getStates,
} from "../../store/profile/profileSlice";
import useAppSelectors from "../../customHooks/useAppSelector";
import { useDebounce } from "../../customHooks/useDebounce";
import { FixedSizeList } from "react-window";

// const LocationPopUp = ({ open, onClose }) => {
//   const [selectedState, setSelectedState] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const dispatch = useDispatch();
//   const { statesData } = useAppSelectors();

//   useEffect(() => {
//     dispatch(getStates());
//   }, []);
//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
//       {/* <DialogTitle>Select Value</DialogTitle> */}
//       <DialogContent dividers={false}>
//         <Box>
//           <TextField
//             // label="Search Products"
//             variant="outlined"
//             placeholder="Search Products"
//             // value={searchQuery}
//             // onChange={handleSearchChange}
//             // onFocus={handleSearchFocus}
//             fullWidth
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchRounded sx={{ color: "action.active" }} />
//                 </InputAdornment>
//               ),
//             }}
//           />
//         </Box>
//         <Box
//           display={"flex"}
//           alignItems={"center"}
//           mt={2}
//           mb={2}
//           gap={"0.8rem"}
//         >
//           <img src={gpsicon} alt="currentlocation" />
//           <Box>
//             <Typography color="#00A6E8" variant="h6" fontSize={"1rem"}>
//               Use current location
//             </Typography>
//             <Typography color="#26252C" variant="caption">
//               Enable location
//             </Typography>
//           </Box>
//         </Box>

//         <Divider />

//         <Box mt={2}>
//           <Box mb={2}>
//             <Typography color="#707187" variant="caption" fontSize={"0.75rem"}>
//               Choose by states
//             </Typography>
//           </Box>
//           {statesData?.map((stateData) => (
//             <Box key={stateData?.id} display={"flex"} alignItems={"center"} mb={2} gap={"0.8rem"}>
//               <img src={greylocationicon} alt="icon" />
//               <Box>
//                 <Typography
//                   color="#26252C"
//                   variant="subtitle1"
//                   fontWeight={500}
//                 >
//                   {stateData?.name}
//                 </Typography>
//               </Box>
//             </Box>
//           ))}
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default LocationPopUp;

const LocationPopUp = ({ open, onClose }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLocating, setIsLocating] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const dispatch = useDispatch();
  const { statesData, citiesData } = useAppSelectors();

  // Initial fetch of states data
  useEffect(() => {
    dispatch(getStates());
  }, [dispatch]);

  // Handler for search query changes
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSelectedState(null);
    setSearchQuery(query);
    debouncedSearch(query);
  };

  // Create debounced search function
  const performSearch = useCallback(
    (query) => {
      if (query && query.length > 0) {
        dispatch(getLocationBySearchQuery(query));
      }
    },
    [dispatch]
  );

  // Initialize debounced search with 500ms delay
  const debouncedSearch = useDebounce(performSearch, 500);

  // Handle selection of a state
  const handleStateSelect = (location) => {
    if (searchQuery.trim().length === 0) {
      if(selectedState){
        localStorage.setItem("location", JSON.stringify({...location,state:selectedState?.name}));
        onClose();
      }else{
        dispatch(getCityByStateId(location.id));
        setSelectedState(location);
      }
    }else{
      localStorage.setItem("location", JSON.stringify(location));
      onClose();
    }

    // onClose();
  };



  const getCoordinates = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser"));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      }
    });
  };

  const getZipCode = async (coords) => {
    try {
      const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${API_KEY}`
      );
      const data = await response.json();
      const address = data.results[0].address_components;
      const zipComponent = address.find(component => 
        component.types.includes('postal_code')
      );
      return zipComponent?.long_name || null;
    } catch (error) {
      throw new Error("Unable to retrieve location information");
    }
  };

  // Current location handler
  const handleGetCurrentLocation = async () => {
    try {
      setIsLocating(true);
      setLocationError(null);
      setSelectedState(null);
      
      const coords = await getCoordinates();

      const zipCode = await getZipCode(coords);
      
      if (!zipCode) throw new Error("No zip code found for this location");
      
      setSearchQuery(zipCode);
      dispatch(getLocationBySearchQuery(zipCode));
    } catch (error) {
      setLocationError(error.message);
    } finally {
      setIsLocating(false);
    }
  };

  // which data to display
  const displayData =
    searchQuery.length > 0 || selectedState ? citiesData : statesData;

  const Row = ({ index, style, data }) => {
    const item = data[index];
    return (
      <div style={style}>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          gap="0.8rem"
          onClick={() => handleStateSelect(item)}
          sx={{ cursor: "pointer" }}
        >
          <img src={greylocationicon} alt="icon" />
          <Box>
            <Typography color="#26252C" variant="subtitle1" fontWeight={500}>
              {item?.name}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      {selectedState && (
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={2}>
            <ArrowBack
              onClick={() => setSelectedState(null)}
              sx={{ color: "#26252C", cursor: "pointer" }}
            />
            <Typography color="#26252C" variant="h6" fontWeight={600}>
              {selectedState?.name}
            </Typography>
          </Box>
        </DialogTitle>
      )}

      <DialogContent dividers={selectedState ? true : false}>
        <Box>
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded sx={{ color: "action.active" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          mt={2}
          mb={2}
          gap={"0.8rem"}
        >
          <img src={gpsicon} alt="currentlocation" />
          <Box sx={{cursor:"pointer"}}>
            <Typography onClick={handleGetCurrentLocation} color="#00A6E8" variant="h6" fontSize={"1rem"}>
              Use current location
            </Typography>
          <Typography color="#26252C" variant="caption">
            {isLocating ? "Locating..." : (locationError || "Enable location")}
          </Typography>
          </Box>
        </Box>
        <Divider />
        <Box mt={2}>
          <Box mb={2}>
            <Typography color="#707187" variant="caption" fontSize={"0.75rem"}>
              {selectedState
                ? `All in ${selectedState.name}`
                : searchQuery.length > 0
                ? "Search results"
                : "Choose by states"}
            </Typography>
          </Box>
          {displayData?.length === 0 ? (
            <Typography>No results found</Typography>
          ) : (
            <FixedSizeList
              height={250}
              width="100%"
              itemSize={60}
              itemCount={displayData?.length || 0}
              itemData={displayData}
            >
              {Row}
            </FixedSizeList>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationPopUp;

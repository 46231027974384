import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../store/auth/authSlice";
import { encryptPassword } from "../../../utils/encryption";
import { toast } from "react-toastify";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import Google from "../../../assets/images/LoginImages/google.svg";
import Email from "../../../assets/images/LoginImages/mail.svg";
import Password from "../../../assets/images/LoginImages/password.svg";
import "./login.css";
import { FireBaseLogIn } from "../../../utils/firebasehelper";
import { useFireBaseChatHelper } from "../../../customHooks/useFireBaseChatHelper";
import { fetchAndStoreToken } from "../../../config/firebase.config";
import useSocialLogin from "../../../customHooks/useSocialLogin";

const schema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup.string().required("This field is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUserOnlineStatus } = useFireBaseChatHelper();
  const {handleSocialAuth} = useSocialLogin();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: ""
    }
  });

  const onSubmit = async (values) => {
    const encryptedPassword = encryptPassword.encrypt(values.password);
    const reqBody = { email: values.email, password: encryptedPassword };

    const res = await dispatch(loginUser(reqBody));
    if (res?.data?.status) {
      FireBaseLogIn(values.email, values.password).then(() => {
        setUserOnlineStatus(res?.data?.data?.id);
        fetchAndStoreToken(res?.data?.data?.id);
      });
      toast.success("Login successful!");
      const redirectPath = location.state?.from || "/";
      navigate(redirectPath, { replace: true });
    } else {
      toast.error(res?.data?.message || "Login failed");
    }
    reset();
  };

  return (
    <Box
      sx={{
        border: "0.5px solid #D8D8DF",
        width: 936,
        minHeight: 452,
        bgcolor: "#FFF",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "48px" }}>
        <h1 className="headline">Welcome to VerkaufAllen!</h1>
        <p className="subtitle">
          Please enter your credentials to access your account.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <Grid container spacing={3}>
            {["email", "password"].map((field) => (
              <Grid item xs={12} sm={3} key={field}>
                <FormControl variant="standard" error={Boolean(errors[field])}>
                  <InputLabel htmlFor={field} sx={{ marginLeft: "30px" }}>
                    {field === "email" ? "Email Id" : "Password"}
                  </InputLabel>
                  <Input
                    id={field}
                    type={
                      field === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : "email"
                    }
                    {...register(field)}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={field === "email" ? Email : Password}
                          alt={field}
                          className="icon"
                        />
                      </InputAdornment>
                    }
                    endAdornment={
                      field === "password" && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                  {errors[field] && (
                    <FormHelperText>{errors[field].message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3} marginTop={-2}>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => navigate("/forgot-password")}
                sx={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "#00A6E8",
                }}
              >
                Forgot Password?
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} marginTop={3}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "300px",
                  textTransform: "none",
                  backgroundColor: isValid && isDirty ? "#00A6E8" : "gray",
                  color: "white",
                }}
                disabled={!(isValid && isDirty)}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className="bottom">
          <div className="otherOption">
            <p>Or continue with</p>
          </div>
          <Divider className="horizontalLine" />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            marginLeft={2}
          >
            {/* {[
              { icon: <GoogleIcon />, label: "Continue With Google",type:"google" },
              { icon: <FacebookOutlinedIcon />, label: "Continue With Facebook",type:"facebook" },
              { icon: <AppleIcon />, label: "Continue With Apple",type:"apple" },
            ].map(({ icon, label, type }) => (
              <Button
                onClick={()=>handleSocialAuth(type)}
                key={label}
                startIcon={icon}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                }}
              >
                {label}
              </Button>
            ))} */}
            <Button
              onClick={() => handleSocialAuth("google")}
              startIcon={<img src={Google} alt="Google" width="27" />}
              sx={{
                backgroundColor: "#eeeeee",
                color: "black",
                textTransform: "none",
                fontWeight: 600,
                font: "Work Sans",
                fontSize: "16px",
              }}
            >
              Continue With Google
            </Button>
          </Stack>
        </div>
      </Box>
    </Box>
  );
};

export default Login;
import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import ForgotPassword from "../../pages/public/ForgotPassword/ForgotPassword";
import TabComponent from "../../components/Tabs/Tab";
import VerificationPage from "../../pages/public/VerificationPage/VerificationPage";
import NewPassword from "../../pages/public/NewPassword/NewPassword";
import SuccessPage from "../../pages/public/SuccessPage/SuccessPage";
import Home from "../../pages/private/HomePage/Home";
import PostNewAdd from "../../pages/private/PostNewAdd/PostNewAdd";
import ProductDetails from "../../pages/private/ProductDetails/ProductDetails";
import Account from "../../pages/private/Account/Account";
import Profile from "../../pages/private/Profile/Profile";
import Following from "../../pages/private/Following/Following";
import MyAds from "../../pages/private/MyAds/MyAds";
import Favourite from "../../pages/private/Favourite/Favourite";
import Help from "../../pages/private/Help/Help";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import ContactInfo from "../../pages/private/ContactInfo/ContactInfo";
import PreviewPage from "../../pages/private/PreviewPage/PreviewPage";
import CommercialPage from "../../pages/private/Profile/CommercialPage";
import SellerProfile from "../../pages/private/SellerProfile/SellerProfile";
import ChatRoom from "../../pages/private/ChatRoom/ChatRoom";
import SuggestionsComplaint from "../../pages/private/Suggestion&Complaints/SuggestionsComplaint";
import PrivateRoute from "../privateRoute/PrivateRoute";
import TermsConditions from "../../components/TermsConditions/TermsConditions";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "../../components/AboutUs/AboutUs";
import CategoryAdsList from "../../components/CategoryAdsList/CategoryAdsList";
import SocialAuth from "../../pages/public/SocialAuth/SocialAuth";

const AppRoute = () => {
  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="/auth" element={<TabComponent />} />
      <Route path="/social-auth" element={<SocialAuth />} />

      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/forgot-password/verification"
        element={<VerificationPage />}
      />
      <Route path="/email-verification" element={<VerificationPage />} />
      <Route
        path="/forgot-password/verification/newpassword"
        element={<NewPassword />}
      />
      <Route
        path="/forgot-password/verification/newpassword/passwordchange"
        element={<SuccessPage />}
      />

      {/* App Routes */}

      <Route path="/" element={<Home />} />
      <Route
        path="/account"
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-ads"
        element={
          <PrivateRoute>
            <MyAds />
          </PrivateRoute>
        }
      />
      <Route
        path="/following"
        element={
          <PrivateRoute>
            <Following />
          </PrivateRoute>
        }
      />
      <Route
        path="/favourite"
        element={
          <PrivateRoute>
            <Favourite />
          </PrivateRoute>
        }
      />
      <Route
        path="/help"
        element={
          <PrivateRoute>
            <Help />
          </PrivateRoute>
        }
      />

      <Route
        path="/suggestions-complaints"
        element={<SuggestionsComplaint />}
      />
      <Route path="/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/privacy-and-policies" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/profile/commercial" element={<CommercialPage />} />
      <Route path="/ads-page" element={<CategoryAdsList />} />
      <Route path="/favourite" element={<Favourite />} />
      <Route path="/product-details/:slug" element={<ProductDetails />} />
      <Route path="/seller-profile/:id" element={<SellerProfile />} />
      <Route path="/chat" element={<ChatRoom />} />
      <Route path="/post" element={<PostNewAdd />} />
      <Route path="/edit-post/:id" element={<PostNewAdd />} />
      <Route path="/category/:slug" element={<CategoryCard />} />
      <Route path="/post/contact-information" element={<ContactInfo />} />
      <Route path="/edit-post/contact-information" element={<ContactInfo />} />
      <Route path="/post/preview" element={<PreviewPage />} />
      <Route path="/edit-post/preview" element={<PreviewPage />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoute;

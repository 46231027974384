import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import location from "../../../assets/images/ChatRoom/locationChat.svg";
import useAppSelectors from "../../../customHooks/useAppSelector";
import {
  AttachFile,
  Block,
  DeleteOutlineOutlined,
  MoreVert,
  Send,
} from "@mui/icons-material";
import SelectedAttachment from "./SelectedAttachment";
import { useFireBaseChatHelper } from "../../../customHooks/useFireBaseChatHelper";
import DeleteBlockDialogBox from "./DeleteBlockDialogBox";

const dotStyle = {
  alignSelf: "center",
  width: "4px",
  height: "4px",
  borderRadius: "2px",
  backgroundColor: "#707187",
};

const state_zip_dist = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const divider = {
  marginTop: "15px",
};

const chatStyles = {
  chatContainer: {
    height: "calc(100vh - 300px)",
    display: "flex",
    flexDirection: "column",
  },
  messagesArea: {
    flexGrow: 1,
    height: "60vh",
    overflowY: "auto",
    marginBottom: 2,
    padding: "10px",
    scrollBehavior: "smooth",
  },
  messageWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "16px",
  },
  senderWrapper: {
    justifyContent: "flex-end",
    width: "auto",
  },
  message: {
    maxWidth: "70%",
    padding: "10px 15px",
  },
  receivedMessage: {
    backgroundColor: "#f0f0f0",
    borderRadius: "1.125rem 1.125rem 1.125rem 0",
  },
  sentMessage: {
    backgroundColor: "#2196F3",
    color: "white",
    borderRadius: "1.125rem 1.125rem 0 1.125rem ",
  },
  timestamp: {
    fontSize: "0.75rem",
    marginTop: "4px",
    opacity: 0.7,
    textAlign: "right",
  },
  inputArea: {
    display: "flex",
    gap: 2,
    padding: "10px",
    borderTop: "1px solid #D8D8DF",
  },
};

const getOptions = (isBlocked) => [
  { label: "Delete", icon: <DeleteOutlineOutlined />, type: "delete" },
  { 
    label: isBlocked ? "UnBlock" : "Block", 
    icon: <Block />, 
    // type: isBlocked ? "unblock" : "block", 
    type:"block"
  },
];


function ChatMessage({
  messages,
  setLoading,
  selectedChatRoom,
  setSelectedChatRoom,
  didOpponentBlocked,
  didIBlocked,
  blockedMsg,
}) {
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [sendMessageContent, setSendMessageContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [menuOpenId, setMenuOpenId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const { currentUserData } = useAppSelectors();
  const {
    getFirebaseCurrentUserIdUUid,
    getOtherParticipant,
    listenToUserStatus,
    listenToTypingStatus,
    setTypingStatus,
    sendMessage,
    deleteAllPreviousMessages,
    blockUnblockUser,
  } = useFireBaseChatHelper();
  let previousDate = null;

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) {
      // if timestamp is null or invalid
      const currentDate = new Date();
      return currentDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    if (selectedChatRoom?.ownerId) {
      const userId =
        selectedChatRoom?.customerId === currentUserData?.id
          ? selectedChatRoom?.ownerId.toString()
          : selectedChatRoom?.customerId.toString();

      const unsubscribe = listenToUserStatus(userId, setIsOnline);
      return () => {
        unsubscribe();
      };
    }
  }, [selectedChatRoom, currentUserData?.id]);

  useEffect(() => {
    if (selectedChatRoom?.id) {
      const userId =
        selectedChatRoom?.customerId === currentUserData?.id
          ? selectedChatRoom?.ownerId.toString()
          : selectedChatRoom?.customerId.toString();
      const unsubscribe = listenToTypingStatus(
        selectedChatRoom.id,
        userId,
        setIsTyping
      );

      return () => {
        unsubscribe();
      };
    }
  }, [selectedChatRoom]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveAttachment = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuOpenId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpenId(null);
  };

  const handleMenuItemClick = (type) => {
    setDialogType(type);
    setOpenDialog(true);
    handleClose();
  };

  const handleContinue = () => {
    if (dialogType === "delete") {
      deleteAllPreviousMessages(selectedChatRoom?.id, currentUserData?.id);
    } else if (dialogType === "block" || dialogType === "unblock") {
      
      const blockedUserId =
        selectedChatRoom?.customerId === currentUserData?.id
          ? selectedChatRoom?.ownerId
          : selectedChatRoom?.customerId;
      const userId = currentUserData?.id.toString();

      blockUnblockUser(blockedUserId, userId);
    }
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  // Format date
  const formatDate = (date) => {
    if (!date) return "Today";

    const today = new Date();
    if (isSameDay(date, today)) {
      return "Today";
    } else if (isSameDay(date, new Date(today.setDate(today.getDate() - 1)))) {
      return "Yesterday";
    } else {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const handleScrollDown = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };
  const handleInputChange = (e) => {
    const text = e.target.value;
    setSendMessageContent(text);

    if (selectedChatRoom?.id) {
      setTypingStatus(
        selectedChatRoom.id,
        currentUserData?.id,
        text.trim() !== ""
      );
    }

    debounce(() => {
      if (selectedChatRoom?.id && text.trim() === "") {
        const userId =
          selectedChatRoom?.customerId === currentUserData?.id
            ? selectedChatRoom?.ownerId.toString()
            : selectedChatRoom?.customerId.toString();
        setTypingStatus(selectedChatRoom.id, userId, false);
      }
    }, 1000)();
  };

  const handleSendMessage = async () => {
    if (
      (sendMessageContent.trim() === "" && selectedFiles.length === 0) ||
      !selectedChatRoom?.id
    ) {
      return;
    }

    const reciverId = getOtherParticipant(
      currentUserData?.id,
      selectedChatRoom?.participants
    );
    setSendMessageContent("");
    await sendMessage(
      selectedChatRoom?.id,
      sendMessageContent,
      currentUserData?.id,
      reciverId,
      selectedFiles,
      handleUpdateProgress
    );

    setTypingStatus(selectedChatRoom.id, currentUserData?.id, false);
    setSendMessageContent("");
    setSelectedFiles([]);
    setUploadProgress({});
  };

  const handleUpdateProgress = (fileName, progress) => {
    setUploadProgress((prev) => ({
      ...prev,
      [fileName]: progress,
    }));
  };

  useEffect(() => {
    handleScrollDown();
  }, [messages]);

  return (
    <>
      <Grid item xs={9}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              fontFamily="Work Sans"
              fontSize="16px"
              fontWeight="600"
              lineHeight="22px"
              ml={1}
            >
              {selectedChatRoom?.customerId === currentUserData?.id
                ? selectedChatRoom?.providerName
                : selectedChatRoom?.customerName}
            </Typography>
            <Typography
              sx={{
                color: "#707187",
                fontSize: "12px",
                display: "block",
              }}
              mt={"0.2rem"}
            >
              {isTyping && "typing..."}
            </Typography>
            {selectedChatRoom?.id && (
              <Chip
                label={isOnline ? "Online" : "Offline"}
                variant="contained"
                sx={{
                  backgroundColor: isOnline ? "#4CAF50" : "#FF5252",
                  color: "#fff",
                  height: "20px",
                  marginLeft: "5px",
                }}
                size="small"
              />
            )}
          </Box>

          {selectedChatRoom?.id && (
            <IconButton
              aria-label="more options"
              // aria-controls={menuOpenId === item.id ? "long-menu" : undefined}
              aria-haspopup="true"
              onClick={(e) => handleClick(e)}
              sx={{ ml: "auto", mr: 2 }}
            >
              <MoreVert />
            </IconButton>
          )}
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {getOptions(didIBlocked)?.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => handleMenuItemClick(option.type)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* State pin and village or district */}
        {selectedChatRoom?.id && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "5px",
            }}
          >
            {/* STate pin dist */}
            <Box sx={{ display: "flex", gap: "8px" }}>
              {/* Image and state */}
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  marginRight: "8px",
                }}
              >
                <img src={location} alt="location icon" />
                <Typography sx={state_zip_dist}>State</Typography>
              </Box>

              {/* Dot */}
              <div style={dotStyle} />

              <Typography sx={state_zip_dist}>1010</Typography>

              {/* Dot */}
              <div style={dotStyle} />

              <Typography sx={state_zip_dist}>Village or district</Typography>
              <Box sx={{ position: "relative" }}></Box>
            </Box>
          </Box>
        )}

        <Divider sx={divider} />

        <Box>
          {/* chat display */}
          <Box sx={chatStyles.chatContainer}>
            <Box ref={messagesEndRef} sx={chatStyles.messagesArea}>
              {messages.map((message, index) => {
                if (!message?.visibleFor?.includes(currentUserData?.id))
                  return null;

                const messageDate = message.timestamp
                  ? new Date(message.timestamp.seconds * 1000)
                  : new Date();

                const showDateDivider =
                  !previousDate || !isSameDay(messageDate, previousDate);

                previousDate = messageDate;

                return (
                  <React.Fragment key={message.id}>
                    {showDateDivider && (
                      <Divider sx={chatStyles.divider}>
                        <Chip label={formatDate(messageDate)} />
                      </Divider>
                    )}
                    <Box
                      sx={{
                        ...chatStyles.messageWrapper,
                        ...(message.senderId ===
                          getFirebaseCurrentUserIdUUid() &&
                          chatStyles.senderWrapper),
                      }}
                    >
                      <Box
                        sx={{
                          ...chatStyles.message,
                          ...(message.senderId ===
                          getFirebaseCurrentUserIdUUid()
                            ? chatStyles.sentMessage
                            : chatStyles.receivedMessage),
                        }}
                      >
                        {message.content && (
                          <Typography variant="body1">
                            {message.content}
                          </Typography>
                        )}
                        {message.attachments?.map((attachment, index) => (
                          <Box key={index} sx={{ mt: 1 }}>
                            <img
                              src={attachment}
                              alt="attachment"
                              style={{ maxWidth: "300px" }}
                            />
                          </Box>
                        ))}
                        {message.status === "uploading" && (
                          <LinearProgress variant="indeterminate" />
                        )}
                        <Typography
                          sx={{
                            ...chatStyles.timestamp,
                            color:
                              message.senderId ===
                              getFirebaseCurrentUserIdUUid()
                                ? "rgba(255, 255, 255, 0.7)"
                                : "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {formatTimestamp(message.timestamp)}
                        </Typography>
                      </Box>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>

          <Box>
            {/* Input chat */}
            {selectedFiles?.length > 0 && (
              <Box
                width={"100%"}
                position={"relative"}
                height={"2.4rem"}
                bottom={1}
                overflow="hidden"
                sx={{
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    width: "1px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "transparent",
                  },
                }}
              >
                <SelectedAttachment
                  data={selectedFiles}
                  handleRemoveAttachment={handleRemoveAttachment}
                />
              </Box>
            )}

            {(didOpponentBlocked || didIBlocked || blockedMsg) && (
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#ffebee",
                  color: "#d32f2f",
                  padding: "10px",
                  borderRadius: "8px",
                  margin: "10px",
                }}
              >
                {blockedMsg}
              </Box>
            )}
            <Box sx={chatStyles.inputArea}>
              {selectedChatRoom?.id && (
                <>
                  <TextField
                    disabled={didOpponentBlocked || didIBlocked || blockedMsg}
                    onChange={handleInputChange}
                    value={sendMessageContent}
                    fullWidth
                    multiline
                    maxRows={4}
                    placeholder="Type your message..."
                    variant="standard"
                    size="small"
                    sx={{ flexGrow: 1, paddingRight: "8px" }}
                  />
                  <>
                    <IconButton
                      color="primary"
                      sx={{ borderRadius: "8px", maxHeight: "40px" }}
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                        multiple
                      />
                      <AttachFile />
                    </IconButton>
                    <IconButton
                      onClick={handleSendMessage}
                      disabled={didOpponentBlocked || didIBlocked || blockedMsg}
                      color="primary"
                      sx={{
                        backgroundColor: "#2196F3",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#1976D2",
                        },
                        borderRadius: "8px",
                        maxHeight: "40px",
                      }}
                    >
                      <Send />
                    </IconButton>
                  </>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
      {openDialog && (
        <DeleteBlockDialogBox
          // adId={123}
          dialogType={dialogType}
          onClose={handleCloseDialog}
          onContinue={handleContinue}
        />
      )}
    </>
  );
}

export default ChatMessage;

import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import {
  Button,
  InputAdornment,
  Box,
  Avatar,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Images
import Mainlogo from "../../assets/images/HeaderIcon/logo.svg";
import chat from "../../assets/images/HeaderIcon/comment.svg";
import account_blue from "../../assets/images/HeaderIcon/person.svg";
import Account from "../../assets/images/HeaderIcon/Account.svg";
import Dropdown from "../../assets/images/HeaderIcon/Down.svg";
import location from "../../assets/images/HeaderIcon/location_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/auth/authSlice";
import useAppSelectors from "../../customHooks/useAppSelector";
import { useNavigateAction } from "../../customHooks/useNavigateAction";
import { persistor } from "../../store/store";
import { deleteFCMToken } from "../../config/firebase.config";
import { SearchRounded } from "@mui/icons-material";
import LocationPopUp from "./LocationPopUp";
import { searchProducts } from "../../store/product/productSlice";
import { useDebounce } from "../../customHooks/useDebounce";
import ProductSerachBar from "./ProductSerachBar";

const HeaderContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 32,
  paddingRight: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#fff",
  height: "72px",
  position: "fixed",
  gap: 15,
  top: 0,
  zIndex: 1000,
  width: "96%",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    justifyContent: "center",
  },

  [theme.breakpoints.between("sm", "md")]: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "15px",
    marginBottom: "140px",
    marginTop: "20px",
  },
}));

const LogoImage = styled("img")({
  width: 191,
  height: 124,
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  "&:active": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
});

const VRLine = styled("div")({
  width: "1px",
  height: "24px",
  background: "#D8D8DF",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const PostAddButton = styled(Button)({
  background: "#00A6E8",
  height: "48px",
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "110%",
  borderTopLeftRadius: "50px",
  borderBottomLeftRadius: "50px",
});

const LogoButton = styled(Button)({
  backgroundColor: "transparent",
  boxShadow: "none",
  // border: "1px solid black",
  height:"50px",
  padding: 0,
  minWidth: "auto",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:focus-visible": {
    outline: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationUrl = useLocation();
  const handleProtectedAction = useNavigateAction();
  const { isAuthenticated, currentUserData } = useAppSelectors();
  const [locationPopupOpen, setLocationPopupOpen] = useState(false);
  const [openProfilePopup, setOpenProfilePopup] = useState(null);
  const handleOpenProfile = (event) => {
    setOpenProfilePopup(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setOpenProfilePopup(null);
  };

  const open = Boolean(openProfilePopup);




  // Navigate Home Handle
  const navigateHomeHandle = () => {
    navigate("/");
  };
  // select loaction
  const handleOpenLocationSelect = () => {
    setLocationPopupOpen(!locationPopupOpen);
  };

  const handleCloseLocationSelect = () => {
    setLocationPopupOpen(false);
  };

  // Navigate to new post handler
  const navigateToNewPostHandler = () => {
    handleProtectedAction(() => {
      navigate("/post");
    });
  };

  const handleChat = () => {
    navigate("/chat");
  };

  const handleLogout = (event) => {
    event.preventDefault();
    handleCloseProfile();
    dispatch(logoutUser()).then((res) => {
      if (res?.data?.status) {
        deleteFCMToken(currentUserData?.id);
        persistor.purge();
      }
      navigateHomeHandle();
    });
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("location"))) {
      setLocationPopupOpen(true);
    }
  }, [locationUrl]);

  return (
    <>
      <HeaderContainer>
        {/* Logo */}
        <Box>
          <LogoButton onClick={navigateHomeHandle}>
            <LogoImage src={Mainlogo} alt="Main Logo" />
          </LogoButton>
        </Box>

        {/* Location */}
        <Box
          onClick={handleOpenLocationSelect}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          border={"1px solid #D8D8DF"}
          borderRadius={"4px"}
          background={"#F7F7F8"}
          height={"58px"}
          padding={"0 0.2rem"}
          width={"15%"}
          gap={"0.8rem"}
          sx={{ cursor: "pointer" }}
        >
          <Box>
            <img src={location} alt="location" />
          </Box>
          <Typography variant="subtitle1">
            {JSON.parse(localStorage.getItem("location"))?.name || "Location"}
          </Typography>
          <Box>
            <img src={Dropdown} alt="icon" />
          </Box>
        </Box>
        {locationPopupOpen && (
          <LocationPopUp
            open={locationPopupOpen}
            onClose={handleCloseLocationSelect}
          />
        )}

        {/* Search */}
        <Box sx={{ position: "relative", width: "40%", padding: 2 }}>
         <ProductSerachBar/>
        </Box>

        {/* Text */}
        <Box>
          {!isAuthenticated ? (
            <>
              {/* Login */}
              <Box
                onClick={() => navigate("/auth")}
                style={{
                  display: "flex",
                  gap: 12,
                  cursor: "pointer",
                  marginLeft: "33px",
                  marginRight: "33px",
                }}
              >
                <Avatar
                  alt="Login logo"
                  src={Account}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#000",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "110%",
                  }}
                >
                  Login
                </Typography>
              </Box>
            </>
          ) : (
            // Login
            <Box
              style={{
                display: "flex",
                gap: 12,
                marginLeft: "33px",
                marginRight: "9px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: 6,
                  cursor: "pointer",
                }}
                onClick={handleOpenProfile}
              >
                <Avatar
                  alt="Login logo"
                  src={account_blue}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#000",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "110%",
                  }}
                >
                  {currentUserData?.nickname}
                </Typography>
                <Avatar
                  alt="Login logo"
                  src={Dropdown}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
              </Box>

              {/* Vertical line */}
              <VRLine />

              <Box
                style={{
                  display: "flex",
                  gap: 6,
                  cursor: "pointer",
                }}
              >
                <Button onClick={handleChat} sx={{ textTransform: "none" }}>
                  <Avatar
                    alt="Login logo"
                    src={chat}
                    sx={{ width: 24, height: 24, alignSelf: "center" }}
                  />
                  <Typography
                    sx={{
                      alignSelf: "center",
                      color: "#000",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "110%",
                    }}
                  >
                    Chat
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        {/* Right side button  */}
        <ButtonContainer>
          <PostAddButton variant="contained" onClick={navigateToNewPostHandler}>
            + Post New Ad
          </PostAddButton>
        </ButtonContainer>

        {/* opening profile popover */}
        <Popover
          open={open}
          anchorEl={openProfilePopup}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List>
            {/* My account */}
            <ListItem
              component={Link}
              to="/account"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="My Account" />
            </ListItem>

            {/* Profile */}
            <ListItem
              component={Link}
              to="/profile"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Profile" />
            </ListItem>

            {/* My ads */}
            <ListItem
              component={Link}
              to="/my-ads"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="My Ads" />
            </ListItem>

            {/* Following */}
            <ListItem
              component={Link}
              to="/following"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Following" />
            </ListItem>

            {/* Favourite */}
            <ListItem
              component={Link}
              to="/favourite"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Favourite" />
            </ListItem>

            {/* Help */}
            <ListItem component={Link} to="/help" onClick={handleCloseProfile}>
              <ListItemText primary="Help" />
            </ListItem>

            <ListItem component={Link} to="/" onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Popover>
      </HeaderContainer>
    </>
  );
};

export default Header;

import React from "react";

// Images
import Calendar from "../../assets/images/AdsPage/calendar.svg";
import View from "../../assets/images/AdsPage/view.svg";

// MUI Components
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  CalendarMonthOutlined,
  DeleteOutlineOutlined,
  DriveFileRenameOutlineOutlined,
  ShareOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changeAdStatus,
  fetchAdsById,
  reserveAd,
} from "../../store/myads/myadsSlice";
import unfavourite from "../../assets/images/ProductDetails/favorite.svg";
import favourite from "../../assets/images/ProductDetails/favourite-add.svg";
import ShareAdsDialogBox from "../ShareAdsDialogBox/ShareAdsDialogBox";
import ReserveDialogBox from "../ReserveDialogBox/ReserveDialogBox";
import DeleteMyAds from "../DeleteMyAds/DeleteMyAds";
import { addFavourite } from "../../store/favourite/favouriteSlice";
import { useNavigateAction } from "../../customHooks/useNavigateAction";

const iconBtn = {
  width: "32px",
  height: "32px",
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
};

const getOptions = (status) => [
  { label: "Edit", icon: <DriveFileRenameOutlineOutlined /> },
  {
    label: status ? "In active" : "Active",
    icon: status ? <ToggleOffOutlined /> : <ToggleOnOutlined />,
  },
  { label: "Reserve", icon: <CalendarMonthOutlined /> },
  { label: "Share", icon: <ShareOutlined /> },
  { label: "Delete", icon: <DeleteOutlineOutlined /> },
];
const ITEM_HEIGHT = 48;

function CommonCards({
  item,
  isMyAdsList = false,
  isFavList = false,
  onRemove,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);
  const [isReserveDialogOpen, setIsReserveDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const options = getOptions(item.status);
  const [isFavourite, setIsFavourite] = React.useState([]);
  const handleProtectedAction = useNavigateAction();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get status label based on review and status conditions
  const getStatusLabel = () => {
    if (item.reviewed) {
      if (item.status) {
        return "Active";
      } else {
        return "Inactive";
      }
    } else {
      return "In Bearbeitung";
    }
  };

  // Get status color based on status label
  const getStatusColor = () => {
    const status = getStatusLabel();
    switch (status) {
      case "In Bearbeitung":
        return { bgcolor: "yellow", color: "#00000" };
      case "Active":
        return { bgcolor: "green", color: "#fff" };
      case "Inactive":
        return { bgcolor: "red", color: "#fff" };
      default:
        return { bgcolor: "grey", color: "#00000" };
    }
  };

  // Filter menu options based on conditions
  const getFilteredOptions = () => {
    if (!item.reviewed && item.status) {
      // Remove Active/Inactive options when in "In Progress" state
      return options.filter(
        (option) => option.label !== "Active" && option.label !== "In active"
      );
    }
    return options;
  };

  const handleMenuItemClick = (label, adsData) => {
    if (label === "Edit") {
      dispatch(fetchAdsById(adsData?.id)).then((res) => {
        navigate(`/edit-post/${adsData?.id}`);
      });
    }
    if (label === "Share") {
      setIsShareDialogOpen(true);
    }
    if (label === "Reserve") {
      setIsReserveDialogOpen(true);
    }
    if (label === "Delete") {
      setIsDeleteDialogOpen(true);
    }
    if (label === "In active" || label === "Active") {
      dispatch(changeAdStatus(adsData?.id, { status: !adsData?.status }));
    }
    handleClose();
  };

  const closeShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const closeReserveDialog = () => {
    setIsReserveDialogOpen(false);
  };

  const handleReserveAd = (reserveData) => {
    dispatch(reserveAd({ adId: item.id, reserveData }));
  };

  const handleFavourite = (id, event) => {
    event.stopPropagation();
    handleProtectedAction(() => {
      setIsFavourite((prev) =>
        prev.includes(id) ? prev.filter((favId) => favId !== id) : [...prev, id]
      );
      dispatch(addFavourite(id));

      if (onRemove && (isFavourite.includes(id) || item.favourite)) {
        onRemove(id);
      }
    });
  };

  const navigateToProductDetailsPage = (id, slug) => {
    navigate(`/product-details/${slug}`);
  };
  return (
    <>
      <Card
        key={item.id}
        onClick={() =>
          isFavList && navigateToProductDetailsPage(item.id, item.slug)
        }
        sx={{
          display: "flex",
          position: "relative",
          boxShadow: "none",
          border: "none",
          overflow: "hidden",
        }}
      >
        <Box
          mt={2}
          sx={{
            width: "177px",
            height: "114px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "4px",
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            image={item?.images?.length > 0 ? item.images[currentIndex] : ""} // Safely access the image
            alt={item?.title || "Ad Image"}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            mt={-0.9}
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              px: 2,
              pt: 2,
            }}
          >
            <Typography component="div" variant="h5" sx={{ fontSize: "20px" }}>
              {item.price || "N/A"}
            </Typography>
            {/* Status Chip */}
            {isMyAdsList && (
              <Chip
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: getStatusColor().bgcolor,
                  color: getStatusColor().color,
                }}
                label={getStatusLabel()}
              />
            )}

            {/* More Options Icon */}
            {isMyAdsList && (
              <IconButton
                aria-label="more options"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {isFavList && (
              <Button
                sx={iconBtn}
                onClick={(event) => handleFavourite(item?.id, event)}
              >
                <img
                  src={
                    isFavourite.includes(item.id) || item.favourite
                      ? unfavourite
                      : favourite
                  }
                  alt={isFavourite ? "unfavourite icon" : "favourite icon"}
                />
              </Button>
            )}
            {/* Dropdown Menu */}
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                },
              }}
            >
              {getFilteredOptions().map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleMenuItemClick(option.label, item)}
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Card Content */}
          <Box
            mt={-3}
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              p: 2,
              justifyContent: "space-evenly",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto", padding: "0" }}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                component="div"
                sx={{ fontSize: "14px", fontWeight: 500 }}
              >
                {item.title || "No title available"}
              </Typography>
            </CardContent>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <Box display="flex" alignItems="center">
              <img src={View} alt="view" className="icon" />
              <Typography marginRight={2} fontSize={14}>
                {item.views || 0}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <img src={Calendar} alt="calendar" className="icon" />
              <Typography fontSize={14}>{item.reserveTill || "N/A"}</Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", top: 115, right: 35 }}>
            <Typography variant="body1" color="gray" fontSize={12}>
              {item?.createdAt}
            </Typography>
          </Box>
        </Box>
      </Card>

      {/* Share Ads Dialog */}
      <ShareAdsDialogBox
        adId={item?.adId}
        open={isShareDialogOpen}
        onClose={closeShareDialog}
      />

      <ReserveDialogBox
        adId={item.id}
        open={isReserveDialogOpen}
        onClose={closeReserveDialog}
        onReserve={handleReserveAd}
      />
      <DeleteMyAds
        adId={item.id}
        open={isDeleteDialogOpen}
        onClose={setIsDeleteDialogOpen}
      />
    </>
  );
}

export default CommonCards;

import { useNavigate } from "react-router-dom";
import useAppSelectors from "./useAppSelector";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../config/firebase.config";
import { useState } from "react";
export const useFireBaseChatHelper = () => {
  const [selectedChatRoom, setSelectedChatRoom] = useState({});
  const [didIBlocked, setDidIBlocked] = useState(false);
  const [didOpponentBlocked, setDidOpponentBlocked] = useState(false);
  const [blockedMsg, setBlockedMsg] = useState("");
  const navigate = useNavigate();
  const { currentUserData } = useAppSelectors();

  const getFirebaseCurrentUserIdUUid = () => {
    return auth.currentUser?.uid;
  };

  const setUserOnlineStatus = async (user_id) => {
    const userId = user_id.toString();
    const userRef = doc(db, "users", userId);
    try {
      // Check if the document exists
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        // If document exists, update it
        await updateDoc(userRef, {
          online: true,
          lastSeen: serverTimestamp(),
        });
      } else {
        // If document doesn't exist, create it
        await setDoc(userRef, {
          online: true,
          lastSeen: serverTimestamp(),
        });
      }
    } catch (error) {}
  };

  // Function to set user as offline
  const setUserOffline = async (userId) => {
    const userRef = doc(db, "users", userId);
    try {
      await updateDoc(userRef, {
        online: false,
        lastSeen: serverTimestamp(),
      });
    } catch (error) {}
  };

  const listenToUserStatus = (userId, setIsOnline) => {
    // Create reference to the user document
    const userRef = doc(db, "users", userId);

    // Set up real-time listener
    const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
      if (userSnapshot.exists()) {
        const isUserOnline = userSnapshot.data()?.online ?? false;
        setIsOnline(isUserOnline);
      }
    });

    // Return unsubscribe function for cleanup
    return unsubscribe;
  };

  const blockUnblockUser = async (blockedUserId, userId) => {
    try {
      const userRef = doc(db, "blockedUsers", userId);

      // Check if the document exists
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const blockedUsers = userSnapshot.data().blocked || [];
        if (blockedUsers.includes(blockedUserId)) {
          // If the user is already blocked, unblock them
          await updateDoc(userRef, {
            blocked: arrayRemove(blockedUserId),
          });
        } else {
          // Block the user
          await updateDoc(userRef, {
            blocked: arrayUnion(blockedUserId),
          });
        }
      } else {
        // Create the document and block the user
        await setDoc(userRef, {
          blocked: [blockedUserId],
        });
      }
    } catch (error) {
    }
  };

  const listenToUserBlockStatus = (
    setDidIBlocked,
    setDidOpponentBlocked,
    setBlockedMsg
  ) => {
    // Keep track of blocked status locally within closure
    let userIsBlocked = false;
    let opponentIsBlocked = false;

    const userId = currentUserData?.id.toString();
    const onlineUserId =
      selectedChatRoom?.customerId === currentUserData?.id
        ? selectedChatRoom?.ownerId.toString()
        : selectedChatRoom?.customerId.toString();

    const chatPartnerName =
      selectedChatRoom?.customerId === currentUserData?.id
        ? selectedChatRoom?.providerName
        : selectedChatRoom?.customerName;

    const userRef = doc(db, "blockedUsers", userId);
    const opponentRef = doc(db, "blockedUsers", onlineUserId);

    const unsubscribeUser = onSnapshot(userRef, (userSnapshot) => {
      if (userSnapshot.exists()) {
        const blockedList = userSnapshot.data().blocked || [];
        userIsBlocked = blockedList.includes(+onlineUserId);
        setDidIBlocked(userIsBlocked);
        if (userIsBlocked) {
          setBlockedMsg(`You have blocked ${chatPartnerName}`);
        } else if (opponentIsBlocked) {
          setBlockedMsg(`${chatPartnerName} has blocked you.`);
        } else {
          setBlockedMsg("");
        }
      }
      else{
        setDidIBlocked(false);
      }
    });

    const unsubscribeOpponent = onSnapshot(opponentRef, (opponentSnapshot) => {
      if (opponentSnapshot.exists()) {
        const blockedList = opponentSnapshot.data().blocked || [];
        opponentIsBlocked = blockedList.includes(+userId);
        setDidOpponentBlocked(opponentIsBlocked);
        if (opponentIsBlocked) {
          setBlockedMsg(`${chatPartnerName} has blocked you.`);
        } else if (userIsBlocked) {
          setBlockedMsg(`You have blocked ${chatPartnerName}`);
        } else {
          setBlockedMsg("");
        }
      }
      else{
        setDidOpponentBlocked(false);
      }
    });

    return () => {
      unsubscribeUser();
      unsubscribeOpponent();
    };
  };

  const deleteAllPreviousMessages = async (chatRoomId, userID) => {
    const now = new Date();
    try {
      // Get reference to messages collection
      const messagesRef = collection(db, `chatRooms/${chatRoomId}/messages`);

      // Query messages before current timestamp
      const q = query(
        messagesRef,
        where("timestamp", "<", Timestamp.fromDate(now))
      );
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return;
      }

      // Start a batch write
      const batch = writeBatch(db);

      snapshot.docs.forEach((document) => {
        const messageRef = doc(
          db,
          `chatRooms/${chatRoomId}/messages/${document.id}`
        );

        // Use arrayRemove to remove the userId from visibleFor array
        batch.update(messageRef, {
          visibleFor: arrayRemove(userID),
        });
      });

      // Commit the batch
      await batch.commit();

      // Update the chatRoom's last message for this user
      const chatRoomRef = doc(db, "chatRooms", chatRoomId);
      await updateDoc(chatRoomRef, {
        [`lastMessage.${userID}`]: "",
        [`lastMessageTimestamp.${userID}`]: null,
      });
    } catch (error) {}
  };

  const getOrCreateChatRoom = async (
    productId,
    ownerId,
    customerId,
    productName,
    productImage,
    providerName,
    price,
    customerName,
    extra
  ) => {
    const chatRoomsRef = collection(db, "chatRooms");

    // Query for an existing chat room
    const q = query(
      chatRoomsRef,
      where("productId", "==", productId),
      where("customerId", "==", customerId)
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // If a chat room already exists, return its ID
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id;
      }

      // If no chat room exists, create a new one
      const newChatRoom = await addDoc(chatRoomsRef, {
        productId,
        productName,
        productImage,
        providerName,
        customerName,
        participants: [customerId, ownerId],
        extra,
        price,
        ownerId,
        customerId,
        timestamp: serverTimestamp(),
        lastMessage: {
          ownerId: "",
          customerId: "",
        },
        lastMessageTimestamp: {
          ownerId: serverTimestamp(),
          customerId: serverTimestamp(),
        },
      });

      // Return the ID of the chat room
      return newChatRoom.id;
    } catch (error) {
      throw error;
    }
  };

  const navigateToChat = async (productData, productImageList) => {
    const ownerId = productData.createdBy.id;
    const productId = productData.id;
    const userId = currentUserData.id;

    const customerName = `${currentUserData.firstName} ${currentUserData.lastName}`;
    const providerName = `${productData.createdBy.firstName} ${productData.createdBy.lastName}`;
    const productPrice = productData.price;
    const extra = {
      description: productData.description,
    };

    const roomId = await getOrCreateChatRoom(
      productId,
      parseInt(ownerId),
      userId,
      productData.title,
      productImageList[0],
      providerName,
      productPrice,
      customerName,
      extra
    );
    const chatData = {
      productImage: productImageList[0],
      productId,
      price: productPrice.toString(),
      extra,
      customerId: userId,
      ownerId: parseInt(ownerId),
      productName: productData.title,
      providerName,
      customerName,
      participants: [userId, parseInt(ownerId)],
      timestamp: serverTimestamp(),
      lastMessage: {
        ownerId: "",
        customerId: "",
      },
      lastMessageTimestamp: {
        ownerId: serverTimestamp(),
        customerId: serverTimestamp(),
      },
    };
    // selected group
    const args = {
      ...chatData,
      id: roomId,
    };
    navigate("/chat", { state: args });
  };

  const fetchMessages = (chatRoomId, setMessages) => {
    const messagesRef = collection(db, `chatRooms/${chatRoomId}/messages`);
    const q = query(messagesRef, orderBy("timestamp"));

    return onSnapshot(q, (snapshot) => {
      const messageList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messageList);
    });
  };

  const fetchChatRooms = async (
    userId,
    setAllChatRoomData,
    setIsLoading,
    setSelectedChatRoom,
    chatRoomData,
    setMessages
  ) => {
    setIsLoading(true);
    try {
      // Query for chat rooms where the user is a participant
      const chatRoomsRef = collection(db, "chatRooms");
      const q = query(
        chatRoomsRef,
        where("participants", "array-contains", userId),
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);

      // Map the chat rooms to a usable format
      const chatRoomsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllChatRoomData(chatRoomsData);
      if (chatRoomsData?.length > 0) {
        setSelectedChatRoom(chatRoomsData[0]);
        fetchMessages(chatRoomsData[0]?.id, setMessages);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const listenToChatRooms = (userId, setChatRooms) => {
    // Query for chat rooms where the user is a participant
    const chatRoomsRef = collection(db, "chatRooms");
    const q = query(
      chatRoomsRef,
      where("participants", "array-contains", userId),
      orderBy("timestamp", "desc")
    );

    // Set up a real-time listener for chat rooms
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const chatRoomsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChatRooms(chatRoomsData);
      },
      (error) => {}
    );

    return unsubscribe;
  };

  const getOtherParticipant = (myId, participants) => {
    return participants.find((id) => id !== myId) || null;
  };

  const sendMessage = async (
    chatRoomId,
    message,
    userId,
    onlineUserId,
    files = [],
    updateProgress
  ) => {
    if (message.trim() === "" && files.length === 0) return;

    try {
      const senderId = getFirebaseCurrentUserIdUUid();

      if (!senderId) {
        return;
      }

      // Add the message to the chat room's messages subcollection
      const messagesRef = collection(db, `chatRooms/${chatRoomId}/messages`);
      const messageRef = await addDoc(messagesRef, {
        senderId,
        from: userId,
        to: onlineUserId,
        content: message.trim(),
        timestamp: serverTimestamp(),
        visibleFor: [userId, onlineUserId],
        attachments: [],
        status: files.length > 0 ? "uploading" : "sent",
      });

      // If files are present, upload them
      if (files.length > 0) {
        const fileUrls = await uploadFiles(
          files,
          chatRoomId,
          messageRef.id,
          updateProgress
        );

        // Update the message with the file URLs
        await updateDoc(messageRef, {
          attachments: fileUrls,
          status: "sent",
        });
      }

      // Update the last message and timestamp in the chat room
      const chatRoomRef = doc(db, "chatRooms", chatRoomId);
      const timestamp = serverTimestamp();
      const msg = message.trim() || (files.length > 0 ? "File" : "");

      await updateDoc(chatRoomRef, {
        [`lastMessage.${userId}`]: msg,
        [`lastMessageTimestamp.${userId}`]: timestamp,

        [`lastMessage.${onlineUserId}`]: msg,
        [`lastMessageTimestamp.${onlineUserId}`]: timestamp,
      });
    } catch (error) {}
  };

  const uploadFiles = async (files, chatRoomId, messageId, updateProgress) => {
    const fileUrls = [];

    for (const file of files) {
      const filePath = `chatRooms/${chatRoomId}/messages/${messageId}/${file.name}`;
      const storageRef = ref(storage, filePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Wrap the upload logic in a Promise
      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            updateProgress(file.name, progress); // Update progress for the file
          },
          (error) => {
            reject(error); // Reject the promise on error
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            fileUrls.push(downloadURL); // Add file metadata
            resolve(); // Resolve the promise when upload is complete
          }
        );
      });
    }

    return fileUrls; // Return the array of file URLs
  };

  const listenToTypingStatus = (chatRoomId, partnerId, setIsTyping) => {
    // Reference the chat room document
    const chatRoomRef = doc(db, "chatRooms", chatRoomId);

    // Set up a real-time listener for typing status
    const unsubscribe = onSnapshot(chatRoomRef, (snapshot) => {
      if (snapshot.exists()) {
        // Get the typing status data
        const typingData = snapshot.data().typingStatus || {};

        // Update the typing status for the partner
        setIsTyping(typingData[partnerId] || false);
      }
    });

    // Return the unsubscribe function to clean up the listener
    return unsubscribe;
  };

  const setTypingStatus = async (chatRoomId, userId, isTyping) => {
    try {
      // Reference the chat room document
      const chatRoomRef = doc(db, "chatRooms", chatRoomId);

      // Update the typing status for the user
      await updateDoc(chatRoomRef, {
        [`typingStatus.${userId}`]: isTyping,
      });
    } catch (error) {}
  };

  return {
    setSelectedChatRoom,
    selectedChatRoom,
    didIBlocked,
    setDidIBlocked,
    didOpponentBlocked,
    setDidOpponentBlocked,
    blockedMsg,
    setBlockedMsg,

    getOtherParticipant,
    getFirebaseCurrentUserIdUUid,

    setUserOnlineStatus,
    setUserOffline,
    navigateToChat,
    getOrCreateChatRoom,
    listenToChatRooms,

    listenToUserStatus,
    fetchChatRooms,
    fetchMessages,
    listenToTypingStatus,
    setTypingStatus,
    sendMessage,
    deleteAllPreviousMessages,
    blockUnblockUser,
    listenToUserBlockStatus,
  };
};

import { initializeApp } from "firebase/app";
import {
  arrayUnion,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMIAN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESURMNET_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
const facebookProvider = new FacebookAuthProvider();
let messaging;

// Register the service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw-verkaufealles.js", { scope: "/" })
    .then((registration) => {
      // console.log("Service Worker registered with scope:", registration.scope);
      messaging = getMessaging(app);
    })
    .catch((error) => {
      // console.error("Service worker registration failed:", error);
    });
}

// Request notification permission
const requestNotificationPermission = async () => {
  try {
    if (!("Notification" in window)) {
      // console.warn("This browser does not support notifications");
      return false;
    }

    if (Notification.permission === "granted") {
      return true;
    }

    if (Notification.permission === "denied") {
      // console.warn("Notification permission was previously denied");
      return false;
    }

    // Request permission
    const permission = await Notification.requestPermission();
    return permission === "granted";
  } catch (error) {
    // console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Fetch and store FCM token
export const fetchAndStoreToken = async (user_id) => {
  if (!messaging) {
    // console.warn("Messaging is not initialized");
    return;
  }

  try {
    // Request notification permission
    const hasPermission = await requestNotificationPermission();
    if (!hasPermission) {
      // console.warn("Notification permission not granted");
      return;
    }

    // Get the FCM token
    const currentToken = await getToken(messaging, {
      vapidKey: "BJ-9zuFEMK5Ze6IONz90sAU-ldjP-K6KCWFxuL9XckI2zQ7j38m2JZTvnQFqZ098UplmmcNg8gaCVsTHGVVqZmU", // VAPID key
    });

    if (!currentToken) {
      // console.warn("No FCM token available");
      return;
    }

    // Store token in Firestore
    await storeFCMTokenInFirestore(currentToken, user_id);
    // console.log("FCM token:", currentToken);

    return currentToken;
  } catch (error) {
    // console.error("Error in fetchAndStoreToken:", error);
  }
};


// Store FCM token in Firestore
const storeFCMTokenInFirestore = async (token, user_id) => {
  const userRef = doc(db, "users", user_id.toString());

  try {
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      await updateDoc(userRef, {
        fcmToken: arrayUnion(token),
      });
    } else {
      await setDoc(userRef, {
        fcmToken: [token],
      });
    }
    // console.log("FCM token stored in Firestore successfully.");
  } catch (error) {
    // console.error("Error storing FCM token in Firestore:", error);
  }
};

export const deleteFCMToken = async (user_id) => {
  const userRef = doc(db, "users", user_id.toString());

  try {
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      await updateDoc(userRef, {
        fcmToken: [], // Clear the FCM token array
      });
      // console.log("FCM token deleted from Firestore.");
    }
  } catch (error) {
    // console.error("Error deleting FCM token:", error);
  }
};

// Listener for incoming messages
export const onMessageListener = (callback) =>
  new Promise((resolve) => {
    if (!messaging) {
      resolve(null);
      return;
    }

    onMessage(messaging, (payload) => {
      callback(payload);
      resolve(payload);
    });
  });

// Show notification
const showNotification = (payload) => {
  try {
    const { title, body } = payload.notification;

    if (!("Notification" in window)) {
      // console.warn("This browser does not support notifications");
      return;
    }

    if (Notification.permission !== "granted") {
      // console.warn("Notification permission not granted");
      return;
    }

    const notificationOptions = {
      body,
      timestamp: Date.now(),
      requireInteraction: true,
    };

    new Notification(title, notificationOptions);
  } catch (error) {
    // console.error("Error showing notification:", error);
  }
};

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log(result);
    
    return {
      name: user.displayName,
      email: user.email,
      social_id: user?.providerData[0].uid,
    };
  } catch (error) {
    console.error("Google Sign-in Error:", error);
    return null;
  }
};

const signInWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider);
    const user = result.user;
    console.log(result);
    
    // return {
    //   name: user.displayName,
    //   email: user.email,
    //   social_id: user.uid,
    // };
  } catch (error) {
    console.error("Apple Sign-in Error:", error);
    return null;
  }
};

const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    const user = result.user;
    console.log(result);
    
    return {
      name: user.displayName,
      email: user.email,
      social_id: user.uid,
    };
  } catch (error) {
    console.error("Facebook Sign-in Error:", error);
    return null;
  }
};

// Attach the notification listener
onMessageListener(showNotification);

export { db, auth, storage, signInWithGoogle, signInWithApple, signInWithFacebook };

import React, { useState, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDynamicOption } from "../../../store/product/productSlice";
import { getLocationBySearchQuery } from "../../../store/profile/profileSlice";

const CustomLocationDynamicSelectField = ({
  name,
  fieldprops,
  label,
  control,
  multiple,
  errors,
  getValues,
  setValue,
  setValueOnChange,
  locationSourceFieldName,
  getLocationOptionFromApi,
  customHandleChange,
}) => {
  const [options, setOptions] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const sourceValue = getValues(locationSourceFieldName);
  const prevSourceValue = useRef(sourceValue);
  const fetchOptions = async () => {
    try {
      const resolvedOptions = await getLocationOptionFromApi(
        sourceValue,
        locationSourceFieldName
      );
      if (resolvedOptions?.length > 0) setOptions(resolvedOptions);
    } catch (error) {
      console.error("Failed to fetch options:", error);
      setOptions([]);
    }
  };
  // Fetch options when sourceValue changes
  useEffect(() => {
   
    if (!sourceValue) {
      setOptions([]);
      return;
    }
    if (prevSourceValue.current !== sourceValue) {
      setValue(name, "");
      setValue(setValueOnChange,"")
      setOptions([]);
      prevSourceValue.current = sourceValue;
    }
    if(sourceValue?.length >= 4){

      fetchOptions();
    }
  }, [sourceValue, locationSourceFieldName, getLocationOptionFromApi]);

  useEffect(() => {
    if (!sourceValue) {
      setOptions([]);
      return;
    }
    if (location.pathname.startsWith("/edit-post/")) {
      dispatch(getLocationBySearchQuery(sourceValue)).then((res) =>
        setOptions(res)
      );
    }
  }, []);

  return (
    <Box width="100%" mb="0.85rem">
      <FormControl variant="standard" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const value = multiple
              ? Array.isArray(field.value)
                ? field.value
                : []
              : field.value;

            return (
              <Select
                {...field}
                label={label}
                value={value || ""}
                multiple={multiple}
                renderValue={(selected) =>
                  multiple ? selected.join(", ") : selected
                }
                error={!!errors[name]}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const selectedOption = options.find(
                    (opt) => opt.name === selectedValue
                  );
                  if (customHandleChange && selectedOption) {
                    customHandleChange(e, field, selectedOption?.state,fieldprops);
                  }
                }}
              >
                <MenuItem sx={{ display: "none" }} value="">
                  <em>None</em>
                </MenuItem>
                {options?.map((option, i) => (
                  <MenuItem key={i + "location"} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
        {errors[name] && (
          <div style={{ color: "red" }}>{errors[name].message}</div>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomLocationDynamicSelectField;

// return (
//   <Box width={"100%"} key={name} mb={"0.85rem"}>
//     <FormControl variant="standard" fullWidth>
//       <InputLabel>{label}</InputLabel>
//       <Controller
//         name={name}
//         control={control}
//         render={({ field }) => (
//           <Select
//             {...field}
//             label={label}
//             disabled={disabled}
//             value={getValues(name)}
//             renderValue={(selected) =>
//               multiple ? selected.join(", ") : selected
//             }
//             error={!!errors[name]}
//           >
//             <MenuItem value="">
//               <em>None</em>
//             </MenuItem>
//             {getValues(locationSourceFieldName) &&
//               getOptionFromApi(
//                 getValues(optionSourceFieldName),
//                 optionSourceFieldName
//               ).map((option) => (
//                 <MenuItem
//                   key={option.value}
//                   value={option.value}
//                 >
//                   {option.label}
//                 </MenuItem>
//               ))}
//           </Select>
//         )}
//       />
//       {errors[name] && (
//         <div style={{ color: "red" }}>
//           {errors[name].message}
//         </div>
//       )}
//     </FormControl>
//   </Box>
// );

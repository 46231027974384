export function formatString(str) {
  return str
    .split("_") // Split the string at underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words with spaces
}

export const formatPreviewData = (data = {}) => {
  return Object.keys(data).reduce((acc, key) => {
    const field = data[key];
    const { label, value } = field;

    // Case 1: If value is an object with year and month (like age)
    // if (value && typeof value === "object" && value.year !== undefined) {
    //   const year = value.year ? `${value.year} year` : "";
    //   const month = value.month ? `${value.month} month` : "";
    //   const formattedValue = [year, month].filter(Boolean).join(" ");
    //   acc[key] = { label: label, value: formattedValue };
    // }
    // Case 2: Array with objects containing displayData
     if (Array.isArray(value)) {
      if (value.length > 0 && value[0]?.displayData) {
        const formattedValue = value
          .map((item) => item.displayData || item.value || "")
          .join(" | ");
        acc[key] = { label: label, value: formattedValue };
      }
      // Case 3: Array of strings
      else if (typeof value[0] === "string") {
        acc[key] = { label: label, value: value.join(" | ") };
      }
      // Case 4: Array of objects, likely image case
      else if (value.length > 0 && value[0]?.value === undefined) {
        acc[key] = { label: label, value: value };
      }
    }
    // Case 5: Regular field with a simple value
    else if (typeof value === "boolean") {
      acc[key] = {
        label: label,
        value: field?.displayData ? field?.displayData : value ? "Yes" : "No",
      };
    } else if (typeof value === "string" || typeof value === "number") {
      acc[key] = {
        label: label,
        value: field?.displayData ? field?.displayData : value,
      };
    }

    return acc;
  }, {});
};

// imageUtils.js
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const filesToBase64 = async (files) => {
  if (!Array.isArray(files)) return [];
  return Promise.all(files.map(file => fileToBase64(file)));
};

export const base64ToFile = (base64String, index) => {
  try {
    const [header, base64Data] = base64String.split(',');
    const mimeString = header.split(':')[1].split(';')[0];
    const byteString = atob(base64Data);
    
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    
    const blob = new Blob([ab], { type: mimeString });
    const extension = mimeString.split('/')[1];
    return new File([blob], `image-${index}.${extension}`, { type: mimeString });
  } catch (error) {
    console.error('Error converting base64 to file:', error);
    return null;
  }
};

export const base64ArrayToFiles = (base64Array) => {
  if (!Array.isArray(base64Array)) return [];
  return base64Array.map((base64String, index) => base64ToFile(base64String, index))
    .filter(file => file !== null);
};

export const testModel={
  BMW: [
    {
        "label": "M3",
        "value": "M3",
        "selected": false
    },
    {
        "label": "X5",
        "value": "X5",
        "selected": false
    },
    {
        "label": "i8",
        "value": "i8",
        "selected": false
    },
    {
        "label": "7 Series",
        "value": "7 Series",
        "selected": false
    },
    {
        "label": "Z4",
        "value": "Z4",
        "selected": false
    }
],
  Brabus: [
    {
        "label": "G V12 900",
        "value": "G V12 900",
        "selected": false
    },
    {
        "label": "Rocket 900",
        "value": "Rocket 900",
        "selected": false
    },
    {
        "label": "800 Adventure XLP",
        "value": "800 Adventure XLP",
        "selected": false
    }
]
 , 
  Audi: [
    {
        "label": "R8",
        "value": "R8",
        "selected": false
    },
    {
        "label": "A4",
        "value": "A4",
        "selected": false
    },
    {
        "label": "Q7",
        "value": "Q7",
        "selected": false
    },
    {
        "label": "RS7",
        "value": "RS7",
        "selected": false
    },
    {
        "label": "TT",
        "value": "TT",
        "selected": false
    }
],
  Aston_Martin: [
    {
        "label": "DB11",
        "value": "DB11",
        "selected": false
    },
    {
        "label": "Vantage",
        "value": "Vantage",
        "selected": false
    },
    {
        "label": "DBS Superleggera",
        "value": "DBS Superleggera",
        "selected": false
    },
    {
        "label": "Rapide",
        "value": "Rapide",
        "selected": false
    },
    {
        "label": "Valkyrie",
        "value": "Valkyrie",
        "selected": false
    }
], 
  Artega: [
    {
        "label": "GT",
        "value": "GT",
        "selected": false
    },
    {
        "label": "Scalo",
        "value": "Scalo",
        "selected": false
    },
    {
        "label": "Karo",
        "value": "Karo",
        "selected": false
    }
]
,
  Apollo: [
    {
        "label": "Intensa Emozione",
        "value": "Intensa Emozione",
        "selected": false
    },
    {
        "label": "Arrow",
        "value": "Arrow",
        "selected": false
    },
    {
        "label": "N",
        "value": "N",
        "selected": false
    }
]
,
  Alpine: [
    {
        "label": "A110",
        "value": "A110",
        "selected": false
    },
    {
        "label": "A310",
        "value": "A310",
        "selected": false
    },
    {
        "label": "GTA",
        "value": "GTA",
        "selected": false
    },
    {
        "label": "V6 Turbo",
        "value": "V6 Turbo",
        "selected": false
    }
]
,
  Alfa_Romeo: [
    {
        "label": "Giulia",
        "value": "Giulia",
        "selected": false
    },
    {
        "label": "Stelvio",
        "value": "Stelvio",
        "selected": false
    },
    {
        "label": "4C",
        "value": "4C",
        "selected": false
    },
    {
        "label": "8C Competizione",
        "value": "8C Competizione",
        "selected": false
    },
    {
        "label": "Tonale",
        "value": "Tonale",
        "selected": false
    }
]
,
  
}
import React from "react";
import { Button, Avatar, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function ImageWithTextButton({ image, text, onClick }) {
  return (
    <Button onClick={onClick} sx={{ textTransform: "none", minWidth: "auto" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Avatar src={image} alt="Icon" sx={{ width: 64, height: 64 }} />
        <Typography
          sx={{
            color: "#26252C",
            textAlign: "center",
            fontFamily: "Work Sans",
            fontSize: "14px",
            fontWeight: "500",
            mt: 1,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Button>
  );
}

function PopularCategory({ categories }) {
  const adsProduct = useSelector(
    (state) => state.categoryReducer?.products || []
  );
  console.log(adsProduct);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ads-page");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      mt={3}
      p={2}
      sx={{
        background: "#fff",
        borderRadius: "4px",
        border: "1px solid #D8D8DF",
      }}
    >
      {/* Heading */}
      <Typography
        sx={{
          color: "#000",
          fontFamily: "Work Sans",
          fontSize: "20px",
          fontWeight: "600",
          mb: 1,
        }}
      >
        Popular Category
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          gap: 1,
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {categories?.map((item, index) => (
          <ImageWithTextButton
            key={index}
            image={item.icon}
            text={item.name}
            onClick={handleClick}
          />
        ))}
      </Box>
    </Box>
  );
}

export default PopularCategory;

import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getCookie } from "../../utils/cookie";

// API of prduct
const PRODUCT_API = `${process.env.REACT_APP_BASE_URL}`;
const REPORT_ADS = `${process.env.REACT_APP_BASE_URL}//product/interact`;
const PRODUCT_SEARCH = `${process.env.REACT_APP_BASE_URL}/search`;

const initialState = {
  localPreviewProductData: {},
  publishData: {},
  sponsoredProductData: [],
  newListingProductData: [],
  trendyProductData: [],
  popularProductData: [],
  productDetailsById: {},
  recentlyViewedData: {},
  status: null,
  message: null,
};

export const ProductListSlice = createSlice({
  name: "ProductList",
  initialState,
  reducers: {
    setNewListingProductList: (state, action) => {
      state.newListingProductData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setSponsoredProductList: (state, action) => {
      state.sponsoredProductData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setTrendyProductList: (state, action) => {
      state.trendyProductData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setPopularProductList: (state, action) => {
      state.popularProductData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setProductDetails: (state, action) => {
      state.productDetailsById = action.payload.productDetailsById;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setLocalPreviewProductData: (state, action) => {
      state.localPreviewProductData = action.payload.data;
    },
    saveProductPublishData: (state, action) => {
      state.publishData = action.payload.data;
    },
    setRecentlyViewedProductList: (state, action) => {
      state.recentlyViewedData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  setNewListingProductList,
  setSponsoredProductList,
  setTrendyProductList,
  setPopularProductList,
  setProductDetails,
  productListFailure,
  setLocalPreviewProductData,
  saveProductPublishData,
  setRecentlyViewedProductList,
} = ProductListSlice.actions;

export const headerConfig = () => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

  const config = {
    headers: {},
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const fetchSponsoredProductList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/sponsored`,
      headerConfig()
    );

    dispatch(
      setSponsoredProductList({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchNewListingProductList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/new-listing`,
      headerConfig()
    );

    dispatch(
      setNewListingProductList({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchTrendyProductList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/trendy`,
      headerConfig()
    );

    dispatch(
      setTrendyProductList({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchPopularCategoryList = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/categories/popular`,
      headerConfig()
    );

    dispatch(
      setPopularProductList({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchRecentlyViewed = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/recently-viewed`,
      headerConfig()
    );

    dispatch(
      setRecentlyViewedProductList({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response?.data?.data;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const fetchProductById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${PRODUCT_API}/product/${id}`,
      headerConfig()
    );
    dispatch(
      setProductDetails({
        status: response.data.status,
        message: response.data.message,
        productDetailsById: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const reportAds = (id, reportData) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(`${REPORT_ADS}/${id}`, reportData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // Dispatch a success action if needed, for now log the response
    return response;
  } catch (error) {
    console.error("Error reporting ad:", error.message);
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const uploadProduct = (formData) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(`${PRODUCT_API}/product`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const editMyAds = (formData, adId) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.put(
      `${PRODUCT_API}/my-ads/${adId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const sendEmail = (id, data) => async (dispatch) => {
  const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);
  try {
    const response = await axios.post(
      `${PRODUCT_API}/product/interact/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const localPreviewProduct = (previewData) => async (dispatch) => {
  try {
    dispatch(
      setLocalPreviewProductData({
        data: previewData,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const searchProducts =
  (keyword, page = 1, pageSize = 20) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${PRODUCT_SEARCH}?keyword=${keyword}&page=${page}&pageSize=${pageSize}`,
        headerConfig()
      );

      return response?.data?.data;
    } catch (error) {
      dispatch(
        productListFailure({
          status: false,
          message: error.message,
        })
      );
    }
  };

export const publishProduct = (data) => async (dispatch) => {
  try {
    dispatch(
      saveProductPublishData({
        data: data,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const getDynamicOption = (identifier) => async (dispatch) => {
  const response = await axios.get(
    `https://verkaufdev.semseosmo.com/api/v1/fields/${identifier}`,
    headerConfig()
  );
  return response?.data?.data?.values || [];
};

export default ProductListSlice.reducer;

import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Controller } from "react-hook-form";


const createLabelMap = (options) => {
  return options.reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {});
};

const CustomSelectField = ({
  name,
  label,
  control,
  disabled,
  multiple,
  options,
  errors,
  customHandleChange
}) => {
  // Get or create label map on mount
  const labelMap = React.useMemo(() => createLabelMap(options), []); // Empty deps since we don't need to recreate

  const findLabel = (value) => {
    if (multiple && Array.isArray(value)) {
      return value.map((v) => labelMap[v] || "").join(", ");
    }
    return labelMap[value] || "";
  };
  return (
    <Box width="100%" mb="0.85rem">
      <FormControl variant="standard" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const value = multiple
              ? Array.isArray(field.value)
                ? field.value
                : []
              : field.value;

            return (
              <Select
                {...field}
                label={label}
                disabled={disabled}
                multiple={multiple}
                value={value}
                renderValue={findLabel}
                error={!!errors[name]}
              >
                <MenuItem sx={{display:"none"}} value="">
                  <em>None</em>
                </MenuItem>
                {options.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {multiple ? (
                      <>
                        <Checkbox
                          checked={value?.includes(option.value)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={option.label} />
                      </>
                    ) : (
                      option.label
                    )}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
        {errors[name] && (
          <div style={{ color: "red" }}>{errors[name].message}</div>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomSelectField;


// return (
  //   <Box width={"100%"} key={name} mb={"0.85rem"}>
  //     <FormControl variant="standard" fullWidth>
  //       <InputLabel>{label}</InputLabel>
  //       <Controller
  //         name={name}
  //         control={control}
  //         render={({ field }) => {
  //           console.log(options);

  //           const value = multiple
  //             ? Array.isArray(field.value)
  //               ? field.value
  //               : []
  //             : field.value;
  //           return (
  //             <Select
  //               {...field}
  //               label={label}
  //               disabled={disabled}
  //               multiple={multiple}
  //               value={value}
  //               renderValue={(selected) =>
  //                 multiple ? selected.join(", ") : selected
  //               }
  //               error={!!errors[name]}
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               {options.map((option, i) => (
  //                 <MenuItem key={i} value={option.value}>
  //                   {multiple ? (
  //                     <>
  //                       <Checkbox
  //                         checked={value?.includes(
  //                           option.value
  //                         )}
  //                         tabIndex={-1}
  //                         disableRipple
  //                       />
  //                       <ListItemText
  //                         primary={option.label}
  //                       />
  //                     </>
  //                   ) : (
  //                     option.label
  //                   )}
  //                 </MenuItem>
  //               ))}
  //             </Select>
  //           );
  //         }}
  //       />
  //       {errors[name] && (
  //         <div style={{ color: "red" }}>
  //           {errors[name].message}
  //         </div>
  //       )}
  //     </FormControl>
  //   </Box>
  // );
import { AttachFile } from "@mui/icons-material";
import { Avatar, Box, Chip } from "@mui/material";

const SelectedAttachment = ({ data, handleRemoveAttachment }) => {
  return (
    <Box
      position={"absolute"}
      bgcolor={"#FBFBFB"}
      display={"flex"}
      boxSizing={"border-box"}
      width={"100%"}
    >
      {data?.map((item, i) => (
        <Box
          display={"flex"}
          maxWidth={"25%"}
          padding={"0.5rem"}
          key={"fileAttachment" + i}
        >
          <Box width={"100%"}>
            <Chip
              sx={{ ".MuiChip-deleteIcon": { color: "#DB0011" } }}
              label={`${item?.name ? item?.name : item?.file?.name}`}
              variant="outlined"
              avatar={
                <Avatar
                  title={`${item?.name ? item?.name : item?.file?.name}`}
                >
                  <AttachFile sx={{ fontSize: 18 }} />
                </Avatar>
              }
              onDelete={() => handleRemoveAttachment(i)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SelectedAttachment;

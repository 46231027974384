import { useCallback, useState } from "react";

export const useDebounce = (callback, delay) => {
    const [timeoutId, setTimeoutId] = useState(null);
  
    const debouncedFn = useCallback((...args) => {
      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      
      // Set a new timeout
      const newTimeoutId = setTimeout(() => {
        callback(...args);
      }, delay);
      
      setTimeoutId(newTimeoutId);
      
      // Cleanup on unmount
      return () => {
        if (newTimeoutId) {
          clearTimeout(newTimeoutId);
        }
      };
    }, [callback, delay, timeoutId]);
  
    return debouncedFn;
};
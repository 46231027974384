import { toast } from "react-toastify";
import {
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from "../config/firebase.config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CheckSocialUser, SocialLoginUser } from "../store/auth/authSlice";

const useSocialLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (userSocialData) => {
    if (userSocialData) {
      dispatch(
        SocialLoginUser({
          email: userSocialData.email,
          social_id: userSocialData.social_id,
        })
      ).then((response) => {
        if (response?.data?.status) {
          if (response?.data?.data?.token) {
            navigate("/", { state: { socialUser: userSocialData },replace:true });
          } else {
            navigate("/social-auth", { state: { socialUser: userSocialData } });
          }
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleSocialAuth = async (type) => {
    switch (type) {
      case "google":
        const userGoogleData = await signInWithGoogle();
        handleNavigate(userGoogleData);
        break;
      case "apple":
        const userAppleData = await signInWithApple();
        handleNavigate(userAppleData);
        break;
      case "facebook":
        const userFaceBookData = await signInWithFacebook();
        handleNavigate(userFaceBookData);
        break;
      default:
        console.log("Unsupported authentication type");
    }
  };
  return { handleSocialAuth };
};

export default useSocialLogin;


import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import ChatSideBar from "./ChatSideBar";
import ChatMessage from "./ChatMessage";
import { useFireBaseChatHelper } from "../../../customHooks/useFireBaseChatHelper";
import { useLocation } from "react-router-dom";

const ChatRoom = () => {
  const [adType, setAdType] = useState(0);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    selectedChatRoom,
    setSelectedChatRoom,
    setDidIBlocked,
    setDidOpponentBlocked,
    setBlockedMsg,
    didOpponentBlocked,
    didIBlocked,
    blockedMsg,
    listenToUserBlockStatus,
  } = useFireBaseChatHelper();
  const handleTypeChange = (selectedType) => {
    setAdType(selectedType);
  };
  useEffect(() => {
    if (selectedChatRoom?.id) {
      const unsubscribe = listenToUserBlockStatus(
        setDidIBlocked,
        setDidOpponentBlocked,
        setBlockedMsg
      );
      return () => unsubscribe();
    }
  }, [selectedChatRoom]);

  return (
    <>
      <Header />
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Typography variant="h4" component="div">
            Loading Chats...
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box mt={8.9}>
          <Grid container>
            <Grid
              item
              xs={12}
              overflow="hidden"
              margin={1}
              border="1px solid #D8D8DF"
              borderRadius={2}
            >
              <Box bgcolor="white" gap={12}>
                <Box
                  display="flex"
                  position="relative"
                  justifyContent="space-between"
                >
                  <Box paddingLeft={3} paddingTop={1} paddingBottom={1}>
                    <Typography
                      variant="h5"
                      fontFamily="Work Sans"
                      fontSize="20px"
                      fontWeight="600"
                      lineHeight="28px"
                    >
                      Chats
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    marginRight={4}
                    marginTop={1}
                    marginBottom={1}
                  >
                    <Button variant="outlined" size="small">
                      All
                    </Button>
                    <Button
                      variant={adType === 0 ? "contained" : "outlined"}
                      size="small"
                      sx={{
                        backgroundColor: adType === 0 ? "#2196F3" : "#fff",
                        color: adType === 0 ? "#fff" : "#2196F3",
                        boxShadow: "none",
                      }}
                      onClick={() => handleTypeChange(0)}
                    >
                      Selling
                    </Button>
                    <Button
                      variant={adType === 1 ? "contained" : "outlined"}
                      size="small"
                      sx={{
                        backgroundColor: adType === 1 ? "#2196F3" : "#fff",
                        color: adType === 1 ? "#fff" : "#2196F3",
                      }}
                      onClick={() => handleTypeChange(1)}
                    >
                      Buying
                    </Button>
                  </Stack>
                </Box>
                <Divider />
                <Grid container>
                  <Grid item xs={3} borderRight="1px solid #D8D8DF">
                    <ChatSideBar
                      selectedChatRoom={selectedChatRoom}
                      setSelectedChatRoom={setSelectedChatRoom}
                      setMessages={setMessages}
                      setLoading={setLoading}
                    />
                  </Grid>
                  <ChatMessage
                    messages={messages}
                    selectedChatRoom={selectedChatRoom}
                    setSelectedChatRoom={setSelectedChatRoom}
                    setLoading={setLoading}
                    didOpponentBlocked={didOpponentBlocked}
                    didIBlocked={didIBlocked}
                    blockedMsg={blockedMsg}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ChatRoom;

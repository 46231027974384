import React from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Button, Grid, Typography, Divider, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Images
import ImageViewer from "../../../components/common/ImageViewer";
import { editMyAds, uploadProduct } from "../../../store/product/productSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { base64ArrayToFiles } from "../../../utils/helper";
import { toast } from "react-toastify";

const detailsText = {
  marginTop: "24px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const detailsText2 = {
  marginTop: "12px",
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const descriptive = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const descriptiveText = {
  marginTop: "8px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 7px 0px 5px 20px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
`;

const SectionName = ({ sectionData }) => {
  const excludedFields = [
    "price_type",
    "price",
    "title",
    "description",
    "image",
    "signature",
    "descriptive_paragraph",
  ]; // Fields to exclude from SectionName

  // Filter out the excluded fields
  const dataChunks = Object.entries(sectionData).filter(
    ([key]) => !excludedFields.includes(key)
  );

  return (
    <Grid container spacing={2}>
      {dataChunks.map(([key, { label, value }], index) => {
        // Case when the value is a string with ' | ' (like additional information)
        const isPipeSeparated =
          typeof value === "string" && value.includes(" | ");

        return (
          <Grid
            item
            xs={isPipeSeparated || label === "Signature" ? 12 : 6} // Full row for pipe-separated values, otherwise half row
            key={index}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                sx={{ ...detailsText2, fontWeight: 700, fontSize: "0.87rem" }}
              >
                {label}
              </Typography>
              <Typography
                sx={{ ...detailsText2, textAlign: "end", fontSize: "0.87rem" }}
              >
                {isPipeSeparated || label === "Signature" ? (
                  <Box sx={{ display: "block", wordBreak: "break-word" }}>
                    {value || "N/A"}
                  </Box>
                ) : Array.isArray(value) ? (
                  // Handling array values
                  value.length ? (
                    value.join(" | ")
                  ) : (
                    "N/A"
                  )
                ) : value === true ? (
                  "Yes"
                ) : value === false ? (
                  "No"
                ) : (
                  value || "N/A"
                )}
              </Typography>
            </Box>
            <div
              style={{
                borderBottom: "1px dashed #cbcbd1",
                marginBottom: "16px",
              }}
            />
          </Grid>
        );
      })}
      {sectionData?.signature?.label && (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography
              sx={{ ...detailsText2, fontWeight: 700, fontSize: "0.87rem" }}
            >
              {sectionData?.signature?.label}
            </Typography>
            <Box>
              <img
                style={{ width: "9rem", height: "3.5rem" }}
                src={sectionData?.signature?.value}
                alt={sectionData?.signature?.label}
              />
            </Box>
            {/* <Typography sx={{ ...detailsText2, textAlign: "end" }}>
            {sectionData?.signature?.value}
          </Typography> */}
          </Box>
          <div
            style={{
              borderBottom: "1px dashed #cbcbd1",
              marginBottom: "16px",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const PreviewPage = () => {
  const location = useLocation();
  const previewProductData = useSelector(
    (state) => state?.productReducer?.localPreviewProductData
  );
  const editAdsData = useSelector(
    (state) => state?.myadsReducer?.adsDetailsData
  );
  const publishData = useSelector(
    (state) => state?.productReducer?.publishData
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handlePublish = () => {
  //   // Create FormData 
  //   const formDataToSubmit = new FormData();

  //   // Add images
  //   if (publishData.base64Images?.length > 0) {
  //     const files = base64ArrayToFiles(publishData.base64Images);
  //     files.forEach((file) => {
  //       formDataToSubmit.append("images", file);
  //     });
  //   }

  //   if (location.pathname.startsWith("/edit-post/")) {
  //     formDataToSubmit.append(
  //       "data",
  //       JSON.stringify({
  //         details: publishData.details,
  //         removed_images: publishData.removed_images,
  //         contacts: publishData.contacts, 
  //       })
  //     );

  //     dispatch(editMyAds(formDataToSubmit, editAdsData?.id)).then(() => {
  //       navigate("/my-ads");
  //     });
  //   } else {
  //     formDataToSubmit.append(
  //       "data",
  //       JSON.stringify({
  //         details: publishData.details,
  //         template_id: publishData.template_id,
  //         template_type: publishData.template_type,
  //         selected_categories: publishData.selected_categories,
  //         contacts: publishData.contacts, 
  //       })
  //     );

  //     dispatch(uploadProduct(formDataToSubmit)).then(() => {
  //       navigate("/");
  //     });
  //   }
  // };
const handlePublish = () => {
  // Create FormData
  const formDataToSubmit = new FormData();

  // Add images
  if (publishData.base64Images?.length > 0) {
    const files = base64ArrayToFiles(publishData.base64Images);
    files.forEach((file) => {
      formDataToSubmit.append("images", file);
    });
  }

  let apiCall;

  if (location.pathname.startsWith("/edit-post/")) {
    formDataToSubmit.append(
      "data",
      JSON.stringify({
        details: publishData.details,
        removed_images: publishData.removed_images,
        contacts: publishData.contacts,
      })
    );

    apiCall = dispatch(editMyAds(formDataToSubmit, editAdsData?.id));
  } else {
    formDataToSubmit.append(
      "data",
      JSON.stringify({
        details: publishData.details,
        template_id: publishData.template_id,
        template_type: publishData.template_type,
        selected_categories: publishData.selected_categories,
        contacts: publishData.contacts,
        location: publishData.location,
      })
    );

    apiCall = dispatch(uploadProduct(formDataToSubmit));
  }

  apiCall
    .then((response) => {
      if (response.error) {
        throw new Error(response.error.message || "Something went wrong");
      }
      navigate(location.pathname.startsWith("/edit-post/") ? "/my-ads" : "/");
    })
    .catch((error) => {
      toast.error(error.message || "Failed to publish. Please try again.");
    });
};

  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container mt={7.4}>
        <WhiteBox>
          <Heading sx={{ marginLeft: "-25px" }}>
            <Button
              sx={{
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              }}
            >
              <KeyboardBackspace sx={{ color: "black" }} />
            </Button>
            Preview
          </Heading>
          <Divider />
          <Box paddingLeft="32px" marginTop={"24px"} marginRight={"24px"}>
            <Grid container spacing={"16px"}>
              {/* Selected images */}
              <Grid item xs={12} sm={6} md={5}>
                <ImageViewer
                  images={publishData.base64Images}
                  altText="Local File Image"
                />
              </Grid>

              {/* Data */}
              <Grid item xs={12} sm={6} md={7}>
                {/* Fixed Price text */}
                <Typography
                  sx={{
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "110%",
                  }}
                >
                  {previewProductData?.price_type?.value
                    ? previewProductData.price_type.value
                    : null}
                </Typography>

                <Typography
                  sx={{
                    mt: "0.5rem",
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "110%",
                  }}
                >
                  {previewProductData?.price?.value
                    ? `${previewProductData.price.value}`
                    : null}
                </Typography>

                <Box mt={"0.5rem"}>
                  <Typography
                    variant="h6"
                    sx={{
                      alignSelf: "center",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {previewProductData?.title?.value}
                  </Typography>
                </Box>

                <Box mt={"0.5rem"}>
                  <Typography
                    sx={{
                      alignSelf: "start",
                      fontSize: "0.85rem",
                      lineHeight: "normal",
                    }}
                  >
                    {previewProductData?.description?.value}
                  </Typography>
                </Box>

                {/* Details */}
                <Typography sx={detailsText}>Details</Typography>

                {/* Section name */}
                <Box sx={{ marginTop: "16px" }}>
                  <SectionName sectionData={previewProductData} />
                </Box>
              </Grid>

              {/* Descriptive Paragraph */}
              <Box width={"100%"} marginTop={"19px"} marginLeft={"24px"}>
                <Typography sx={descriptive}>Descriptive Paragraph</Typography>

                <Typography sx={descriptiveText}>
                  {previewProductData?.descriptive_paragraph?.value}
                </Typography>
              </Box>

              <Divider sx={{ marginTop: "24px" }} />

              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-end"}
                gap={"1rem"}
                mb={"1rem"}
                mt={"1rem"}
              >
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handlePublish}
                  variant="contained"
                  color="primary"
                >
                  Publish
                </Button>
              </Box>
            </Grid>
          </Box>
        </WhiteBox>
      </Container>
    </>
  );
};

export default PreviewPage;

//   {/* State pin and village or district */}
//   <Box
//   sx={{
//     display: "flex",
//     justifyContent: "space-between",
//     marginTop: "24px",
//   }}
// >
//   {/* STate pin dist */}
//   <Box sx={{ display: "flex", gap: "8px" }}>
//     {/* Image and state */}
//     <Box
//       sx={{ display: "flex", gap: "4px", marginRight: "8px" }}
//     >
//       <img src={location} alt="location icon" />
//       <Typography sx={state_zip_dist}>State</Typography>
//     </Box>

//     {/* Dot */}
//     <div style={dotStyle} />

//     <Typography sx={state_zip_dist}>1010</Typography>

//     {/* Dot */}
//     <div style={dotStyle} />

//     <Typography sx={state_zip_dist}>
//       Village or district
//     </Typography>
//   </Box>

//   {/* Sponsored */}
//   <Box
//     sx={{
//       display: "flex",
//       gap: "4px",
//     }}
//   >
//     <Typography sx={sponsoredText}>Sponsored</Typography>
//     <Typography sx={sponsoredText}>Sponsored</Typography>
//   </Box>
// </Box>

//  {/* Nick name and user profile */}
//  <Button
//  display={"flex"}
//  sx={{
//    alignSelf: "center",
//    borderRadius: "4px",
//    border: "1px solid #D8D8DF",
//    background: "#FFF",
//    width: "328px",
//    padding: "12px",
//    marginTop: "24px",
//  }}
// >
//  <Avatar sx={{ width: "28px", height: "28px" }} />

//  {/* Name and Image */}
//  <Box
//    sx={{
//      display: "flex",
//      justifyContent: "space-between",
//      width: "100%",
//      alignItems: "center",
//      marginLeft: "8px",
//    }}
//  >
//    <Box>
//      <Typography sx={nickNameText}>Nick Name</Typography>
//      <Typography sx={sinceText}>Since 2024</Typography>
//    </Box>
//    <Box sx={{ display: "flex", gap: "2px" }}>
//      <Typography sx={otherAdsText}>Other Ads</Typography>
//      <img
//        src={drop}
//        alt="drop icon"
//        style={{ width: "12px", height: "12px" }}
//      />
//    </Box>
//  </Box>
// </Button>

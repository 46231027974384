import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AdsCard from "../../../components/AdsCard/AdsCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAds } from "../../../store/myads/myadsSlice";

const MyAds = () => {
  const dispatch = useDispatch();
  const [adType, setAdType] = useState(0);
  const adsList = useSelector(
    (state) => state?.myadsReducer?.data?.result
  );

  const adsCount = Array.isArray(adsList) ? adsList.length : 0;

  useEffect(() => {
    dispatch(fetchAds(adType));
  }, [dispatch, adType]);

  const handleTypeChange = (selectedType) => {
    setAdType(selectedType);
  };

   React.useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

  return (
    <>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={10.4}>
            <Box bgcolor="white" gap={12}>
              <Box
                display="flex"
                position="relative"
                justifyContent="space-between"
              >
                <Box paddingLeft={3} paddingTop={4}>
                  <Typography
                    variant="h5"
                    fontFamily="Work Sans"
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="28px"
                  >
                    My Ads ({adsCount})
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#707187"
                    fontFamily="Work Sans"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="22px"
                  >
                    Please find your Posted Ads.
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  paddingTop={4}
                  marginRight={4}
                  paddingBottom={3}
                >
                  <Button
                    variant={adType === 0 ? "contained" : "outlined"} 
                    sx={{
                      backgroundColor: adType === 0 ? "#2196F3" : "#fff",
                      color: adType === 0 ? "#fff" : "#2196F3", 
                      boxShadow: "none",
                    }}
                    onClick={() => handleTypeChange(0)}
                  >
                    Selling
                  </Button>
                  <Button
                    variant={adType === 1 ? "contained" : "outlined"}
                    sx={{
                      backgroundColor: adType === 1 ? "#2196F3" : "#fff", 
                      color: adType === 1 ? "#fff" : "#2196F3",
                    }}
                    onClick={() => handleTypeChange(1)}
                  >
                    Buying
                  </Button>
                </Stack>
              </Box>
              <AdsCard products={adsList} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyAds;

import React, { useEffect } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from "../../../components/Header/Header";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import FollowingCard from '../../../components/FollowingCard/FollowingCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFollowList } from '../../../store/follow/followSlice';


const Following = () => {
  const dispatch = useDispatch();
  const followList = useSelector((state) => state?.followReducer?.data);
 
   useEffect(() => {
     dispatch(fetchFollowList());
   }, [dispatch]);
  
   React.useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  
  return (
    <>
      <Header/>
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={10.4}>
            <Box bgcolor="white">
              <Typography
                variant="h5"
                fontFamily="Work Sans"
                fontSize="20px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="28px"
                paddingLeft={3}
                paddingTop={4}
              >
                Following ({followList?.length || 0})
              </Typography>
              <Typography
                variant="subtitle2"
                color="#707187"
                fontFamily="Work Sans"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                marginTop={2}
                marginLeft={3}
              >
                Please fill in the following details to create your account.
              </Typography>
              <FollowingCard details={followList || []} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Following


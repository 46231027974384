import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SidebarFilter from "./SidebarFilter";

const CategoryAdsList = () => {
  const location = useLocation();
  const ads = location.state?.ads || [];
  React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <Header />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SidebarFilter />
            </Grid>
            <Grid item xs={9} overflow="hidden" marginTop={10.5}>
              <Box bgcolor="white" gap={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box paddingLeft={3} paddingTop={2}>
                    <Typography
                      variant="h5"
                      fontFamily="Work Sans"
                      fontSize="20px"
                      fontWeight="600"
                      lineHeight="28px"
                    >
                      Ads
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#707187"
                      fontFamily="Work Sans"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="22px"
                    >
                      Please find Posted Ads.
                    </Typography>
                  </Box>
                  <Grid container padding={3}>
                    {ads.length > 0 ? (
                      ads.map((ad, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <ProductCard products={[ad]} />
                        </Grid>
                      ))
                    ) : (
                      <Typography>No ads available</Typography>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "auto" }}>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default CategoryAdsList;

import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getCookie } from "../../utils/cookie";

// API of prduct
const CATEGORY_LIST = `${process.env.REACT_APP_BASE_URL}/categories`;
const GET_CATEGORY_BY_ID = `${process.env.REACT_APP_BASE_URL}/categories`;
const GET_ADS_BY_CATEGORY = `${process.env.REACT_APP_BASE_URL}/categories/details`;

const token = getCookie(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  mainCategorydata: [],
  subCategoryMap: {},
  categories: {},
  products: {},
  status: null,
  message: null,
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryListSuccess: (state, action) => {
      state.mainCategorydata = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    categoryListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    subCategoryDetailsSuccess: (state, action) => {
      state.subCategoryMap[action.payload.parentCategorySlug] =
        action.payload.subCategories;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    subCategoryDetailsFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setCategoryData: (state, action) => {
      state.categories = action.payload.categories || [];
      state.products = action.payload.products || [];
    },
    resetCategoryData: (state) => {
      state.categories = [];
      state.products = [];
    },
  },
});

export const {
  categoryListSuccess,
  categoryListFailure,
  subCategoryDetailsSuccess,
  subCategoryDetailsFailure,
  setCategoryData,
  resetCategoryData,
} = CategorySlice.actions;

export const fetchCategoryList = () => async (dispatch) => {
  try {
    const response = await axios.get(CATEGORY_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      categoryListSuccess({
        data: response?.data?.data,
        status: response.data.status,
        message: response.data.message,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      categoryListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const getCategoryById = (parentSlug) => async (dispatch) => {
  try {
    const response = await axios.get(`${GET_CATEGORY_BY_ID}/${parentSlug}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      subCategoryDetailsSuccess({
        parentCategorySlug: parentSlug, // The clicked category ID
        subCategories: response.data.data, // The fetched subcategories
        status: response.data.status,
        message: response.data.message,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      subCategoryDetailsFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

// export const fetchAdsByCategory =
//   (categorySlug, sort = "desc") =>
//   async (dispatch) => {
//     try {
//       const response = await axios.get(
//         `${GET_ADS_BY_CATEGORY}/${categorySlug}?sort=${sort}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.data.status) {
//         dispatch(
//           subCategoryDetailsSuccess({
//             parentCategorySlug: categorySlug,
//             subCategories: response.data.data, // Extracted category details
//             status: response.data.status,
//             message: response.data.message,
//           })
//         );
//         return response.data.data;
//       } else {
//         console.error("API Error:", response.data.message);
//         dispatch(
//           subCategoryDetailsFailure({
//             status: false,
//             message: response.data.message,
//           })
//         );
//       }
//     } catch (error) {
//       console.error("Request Failed:", error);
//       dispatch(
//         subCategoryDetailsFailure({
//           status: false,
//           message: error.message,
//         })
//       );
//     }
//   };

export const fetchAdsByCategory =
  (categorySlug, sort = "desc") =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${GET_ADS_BY_CATEGORY}/${categorySlug}?sort=${sort}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        dispatch(
          setCategoryData({
            categories: response.data.data.categories,
            products: response.data.data.products,
          })
        );
        return response.data.data;
      } else {
        dispatch(
          categoryListFailure({ status: false, message: response.data.message })
        );
      }
    } catch (error) {
      dispatch(categoryListFailure({ status: false, message: error.message }));
    }
  };

export default CategorySlice.reducer;
